$notifications-dropdown-width: 470px;
$notifications-dropdown-height: 600px;
$notification-dot-side: 10px;

.notifications-root {
  padding: $modern-gap;
  margin-top: $modern-gap;

  @media (max-width: $screen-mobile-max) {
    margin-top: 0;
  }
}

.dropdown-menu.notifications-dropdown {
  border-radius: $modern-radius-lg;
  box-shadow: $modern-shadow-8dp;
}

.notifications-dropdown {
  @media (max-width: $screen-mobile-max) {
    position: fixed;
    margin-top: 0;
    inset: 0 !important;
  }
}

.notifications-dropdown-container {
  max-height: $notifications-dropdown-height;
  width: $notifications-dropdown-width;

  @media (max-width: $screen-mobile-max) {
    min-height: var(--window-height);
    width: 100vw;
  }

  ul.nav.nav-tabs {
    padding: $modern-gap $modern-gap 0 $modern-gap;
    margin: 0;
  }

  .tab-content {
    min-height: $notifications-dropdown-height - 2 * $modern-gap-xl;
    padding: 0;

    @media (max-width: $screen-mobile-max) {
      min-height: calc(var(--window-height) - #{$modern-gap-xl} - 36px);
    }
  }
}

.notifications-dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $modern-gap-xl;
  padding: $modern-gap-condensed $modern-gap $modern-gap-xs;
  color: $modern-subtext;
  box-shadow: $modern-shadow-2dp;
}

.notifications-dropdown-content {
  height: $notifications-dropdown-height - 2 * $modern-gap-xl;
  overflow-y: auto;

  .feed-events-container {
    display: flex;
    flex-direction: column;
  }

  .empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $modern-gap-lg;
    color: $modern-placeholder;
    text-align: center;
    font-weight: $font-demi;
    font-size: $modern-font-sm;
    line-height: $modern-line-height-condensed;
    letter-spacing: 0.5px;
  }

  .notifications-dropdown-event {
    &.feed-event:not(:first-child) {
      width: 100%;
      border-top: 1px solid $modern-grey;
    }
  }

  @include modern-scrollbar()
}

.notifications-dropdown-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $modern-gap-xl;
  border-top: 1px solid $modern-disable;
  z-index: 1500;
}

.notifications__container {
  display: flex;
  gap: $modern-gap;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column-reverse;
    gap: 0;
  }

  .notifications__feed-events {
    flex-basis: 75%;
    flex-shrink: 0;
  }

  .notifications__extra-events {
    flex-basis: 25%;
    margin-bottom: $modern-gap-md;
  }

  .notifications__divider {
    width: 100%;
    height: 1px;
    background-color: $modern-grey;
    margin-bottom: $modern-gap;
  }

  @media (max-width: $screen-tablet-max) {
    .notifications__feed-events {
      flex-basis: 66.66%;
    }

    .notifications__extra-events {
      flex-basis: 33.33%;
    }
  }

  .notifications__tabs.nav-tabs.modern-nav-tabs-regular {
    gap: $modern-gap;
  }
}

.notification-card {
  position: relative;
  padding: $modern-gap;

  .notification-card__header {
    margin-bottom: $modern-gap-md;
  }

  .notification-card__body {
    display: flex;
    justify-content: space-between;
  }

  .notification-card__info {
    margin-bottom: $modern-gap;
  }

  .notification-card__image {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .notification-card__footer {
    margin-top: $modern-gap-md
  }
}

.notifications__main {
  display: flex;
  flex-direction: column;
}

.dropdown button.notification-button {
  position: relative;
  padding: 0;

  .notification-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: $notification-dot-side;
    height: $notification-dot-side;
    border-radius: $modern-radius;
    border: 1px solid $modern-layer;
    background-color: $modern-error-hover;
  }
}