.form-actions.embedded-form-actions {
    margin-top: 4px;
    padding-top: 0;
    padding-bottom: 4px;
    background-color: #ffffff;
    border-top-width: 0;
}

.forms-holder .row_form:last-child .well{
    margin-bottom: 0;
}

.row_form .well {
    padding-bottom: 5px;
}

//.well.form-actions {
//    margin-bottom: 20px;
//}


@media (max-width: 767px) {

    .form-horizontal .form-actions {
        padding-left: 19px;
    }

    .cell.xs-block {
        display: block;
    }
}

@media (min-width: 767px) {
    .form-horizontal .desktop-form-group {
        margin-left: 180px;
    }
}

.form-horizontal .controls textarea {
    resize: none;
}

.form-horizontal .form-actions {
    padding-bottom: 14px;
}

.form-horizontal .form-actions .btn {
    margin-bottom: 5px;
}

.form-inline .control-group {
    display: inline-block;
    vertical-align: top;
}

.form-inline .help-inline {
    display: block;
    max-width: 210px;
}

.form-standard {
    margin-bottom: 15px;
}

.editable-formset-table th, .editable-formset-table td {
    line-height: 20px;
    padding: 4px 5px 4px  5px;
}

.editable-formset-table td input{
    margin-top: 0;
    margin-bottom: 0;
}

@media (max-width: 991px) {
  .form-horizontal .control-label[class*="col-md"] {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: start;
  }
}

.control-label.optional-field {
    font-style: italic;
    color: #777;
}

.required-form .control-label.optional-field {
    font-style: normal;
    color: #333;
}

.clearfix.control-group ul li {
    margin-left: 0;
    list-style: none;
}

.clearfix.control-group ul {
    margin-left: 0;

}

.clearfix.control-group ul li input[type="radio"] {
    margin-right: 4px;
}

.form-horizontal.form-horizontal-thin .control-group{
    margin-bottom: 5px;
}

.form-horizontal.form-horizontal-thin .nav.nav-pills .dropdown a{
    margin-top: 0;
}

.form-inline .select-mini {
  width: 248px;
}

.form-control-static-sm {
    min-height: 30px;
    padding-bottom: 3px;
}

.formulate-input-help {
  color: $text-muted;
  font-size: $font-sm;
  margin-top: $grid-size;
  line-height: $modern-line-height-condensed;
}

.shp-form-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 0;

  .form-group {
    margin-bottom: 2 * $grid-size;
  }

  .form-group ~ .form-group, .form-group ~ * > .form-group {
    margin-left: 2 * $grid-size;
  }

  .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-control-static {
    display: inline-block;
  }

  .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .input-group .input-group-addon,
  .input-group .input-group-btn,
  .input-group .form-control {
    width: auto;
  }

  .input-group > .form-control {
    width: 100%;
  }

  .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .radio, .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .radio label, .checkbox label {
    padding-left: 0;
  }

  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }

  .formulate-input-wrapper {
    display: flex;
    align-items: center;
    //margin-right: 2 * $grid-size;
    //margin-bottom: 2 * $grid-size;

    > label {
      margin-right: $grid-size;
      margin-bottom: 0;
    }

    .shp-filter-optional {
      flex: 1;
    }
  }

  .shp_checklist__list {
    display: inline-flex;
    padding: 0;

    .shp_checklist__row {
      display: flex;
      margin-bottom: 0;

      & ~ .shp_checklist__row {
        margin-left: 2 * $grid-size;
      }
    }
  }

  .date.L, .date.LT {
    max-width: 5 * $line-height;
  }
}

.form-group--md {
  margin-bottom: $gap-md;

  &:last-child {
    margin-bottom: 0;
  }
}