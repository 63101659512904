.show-more-widget {
  position: relative;
  margin-top: 15px;
}

.show-more-line {
  position: relative;
  top: -20px;
  margin: 0;
  line-height: 1px;
}

.show-more__container {
  min-height: $line-height;
}

.show-more-btn, .show-less-btn {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  //position: relative;
  border: 1px $navy solid;
  box-shadow: 0 0 0 1px #2980B9;
  display: inline-block;
  //bottom: -40px;
  color: $navy;
  text-decoration: none;
}

[aria-expanded='false'] .text-fade {
  position: relative;
}

[aria-expanded='false'] .text-fade::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(rgba(255,255,255,0) 250px, #fff 300px, #fff);
}
