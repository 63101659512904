@use "sass:math";
@import "colors";
@import "shp/modern_gradients";

// Modern fonts
$font-bold: 700;
$font-demi: 600;
$font-semi-bold: 500;
$font-regular: 400;

$modern-font-family: "DIN 2014 Rounded", Arial, sans-serif;

$modern-font-xxxs: 8px;
$modern-font-xxs: 10px;
$modern-font-xs: 12px;
$modern-font-sm: 14px;
$modern-font-md: 16px;
$modern-font-lg: 18px;
$modern-font-h4: 20px;
$modern-font-icon: 24px;
$modern-font-h3: 32px;
$modern-font-h2: 40px;
$modern-font-h1: 48px;
$modern-font-title: 64px;

$modern-mobile-font-h3: 28px;
$modern-mobile-font-h2: 34px;
$modern-mobile-font-h1: 40px;
$modern-mobile-font-title: 44px;

// Modern line height
$modern-line-height-xxs: 8px;
$modern-line-height-small: 12px;
$modern-line-height-navigation_small: 14px;
$modern-line-height-condensed: 16px;
$modern-line-height: 24px;
$modern-line-height-h4: 32px;
$modern-line-height-h3: 40px;
$modern-line-height-h2: 48px;
$modern-line-height-h1: 56px;
$modern-line-height-title: 64px;

$modern-mobile-line-height-h3: 32px;
$modern-mobile-line-height-h2: 40px;
$modern-mobile-line-height-h1: 48px;
$modern-mobile-line-height-title: 56px;

// Modern line padding
$modern-line-padding-sm: math.div($modern-line-height * 2, 3);
$modern-line-padding: $modern-line-height;
$modern-line-padding-lg: $modern-line-height * 2;
$modern-p-margin: $modern-line-height;

// Modern shadows
$modern-shadow-2dp:  0 0 1px 0 rgba(0, 99, 153, 0.15), 0 1px 2px 0 rgba(0, 99, 153, 0.15);
$modern-shadow-4dp:  0 2px 10px 0 rgba(0, 99, 153, 0.1), 0 0 2px 0 rgba(0, 99, 153, 0.2);
$modern-black-shadow-4dp: 0 2px 10px 0 rgba(0, 0, 0, 0.10), 0 0 2px 0 rgba(0, 0, 0, 0.20);;
$modern-shadow-8dp:  0 0 3px 0 rgba(0, 99, 153, 0.15), 0 4px 10px 0 rgba(0, 99, 153, 0.15);

// Modern gaps: paddings, margins, radius
$modern-gap: 16px;
$modern-gap-xxs: math.div($modern-gap, 8);
$modern-gap-xs: math.div($modern-gap, 4);
$modern-gap-condensed: math.div($modern-gap * 3, 4);
$modern-gap-sm: math.div($modern-gap, 2);
$modern-gap-md: math.div($modern-gap * 3, 2);
$modern-gap-lg: $modern-gap * 2;
$modern-gap-xl: $modern-gap * 3;
$modern-gap-2xl: $modern-gap * 4;
$modern-gap-3xl: $modern-gap * 6;
$modern-gap-4xl: $modern-gap * 8;

$modern-radius-xxl: 50px;
$modern-radius-xl: 20px;
$modern-radius-lg: 10px;
$modern-radius: 8px;
$modern-radius-sm: 6px;
$modern-radius-xs: 4px;

// Breakpoints
$screen-mobile-min: 360px;
$screen-tablet-min: 768px;
$screen-desktop-min: 1280px;
$screen-mobile-min: 360px;
$screen-mobile-max: $screen-tablet-min - 1px;
$screen-tablet-max: $screen-desktop-min - 1px;

// Containers
$base-container-max-width: 1600px;

$mobile-item-touchable-size: 44px;

// Components
$modern-panel-background: $modern-background;
$modern-panel-layer: $modern-layer;
$modern-panel-black: $modern-black-background;
$modern-panel-grey: $modern-grey-background;
$modern-panel-grey-soft: $modern-grey-soft-background;
$modern-panel-primary-dark: $modern-primary-dark-background;
$modern-panel-primary: $modern-primary;
$modern-panel-primary-soft: $modern-primary-soft-background;
$modern-panel-error: $modern-error;
$modern-panel-error-soft: $modern-error-soft-background;
$modern-panel-success: $modern-success;
$modern-panel-success-soft: $modern-success-soft-background;
$modern-panel-warning: $modern-warning;
$modern-panel-warning-soft: $modern-warning-soft-background;
$modern-panel-amazing: $modern-amazing;
$modern-panel-amazing-soft: $modern-amazing-soft-background;
$modern-panel-border-width: 1px;
$modern-panel-decorated-border-top: 8px;
$modern-panel-decorated-outside-border: 2px;

$modern-input-border-width: 1px;
$modern-input-padding: $modern-gap-sm - $modern-input-border-width;
$modern-input-paddings-sm: ($modern-gap-xs - $modern-input-border-width) ($modern-gap-sm - $modern-input-border-width);
$modern-input-paddings-md: ($modern-gap-condensed - $modern-input-border-width) ($modern-gap-sm - $modern-input-border-width);
$modern-input-paddings-lg: ($modern-gap-condensed - $modern-input-border-width) ($modern-gap-sm - $modern-input-border-width);
$modern-input-text-color: $modern-text-black;
$modern-input-label-color: $modern-text-black;
$modern-input-caption-color: $modern-subtext;
$modern-input-placeholder-color: $modern-placeholder;
$modern-input-disabled-color: $modern-subtext;
$modern-input-layer-color: $modern-layer;
$modern-input-disabled-background: $modern-disable;
$modern-input-border-color: $modern-line;
$modern-input-focus-color: $modern-primary;
$modern-input-error-color: $modern-error;
$modern-input-hover-color: $modern-disable;
$modern-input-addon-color: $modern-subtext;
$modern-input-addon-background: $modern-disable;
$modern-textarea-grabber-size: 20px;

$pupil-main-header-actions-height: 80px;
$pupil-main-header-avatar-size: 120px;
$pupil-main-header-info-left-padding: $pupil-main-header-avatar-size + $modern-gap + $modern-gap-md;
