
.limited-container {
    max-height: 80px;
    overflow-y: auto;
}


.limited-container.limited-container-60 {
    max-height: 60px;
}

.limited-container.limited-container-x2 {
    max-height: 160px;
    overflow-y: auto;
}


.limited-container.small {
    max-height: 40px;
}

.limited-container.large-container {
    max-height: 240px;
}

.giant-container.limited-container {
    max-height: 480px;
}

.giant-container.limited-container {
    max-height: 70vh;
}

.limited-info {
    display: block;
    width: 25px;
    overflow:hidden;
    white-space: nowrap;
    position: absolute;
}

.limited-info:hover {
    width: auto;
    background-color: #F3F3F3;
    z-index: 11;
}