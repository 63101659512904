.modal.fade .modal-dialog {
    transform: translate(0px, 0px);
}

.modal.modal-small .modal-footer {
    margin-top: 0;
}

.modal.modal-giant .modal-dialog{
    width: 1280px;
}

.modal.modal-extra-large .modal-dialog{
    width: 1000px;
}

.modal.modal-large  .modal-dialog{
    width: 700px;
}

.modal.modal-xs .modal-dialog{
    width: 16 * $line-height ;
}

.modal.modal-xl-paddings .modal-dialog .modal-content .modal-body {
    padding-right: 32 * $grid-size;
    padding-left: 32 * $grid-size;
}

.modal.modal-no-padding .modal-body {
    padding: 0;
}

.modal-body.modal-body-xs {
    padding-bottom: 0;
}

.modal form {
    margin-bottom: 0;
}

.modal-dialog .sticky-modal-footer{
    margin-left: - math.div($line-height, 2);
    margin-right: - math.div($line-height, 2);
    margin-bottom: - math.div($line-height, 2);
    overflow-x: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.modal-dialog .sticky-modal-content {
    margin: -16px;
}


.modal-dialog .sticky-modal-footer .form-actions{
    padding-bottom: 19px;
    margin-bottom: 0;
}

.modal-dialog .btn-toolbar{
    margin-left: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}


@media (max-width: 1150px) {
    .modal.modal-extra-large .modal-dialog {
        width: auto;
    }

}

@media (max-width: 1350px) {
    .modal.modal-giant .modal-dialog {
        width: auto;
    }
}

@media (max-width: 767px) {

  .modal.modal-large .modal-dialog {
    width: auto;
  }

  .modal .form-horizontal .controls {
    margin-left: 110px;
  }
}

@media (min-width: 767px) {
    .modal .form-horizontal .desktop-form-group {
        margin-left: 0;
    }
}
