@use "sass:math";

.formulate-input-errors {
  padding-left: 0;
  color: $red;
  margin-bottom: math.div($line-padding, 2);
}

.formulate-form-errors {
  list-style-type: none;
  border-color: #eee;
  border-left-color: $red-text;
  border-image: none;
  border-radius: $btn-border-radius;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
  margin-bottom: $line-padding;
  padding: $line-padding;
  background-color: $ibox-title-bg;
}

.formulate-form {
  $label-width: 160px;
  $label-width-lg: 220px;
  $padding-size: $grid-size * 3;
  $checkbox-size: 16px;

  @mixin label {
    width: $label-width;
    text-align: right;
    flex-shrink: 0;
    padding-right: $padding-size;
  }

  @mixin input {
    flex-grow: 1;
  }

  @mixin horizontal-inputs {
    .formulate-input-wrapper {
      display: flex;

      .formulate-input-label {
        margin-top: $grid-size * 1.5;
        @include label;
      }

      .formulate-input-element, .shp__vs {
        @include input;
      }

      &.formulate-input-layout {
        .formulate-input-element {
          min-height: $grid-size * 8;
        }
      }
    }

    .formulate-input-help, .formulate-input-errors {
      margin-left: $label-width;
    }

    .shp_checkbox__wrapper-inline {
      display: flex;

      .formulate-input-label {
        @include input;
        margin-left: 0;
        text-align: left;
      }

      .formulate-input-element {
        @include label;
        flex-grow: 0;
        padding-right: unset;
        width: $checkbox-size;
        margin-left: $label-width - $padding-size - $checkbox-size;
        margin-right: $padding-size;
      }
    }
  }

  @mixin sizes {
    &.formulate-form__label-lg {
      .formulate-input-wrapper .formulate-input-label{
        width: $label-width-lg;
      }

      .shp_checkbox__wrapper-inline {
        .formulate-input-element {
          margin-left: $label-width-lg - $padding-size - $checkbox-size;
        }
      }

      .formulate-input-help, .formulate-input-errors {
        margin-left: $label-width-lg;
      }
    }
  }

  &.formulate-form--horizontal {
    @include horizontal-inputs;
    @include sizes;
  }


  .form-group.formulate-field--horizontal {
    @include horizontal-inputs;
    @include sizes;
  }
}



.modern-page {
  .formulate-input-errors {
    color: $modern-error;
  }
}

.list-select {
  &.horizontal {
    display: flex;
    flex-wrap: wrap;
    gap: $gap-sm;

    @media (max-width: $screen-mobile-max) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: $modern-gap-condensed;
    }
  }
}

.disabled-input-layout {
  width: 100%;
  margin-top: $grid-size;
  background-color: #e0e6e9;
  padding: $grid-size * 2 $grid-size * 4;
  min-height: 36px;
}

[data-type="dropdown_select"] {
  .formulate-input-wrapper.formulate-wrapper-column {
    display: flex;
    flex-direction: column;
  }
}

.semi-bold-from-labels {
  .formulate-input-wrapper {
    .formulate-input-label {
      font-weight: $font-semi-bold;
    }
  }
}

.muted-form-labels {
  .formulate-input-wrapper {
    .formulate-input-label {
      color: $label-gray-color;
    }
  }
}

.details-collapsible-item {
  max-height: 1000px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.25s ease-in;

  &.collapsed {
    max-height: 60px;
    transition: max-height 0.15s ease-out;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: $collapsed-overlay-gradient;
    }
  }
}
