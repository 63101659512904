/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/
// Google Fonts

// Variables, Mixins
// INSPINIA Theme Elements
@import "typography";
@import "navigation";
@import "top_navigation";
@import "modern_navigation";
@import "badges_labels";
@import "elements";
@import "sidebar";
@import "base";
@import "pages";
@import "modules";
//@import "chat";
@import "metismenu";
@import "spinners";
@import "spaces";

// RTL Support
@import "rtl";

// For demo only - config box style
@import "theme-config";

// INSPINIA Skins
@import "skins";
@import "md-skin";

// Media query style
@import "media";

// Custom style
// Your custom style to override base style
@import "journal_table";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}

@import "modules";
