
/*
 * awesome-avatar
 */


.awesome-avatar-select-area {
    border: 1px solid #ddd;
    box-sizing: content-box;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.awesome-avatar-input {
    width: 100%;
    width: -moz-calc(100% - 2px);
    width: -webkit-calc(100% - 2px);
    width: calc(100% - 2px);

    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-width: 0;
}

.awesome-avatar-preview {
    /*margin-right: 20px;*/
    border: 1px solid #ddd;
    box-sizing: content-box;
    background-color: #FFF;
    overflow: hidden;
}

