@use "sass:math";

@mixin third-level-meu-mixin () {
  min-height: 8px;

  > li {
    font-size: 14px;
    margin-bottom: 10px;
    > a {
      color: #2980b9;
      padding-bottom: 10px;
    }

  }
  > li.active > a {
      border-bottom: 4px solid $lazur;
      padding-bottom: 6px;
      color: inherit;
  }

}
.breadcrumb.third-level-menu {
  @include third-level-meu-mixin();

  > li + li::before {
    content: "•";
  }
  > li > a + ol::before {
    content: "( ";
  }

}

.breadcrumb.four-level-menu {
  display: inline-block;

  @include third-level-meu-mixin();
  > ol + li ::after {
    content: ")";
  }

  > li {
    margin-bottom: 0;
    > a {
      padding-bottom: 0;
    }
  }
}

.page-heading {
  border-bottom-width: 1px;
}

.page-heading.page-heading-sm {
  padding-bottom: 0;

  h2 {
    font-weight: 400;
    margin-top: $line-padding;
    margin-bottom: math.div($line-padding, 2);
  }

  .page-heading-actions h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: $screen-xs-max) {
  .page-heading.page-heading-sm {
  h2 {
    margin-top: math.div($line-padding, 2);
    margin-bottom: 0;
  }
  }
}

@media (max-width: $screen-xs-max) {
  .page-heading.page-heading-sm {
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .page-heading.page-heading-no-bottom h2 {
      margin-bottom: 8px;
  }
}

.modern-page {
  .page-heading.page-heading-no-bottom h2 {
    margin: $modern-gap-sm 0;
    font-weight: 700;
  }
}


.navbar-top-links.nav > li > a {
  font-weight: 400;
}

.nav.navbar-top-links > li > a.navbar-brand:hover, .nav.navbar-top-links > li > a.navbar-brand:focus {
  background: transparent;
}

.skin-1 .nav > li > a.navbar-brand:hover, .skin-1 .nav > li > a.navbar-brand:focus {
    color: #9ea6b9;
}


.hidden-navbar {
  #page-wrapper {
    margin-left: 0;
  }
  .navbar-minimalize{
    display: none;
  }
}

.visible-body-small {
  display: none;
}

.body-small {
  .visible-body-small {
    display: inherit;
  }
}

.mini-navbar-icon {
  display: none;
}

.mini-navbar-icon {
  display: none;
}

.metismenu a i {
  min-width: 16px;
  display: inline-block;
  font-size: 16px;
}

.metismenu i.mini-navbar-icon {
  display: none;
}

body.mini-navbar .hidden-mini-navbar {
  display: none;
}

body.mini-navbar .mini-navbar-icon {
  display: block;
  font-style: normal;
}

body.mini-navbar .nav-header {
  padding: 31px;
}

.skin-1 .nav-header {
  padding: 25px 25px 25px 25px;
}

.skin-1 .wrapper-content  {
  .nav-stacked.nav > li > a,
  .panel-heading .nav > li > a,
  .panel-footer .nav > li > a,
  .nav.nav-blue > li > a {
    color: #2980b9;
  }
  .nav-stacked.nav > li.active > a,
  .panel-heading .nav > li.active > a,
  .panel-footer .nav > li.active > a,
  .nav.nav-blue > li > a.active {
    color: #FFFFFF;
  }
}


.skin-1 .sidebar-collapse li.nav-second-level-item {
  border-left: 4px solid $skin-1-color;
}

.sidebar-collapse {
  padding-bottom: 200px;
}

.skin-1 .navbar .nav > li > a.navy {
  color: $navy;
}

.skin-1 .navbar .nav li:hover > a.navy {
  color: darken($navy, 10%);
}

.navbar-top-links  li.nav-li-lg a {
  font-size: 16px;
  padding-top: math.div((2 * $line-height - 23px), 2);
  padding-bottom: math.div((2 * $line-height - 23px), 2);
}

.icon-lg {
  font-size: 18px;
}

.navbar-static-top {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0 $modern-gap-md;
}

h3.modal-title, h4.modal-title, h5.modal-title, h6.modal-title {
    font-weight: 400;
}


@media (min-width: 1000px) and (max-width: 1300px) {
.container {
    width: 970px;
}
}

.hidden-navbar #page-wrapper {
  display: block;
}


@media (min-width: 768px) {
  .container {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .navbar-top-links .dropdown-tasks {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1350px) {
  .container {
    width: 1170px;
  }
}

//.navbar-static-side {
//  margin-top: $line-height * 2 + 4px;
//}

@media (min-width: 768px) {
  .navbar-static-side {
    z-index: 100;
  }
}

.skin-1 .wrapper-content.wrapper-content-no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.profile-element .media-body {
  font-size: 14px;
  font-weight: 200;
}

.navbar-top-links li a.navbar-brand.shp-brand {
  display: flex;
  padding: 0;
  height: auto;
  min-height: auto;
  img {
    height: 32px;
  }
}

.top_menu-lg {
  z-index: 2;
  position: relative;
}
