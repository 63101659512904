@font-face {
  font-family: "DIN 2014 Rounded";
  src: url("/static/font/din_2014_rounded/DIN-2014-Rounded_Extra-Light.otf");
  font-weight: 200;
}

@font-face {
  font-family: "DIN 2014 Rounded";
  src: url("/static/font/din_2014_rounded/DIN-2014-Rounded_Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "DIN 2014 Rounded";
  src: url("/static/font/din_2014_rounded/DIN-2014-Rounded_Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "DIN 2014 Rounded";
  src: url("/static/font/din_2014_rounded/DIN-2014-Rounded_Demi.otf");
  font-weight: 600;
}

@font-face {
  font-family: "DIN 2014 Rounded";
  src: url("/static/font/din_2014_rounded/DIN-2014-Rounded_Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "DIN 2014 Rounded";
  src: url("/static/font/din_2014_rounded/DIN-2014-Rounded_Extra-Bold.otf");
  font-weight: 800;
}

.font-din-2014 {
  font-family: "DIN 2014 Rounded", Arial, sans-serif;
}