@use "sass:math";

.m-none {
  margin: 0;
}

.m-standart {
  margin: $line-padding
}

.m-md {
  margin: math.div($line-padding * 3, 2);
}

.mb-none {
  margin-bottom: 0 !important;
}

.mb-xxs {
  margin-bottom: math.div($line-padding, 8);
}

.mb-xs {
  margin-bottom: math.div($line-padding, 4);
}

.mb-12 {
  margin-bottom: math.div($line-padding * 3, 4);
}

.mb-sm {
  margin-bottom: math.div($line-padding, 2);
}

.mb {
  margin-bottom: $line-padding !important;
}

.mb-md {
  margin-bottom: math.div($line-padding * 3, 2) !important;
}

.mb-lg {
  margin-bottom: $line-padding * 2;
}

.mb-lg-well {
  margin-bottom: $line-padding * 3;
}

.mb-xl {
  margin-bottom: $line-padding * 4;
}

.mb-extra {
  margin-bottom: $line-padding * 5;
}

.mb-super {
  margin-bottom: $line-padding * 10;
}

.mt-none {
  margin-top: 0;
}

.mt-xxs {
  margin-top: math.div($line-padding, 8);
}

.mt-xs {
  margin-top: math.div($line-padding, 4);
}

.mt-sm {
  margin-top: math.div($line-padding, 2);
}

.mt {
  margin-top: $line-padding;
}

.mt-md {
  margin-top: math.div($line-padding * 3, 2);
}

.mt-lg {
  margin-top: $line-padding * 2;
}

.mt-xl {
  margin-top: $line-padding * 4;
}

.mr-none {
  margin-right: 0;
}

.mr-xxs {
  margin-right: math.div($line-padding, 8);
}

.mr-xs {
  margin-right: math.div($line-padding, 4);
}

.mr-sm {
  margin-right: math.div($line-padding, 2);
}

.mr {
  margin-right: $line-padding;
}

.mr-md {
  margin-right: math.div($line-padding * 3, 2);
}

.mr-lg {
  margin-right: $line-padding * 2;
}

.mr-xl {
  margin-right: $line-padding * 4;
}

.ml-none {
  margin-left: 0;
}

.ml-xxs {
  margin-left: math.div($line-padding, 8);
}

.ml-xs {
  margin-left: math.div($line-padding, 4);
}

.ml-sm {
  margin-left: math.div($line-padding, 2);
}

.ml {
  margin-left: $line-padding;
}

.ml-md {
  margin-left: math.div($line-padding * 3, 2);
}

.ml-lg {
  margin-left: $line-padding * 2;
}

.ml-xl {
  margin-left: $line-padding * 4;
}

.ml-auto {
  margin-left: auto;
}

.p-none {
  padding: 0;
}

.p-xxs {
  padding: math.div($line-padding, 8);
}

.p-xs {
  padding: math.div($line-padding, 4);
}

.p-sm {
  padding: math.div($line-padding, 2);
}

.p-standart {
  padding: $line-padding;
}

.p-md {
  padding: math.div($line-padding * 3, 2);
}

.p-lg {
  padding: $line-padding * 2;
}

.p-xl {
  padding: $line-padding * 4;
}

.pr-none {
  padding-right: 0;
}

.pr-xxs {
  padding-right: math.div($line-padding, 8);
}

.pr-xs {
  padding-right: math.div($line-padding, 4);
}

.pr-sm {
  padding-right: math.div($line-padding, 2);
}

.pr {
  padding-right: $line-padding;
}

.pr-md {
  padding-right: math.div($line-padding * 3, 2);
}

.pr-lg {
  padding-right: $line-padding * 2;
}

.pr-xl {
  padding-right: $line-padding * 4;
}

.pr-2xl {
  padding-right: $line-padding * 6;
}

.pl-none {
  padding-left: 0;
}

.pl-xxs {
  padding-left: math.div($line-padding, 8);
}

.pl-xs {
  padding-left: math.div($line-padding, 4);
}

.pl-sm {
  padding-left: math.div($line-padding, 2);
}

.pl {
  padding-left: $line-padding;
}

.pl-md {
  padding-left: math.div($line-padding * 3, 2);
}

.pl-lg {
  padding-left: $line-padding * 2;
}

.pl-xl {
  padding-left: $line-padding * 4;
}

.pl-2xl {
  padding-left: $line-padding * 6;
}

.pb-none {
  padding-bottom: 0;
}

.pb-xxs {
  padding-bottom: math.div($line-padding, 8);
}

.pb-xs {
  padding-bottom: math.div($line-padding, 4);
}

.pb-sm {
  padding-bottom: math.div($line-padding, 2);
}

.pb {
  padding-bottom: $line-padding;
}

.pb-md {
  padding-bottom: math.div($line-padding * 3, 2);
}

.pb-lg {
  padding-bottom: $line-padding * 2;
}

.pb-xl {
  padding-bottom: $line-padding * 4;
}

.pb-2xl {
  padding-bottom: $line-padding * 6;
}

.pt-none {
  padding-top: 0;
}

.pt-xxs {
  padding-top: math.div($line-padding, 8);
}

.pt-xs {
  padding-top: math.div($line-padding, 4);
}

.pt-sm {
  padding-top: math.div($line-padding, 2);
}

.pt {
  padding-top: $line-padding;
}

.pt-md {
  padding-top: math.div($line-padding * 3, 2);
}

.pt-lg {
  padding-top: $line-padding * 2;
}

.pt-xl {
  padding-top: $line-padding * 4;
}

.pt-2xl {
  padding-top: $line-padding * 6;
}

.py-sm {
  padding-top: math.div($line-padding, 2);
  padding-bottom: math.div($line-padding, 2);
}

.py {
  padding-top: $line-padding;
  padding-bottom: $line-padding;
}

.py-lg {
  padding-top: $line-padding * 2;
  padding-bottom: $line-padding * 2;
}

.px-sm {
  padding-left: math.div($line-padding, 2);
  padding-right: math.div($line-padding, 2);
}

.px {
  padding-left: $line-padding;
  padding-right: $line-padding;
}

.px-lg {
  padding-left: $line-padding * 2;
  padding-right: $line-padding * 2;
}


.w-xs {
  width: #{math.div($line-padding, 4px) * 1rem};
}

.w-sm {
  width: #{math.div($line-padding, 2px) * 1rem};
}

.w-md {
  width: #{math.div($line-padding, 1px) * 1rem};
}

.w-lg {
  width: #{math.div($line-padding, 1px) * 2rem};
}
