$run-code-min-height: 280px;

.element-header {
  font-size: 18px;
  display: inline-block;
  padding: 14px 20px 14px 10px;
}

.problem-header-tag {
  font-style: italic;
  color: $navy;
}

.problem-header-tag + .problem-header-tag:before {
  content: "•";
  color: #8095a8;
  padding: 0 5px;
}

.run-report-field {
    max-width: 100vw;
    overflow-x: auto;
    white-space: break-spaces;
    &--error {
      border-color: $modern-error;
    }
}

.shp__code-editor, .shp__code-console {
  margin-left: -$line-padding - 1;
  margin-right: -$line-padding - 1;
}

.problem-table {
  display: grid;
  grid-template-columns: 20px minmax(5%, 1fr) minmax(5%, 1fr) 100px;

  .full-row {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .problem-table__error-row {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }
}

pre.pre-samples {
  overflow: auto;
  margin: 0;
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: $font-md;
  font-family: $fonts-roboto-monospace;
}

.more-info > div {
  width: fit-content;
}

.problem__problem-icon {
  width: 10px;
  display: inline-block;
  margin-right: $modern-gap-xs;
  font-size: 16px;
}

.problem_panel {
  border-radius: unset;
  margin-bottom: 0;
  box-shadow: none;
  @media (max-width: $screen-tablet-max) {
    margin-bottom: $modern-gap-md;
  }
}

.problem_panel__left {
  border-right: transparent;
}

.problem_panel__tags {
  gap: $modern-gap-sm $modern-gap-md;
}

.run-code__header {
  border-bottom: 1px solid $border-color;
}

.run-code__content {
  padding: $modern-gap;
  min-height: $run-code-min-height;
}

.run-code__footer {
  border-top: 1px solid $border-color;
}

.problem-tabs {
  .tab-content {
    min-height: initial;
  }
}

.nav.nav-tabs.modern-nav-tabs.panel-tabs {
  &.problem-tabs > li > a,
  &.problem-tabs li.open > a {
    text-transform: initial;
    font-size: $modern-font-md;
    font-weight: $font-demi;
    line-height: $modern-line-height;
  }

  .dropdown-menu li.active:after {
    @media (max-width: $screen-tablet-max) {
      bottom: initial;
      top: $modern-gap-xs;
      right: $modern-gap-xs;
    }
  }
}

.problem-code-panel {
  border-radius: $modern-radius-lg;
  border: 1px solid $modern-grey;

  .mshp-code-editor {
    padding: $modern-gap-sm;
  }

  &__footer {
    border-top: 1px solid $modern-grey;
    padding: $modern-gap-sm $modern-gap;
  }

  &__full {
    .CodeMirror {
      height: auto !important;
    }
  }
}

.problem-tabs.nav-tabs {
  border: none;

  & > li, & > li:hover {
    border: none;

    & > a {
      padding: $modern-gap-sm $modern-gap;
      background: transparent;
      color: $text-color;
      font-weight: $font-demi;
      font-size: $modern-font-md;
      border-radius: $modern-radius-lg $modern-radius-lg 0 0;
      outline: none;
      border: none;

      .dropdown {
        pointer-events: none;
        i {
          display: none;
        }
      }
    }
  }

  & > li.active > a {
    background: $modern-layer;
    border: none;

    .dropdown {
      pointer-events: initial;

      i {
        display: block;
      }
    }
  }

  .run-selector__verdict {
    @media (max-width: $screen-tablet-max) {
      &-number {
        font-size: $font-md;
      }
      .modern-badge {
        font-size: $font-sm;
      }
      li.active:after {
        bottom: initial;
        top: 0;
      }
    }
  }
}

.problem-tabs__content.tab-content {
  padding-top: 0;
  margin-top: -1px;

  .tab-pane:first-child {
    .modern-panel {
      border-top-left-radius: 0;
    }
  }
}
