
.shp-square {
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  width: 30px;
  height: 30px;
  //color: #FFFFFF;
  padding: 6px;
  position: relative;
  text-align: center;
  vertical-align: center;
}

.shp-square.shp-square-lg {
    width: 40px;
    height: 40px;
    line-height: 26px;
    font-size: 14px;
}


@mixin shp-square-variant ($color) {
  background-color: $color;
}

.shp-square-danger {
  @include shp-square-variant($red)
}

.shp-square-warning {
  @include shp-square-variant($yellow)
}

.shp-square-info {
  @include shp-square-variant($lazur)
}

.shp-square-success {
  @include shp-square-variant($blue)
}

.shp-square-amazing {
  @include shp-square-variant($amazing)
}

.shp-square-default {
  @include shp-square-variant($dark-gray)
}

.shp-square-white {
  @include shp-square-variant($text-color-white)
}

.shp-square-center {
  position: relative;
  top: 4px;
  display: inline-block;
}

.bottom-right-circle {
  left: 20%; bottom: -17px
}

.top-right-circle {
  right: -17px; top: 0;
}

.btn .bottom-right-circle {
  left: 4px; bottom: -10px
}


.btn .top-right-circle {
  left: 4px; top: -17px
}