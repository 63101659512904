$red-dot-size: 8px;
$red-dot-container-space-diameter: 2px;

.navigation-counter-wrapper {
  position: relative;

  .modern-badge {
    position: absolute;
    z-index: 10;
    top: 0;
    left: calc(100% - $modern-gap);
  }
}

.red-dot-wrapper {
  position: relative;
  display: flex;

  .red-dot-container {
    position: absolute;
    right: 1px;
    border-radius: 100%;
    height: $red-dot-size + $red-dot-container-space-diameter;
    width: $red-dot-size + $red-dot-container-space-diameter;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .red-dot {
    border-radius: 100%;
    height: $red-dot-size;
    width: $red-dot-size;
    background-color: $modern-error-hover;
  }
}