$modern-label-grey-text: $modern-subtext;
$modern-label-grey-background: $modern-grey;
$modern-label-primary-text: $modern-primary;
$modern-label-primary-background: $modern-primary-background;
$modern-label-success-text: $modern-success;
$modern-label-success-background: $modern-success-background;
$modern-label-error-text: $modern-error;
$modern-label-error-background: $modern-error-background;
$modern-label-warning-text: $modern-warning-pressed;
$modern-label-warning-background: $modern-yellow-background;
$modern-label-orange-text: $modern-orange;
$modern-label-orange-background: $modern-orange-background;
$modern-label-amazing-text: $modern-amazing;
$modern-label-amazing-background: $modern-amazing-background;

@mixin label-modern-variant($text-color, $bg-color) {
  color: $text-color;
  background-color: $bg-color;
  border-color: $text-color;
  &::before {
    background-color: $text-color;
  }
  &.modern-label-point {
    color: $modern-label-grey-text;
  }
}

.modern-label {
  line-height: $modern-line-height-condensed;
  font-weight: $font-regular;
  letter-spacing: 1px;
  font-size: $modern-font-sm;
  vertical-align: middle;
  border-radius: 50px;
  padding: $modern-gap-xs $modern-gap-xs * 3;
  white-space: nowrap;

  &.modern-label-bordered {
    border: 1px solid $modern-label-grey-text;
  }

  $label_colors: (
    "grey"   : ($modern-label-grey-text, $modern-label-grey-background),
    "primary"   : ($modern-label-primary-text, $modern-label-primary-background),
    "success"  : ($modern-label-success-text, $modern-label-success-background),
    "error"    : ($modern-label-error-text, $modern-label-error-background),
    "warning" : ($modern-label-warning-text, $modern-label-warning-background),
    "orange" : ($modern-label-orange-text, $modern-label-orange-background),
    "amazing" : ($modern-label-amazing-text, $modern-label-amazing-background)
  );

  @each $color_name, $colors in $label_colors {
    $text-color: nth($colors, 1);
    $bg-color: nth($colors, 2);
    &.modern-label-#{$color_name} {
      @include label-modern-variant($text-color, $bg-color);
    }
  }

  &.modern-label-point {
    display: inline-flex;
    align-items: stretch;
    font-size: $font-md;
    letter-spacing: 2px;
    font-weight: normal;
    background: none;
    padding-left: 0;
    padding-right: 0;

    &::before {
      content: "";
      height: $modern-gap-sm;
      width: $modern-gap-sm;
      margin: $modern-gap-xs $modern-gap-sm $modern-gap-xs $modern-gap-xs;
      border-radius: 50%;
    }
  }
}
