@mixin on-focus {
  border-color: $modern-input-focus-color;
  box-shadow: none;
}

@mixin on-hover {
  border-color: $modern-text-black;
  box-shadow: none;
}

@mixin on-error {
  border-color: $modern-input-error-color;
}

@mixin block-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin input-state-variant($color) {
  textarea, .modern-input__icon-group {
    border: $modern-input-border-width solid $color;
    color: $modern-input-text-color;
  }

  .modern-input__caption-container,
  .modern-input__caption-text {
    color: $color;
  }

  .textarea-grabber {
    border-color: transparent $color $color transparent;
  }
}

@mixin modern-subtitle {
  margin-bottom: $gap-sm;
  font-size: $modern-font-sm;
  font-weight: $font-demi;
  line-height: $modern-line-height-condensed;
  letter-spacing: 0.5px;
  text-align: left;
  color: $modern-input-caption-color;
}

@mixin modern-text-field {
  width: 100%;
  font-weight: $font-regular;
  font-size: $modern-font-md;
  line-height: $modern-line-height;
  color: $modern-input-text-color;
  padding: $modern-input-padding;
  box-shadow: none;
  background-color: $modern-input-layer-color;
}

.modern-page {
  .formulate-input-element:not(.modern-input) input {
    @include modern-text-field;
    border: $modern-input-border-width solid $modern-input-border-color;
    border-radius: $modern-radius-lg;

    &:hover {
      @include on-hover;
    }

    &:focus {
      @include on-focus;
    }
  }

  .formulate-input-help {
    font-size: $modern-font-xs;
    font-style: normal;
    font-weight: $font-regular;
    line-height: $modern-line-height-condensed;
    color: $modern-subtext;
  }

  .formulate-input-errors {
    margin-bottom: 0;
  }
}

.modern-input__icon-group {
  display: flex;
  border: $modern-input-border-width solid $modern-input-border-color;
  border-radius: $modern-radius-lg;
  background-color: $modern-input-layer-color;
  overflow: hidden;

  .modern-input__icon-right {
    color: $modern-input-caption-color;
  }

  .modern-input__icon-left {
    color: $modern-input-placeholder-color;
  }

  input, .modern-input__icon {
    border: none;
  }
}

.modern-page .formulate-input-wrapper,
.shp-modern-modal .formulate-input-wrapper {
  input, textarea {
    @include modern-text-field;

    &[type='checkbox'] {
      width: auto;
    }

    &::placeholder {
      color: $modern-input-placeholder-color;
    }

    &:disabled {
      color: $modern-input-disabled-color;
      background: $modern-input-disabled-background;
    }

    &:disabled::placeholder {
      color: $modern-input-placeholder-color
    }
  }

  .formulate-input-element textarea {
    border: $modern-input-border-width solid $modern-input-border-color;
    border-radius: $modern-radius-lg;

    &:hover {
      @include on-hover;
    }

    &:focus {
      @include on-focus;
    }

    &:disabled {
      border: $modern-input-border-width solid $modern-input-border-color
    }
  }

  .modern-input-sm {
    input, textarea, &.textarea-wrapper:after, .input-group-addon {
      padding: $modern-input-paddings-sm;
    }
    .modern-input__icon-group {
      border-radius: $modern-radius;
    }
    & > .input-group > .input-group-addon {
      padding: $modern-input-paddings-sm;
      padding-left: 0;
    }
  }

  .modern-input-lg {
    input, textarea {
      padding: $modern-input-paddings-lg;
    }
    & > .input-group > .input-group-addon {
      padding: $modern-input-paddings-lg;
      padding-left: 0;
    }
  }

  .form-control {
    height: auto;
  }

  .formulate-input-label {
    @include modern-subtitle;
  }

  &.shp_checkbox__wrapper-inline .formulate-input-label {
    margin: 0 0 0 $gap-sm;
    font-size: $modern-font-md;
    font-weight: $font-regular;
    line-height: $modern-line-height;
    letter-spacing: 0.5px;
    text-align: left;
  }

  label.formulate-input-label--before {
    display: block;
  }
}

.modern-input-group__subtitle {
  @include modern-subtitle;
}

.modern-input__icon-left,
.modern-input__icon-right {
  @include block-center;
}

.modern-input__icon-left {
  padding: $modern-input-padding 0 $modern-input-padding $modern-input-padding;
}

.modern-input__icon-right {
  padding: $modern-input-padding $modern-input-padding $modern-input-padding 0;
}

.modern-input-sm {
  .modern-input__icon-right,
  .modern-input__icon-left {
    padding-bottom: $gap-xs;
    padding-top: $gap-xs;
  }
}

.modern-input:hover{
  .modern-input__icon-group {
    @include on-hover;
  }
}

.modern-input:focus-within {
  .modern-input__icon-group {
    @include on-focus;
  }
}

.modern-input__icon-container {
  @include block-center;
  width: $modern-line-height;
  height: $modern-line-height;
}

.modern-input__caption-container {
  display: flex;
  align-items: center;
  margin-top: $gap-xs;
  gap: $gap-xs;
  color: $modern-input-caption-color;
}

.modern-input__caption-text,
.modern-input-group__caption {
  font-size: $modern-font-xs;
  font-weight: $font-regular;
  line-height: $modern-line-height-condensed;
  letter-spacing: 0;
  text-align: left;
  color: $modern-input-caption-color;
}

.modern-input__caption-text_error {
  color: $modern-error;
}

.modern-page {
  .formulate-input.error,
  [data-type='modern_shp_input'][data-is-showing-errors],
  [data-type='modern_shp_textarea'][data-is-showing-errors] {
    textarea, .modern-input__icon-group {
      border: $modern-input-border-width solid $modern-error;
      color: $modern-input-text-color;
    }

    .textarea-grabber {
      border-color: transparent $modern-error $modern-error transparent;
    }

    textarea, .modern-input__icon-group {
      &:hover {
        @include on-hover;
      }

      &:focus-within {
        @include on-focus;
      }
    }

    .textarea-wrapper:focus-within {
      .textarea-grabber {
        border-color: transparent;
      }
    }
  }

  ul.formulate-input-errors {
    list-style: none;
    font-size: $modern-font-xs;
    font-weight: $font-regular;
    line-height: $modern-line-height-condensed;
    letter-spacing: 0;
    text-align: left;
  }

  .formulate-input.success {
    @include input-state-variant($modern-success)
  }

  .formulate-input.hover {
    .modern-input__icon-group, textarea {
      @include on-hover
    }
  }

  .formulate-input.focus {
    .modern-input__icon-group, textarea {
      @include on-focus;
    }

    .textarea-grabber {
      border-color: transparent $modern-input-focus-color $modern-input-focus-color transparent;
    }
  }
}

.modern-input-group, .modern-page .input-group {
  display: flex;
  align-items: flex-start;

  .formulate-input {
    z-index: 10;
    flex-grow: 1;

    .modern-input__icon-group, input[type="number"] {
      border-radius: 0;
    }

    &:not(:first-child) {
      .modern-phone, .modern-country {
        margin-left: -1px;
      }
    }

    &:first-child {
      .modern-input__icon-group, input, .vs__dropdown-toggle {
        border-radius: $modern-radius-lg 0 0 $modern-radius-lg;
      }
    }

    &:last-child {
      .modern-input__icon-group, input, .vs__dropdown-toggle {
        border-radius: 0 $modern-radius-lg $modern-radius-lg 0;
      }
    }
  }

  .modern-input-group_prepend,
  .modern-input-group_append,
  .input-group-btn {
    display: flex;
    width: fit-content;
  }

  .modern-input-group_prepend {
    button, .btn, .btn-modern, .modern-input-group__addon {
      border-radius: 0;
      margin-right: -1px;

      &:first-child {
        border-radius: $modern-radius-lg 0 0 $modern-radius-lg;
      }
    }
  }

  .modern-input-group_append, .input-group-btn {
    button, .btn, .btn-modern, .modern-input-group__addon, .input-group-btn {
      border-radius: 0;
      margin-left: -1px;

      &:last-child {
        border-radius: 0 $modern-radius-lg $modern-radius-lg 0;
      }
    }
  }

  .modern-dropdown {
    padding: $btn-modern-bordered-paddings-md;
  }

  .formulate-input-errors {
    margin-bottom: 0;
  }
}

.modern-input-group__addon {
  font-weight: $font-regular;
  font-size: $modern-font-md;
  line-height: $modern-line-height;
  color: $modern-input-addon-color;
  padding: $modern-input-padding;
  border: $modern-input-border-width solid $modern-input-border-color;
  border-radius: $modern-radius-lg;
  box-shadow: none;
  background: $modern-input-addon-background;
}

.modern-input-group__caption {
  margin-top: $gap-xs;
}

.modern-input {
  .textarea-wrapper {
    position: relative;

    &:not(.textarea-fixed) {
      display: inline-grid;
      width: 100%;

      &::after {
        @include modern-text-field;
        content: attr(data-value) ' ';
        word-break: break-all;
        visibility: hidden;
        white-space: pre-wrap;
        grid-area: 2 / 1;
        border: 1px solid transparent;
      }

      &.modern-input-lg::after {
        padding: $modern-input-paddings-lg;
      }
    }

    &.text-mode {
      pointer-events: none;

      .textarea-grabber {
        display: none;
      }
      textarea {
        border: initial;
        padding: initial;
        border-radius: initial;
      }
    }


    &:focus-within {
      .textarea-grabber {
        border-color: transparent $modern-input-focus-color $modern-input-focus-color transparent;
      }
    }
  }

  textarea {
    resize: vertical;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
    grid-area: 2 / 1;

    &::-webkit-resizer {
      background-color: transparent;
    }

    @include modern-scrollbar($modern-gap-xs);
  }

  .textarea-fixed textarea {
    min-height: 40px;
    grid-area: initial;
  }

  .textarea-grabber {
    width: $modern-textarea-grabber-size;
    height: $modern-textarea-grabber-size;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url(/static/img/icons/shape.svg);
    background-repeat: no-repeat;
    background-position: top left;
    border-bottom-right-radius: $modern-radius-lg;
    border: 1px solid;
    border-color: transparent $modern-input-border-color $modern-input-border-color transparent;
    pointer-events: none;

    @supports (-moz-appearance: none) {
      background: transparent;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $modern-input-layer-color inset !important;
}

.modern-page {
  [data-is-showing-errors="true"] .modern-input-group {
    .modern-phone, .modern-country {
      @include on-error;
    }
  }

  .formulate-input-errors {
    list-style-type: none;

    .formulate-input-error {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .formulate-input[data-is-showing-errors="true"], .formulate-input-element[data-is-showing-errors="true"] {
    .formulate-input-element--checkbox,
    .formulate-input-element input,
    .formulate-input-element .input-group-addon {
      @include on-error;
    }
  }

  .formulate-input, .modern-input {
    &.hide-error-messages {
      .formulate-input-errors {
        display: none;
      }
    }
  }
}


.modern-input {
  &[data-is-showing-errors="true"] {
    .modern-phone, .modern-country {
      @include on-error;
    }
  }

  &:focus-within {
    z-index: 20;
  }

  .formulate-input-errors {
    list-style-type: none;
  }

  input.modern-phone {
    padding: $modern-input-padding;
    border: $modern-input-border-width solid $modern-input-border-color;
    border-radius: $modern-radius-lg;

    &:focus {
      @include on-focus;
      z-index: 20;
    }
  }

  .modern-country {
    padding: $modern-input-padding ($gap-sm + $gap-xs);
    border: $modern-input-border-width solid $modern-input-border-color;
    border-radius: $modern-radius-lg;

    &.open {
      @include on-focus;
      z-index: 20;
    }
  }
}

.formulate-input[data-type='switch'] {
  .formulate-input-wrapper {
    display: flex;
    gap: $modern-gap-sm;

    .formulate-input-label {
      margin: 0;
      font-size: $modern-font-md;
      font-weight: $font-regular;
      line-height: $modern-line-height;
      letter-spacing: 0.5px;
      text-align: left;
      color: $modern-input-text-color;
    }

    .switch-container {
      display: flex;
      align-items: center;
    }

    .switch label {
      margin: 0;
    }
  }
}

.country-phone {
  .modern-form-group, .form-group {
    margin: 0;
  }
}

.input-group-btn {
  .btn {
    &:focus, &-success:focus {
      outline: none;
    }
    &-success:focus {
      background-color: $btn-success-bg;
      border-color: $btn-success-border;
    }
    &-danger:focus {
      background-color: $btn-danger-bg;
      border-color: $btn-danger-border;
    }
  }
}

.modern-input.text-mode {
  pointer-events: none;

  .modern-input__icon-group {
    border: initial;
  }

  .form-control {
    padding: 0;
  }
}
