$timeline-item-status-width: 32px;
$timeline-item-triangle-top-position: calc(50% - 6px);
$timeline-item-triangle-border: 10px 6px 0 6px;
$timeline-item-triangle-border-color: $modern-line transparent transparent transparent;
$timeline-item-line-top-position: 36px;
$timeline-item-line-bottom-position: 8px;
$timeline-item-line-left-position: calc(50% - 1px);
$timeline-item-line-border: 1px $modern-line solid;
$timeline-item-arrow-width: 8px;
$timeline-item-arrow-top-position: 13px;
$timeline-item-arrow-border: 3.5px 6px 3.5px 0;
$timeline-item-arrow-border-color: transparent $bg-highlight transparent transparent;

.vertical-container.shp-vertical-container {
  max-width: inherit;
}

#vertical-timeline.shp-vertical-container {
  margin-top: 0;
}

.vertical-item-block {
  margin-left: 60px;
}

.enrollee-timeline {
  .enrollee-timeline-item:not(:last-child) {
    margin-bottom: $grid-size * 3;
  }
}

.enrollee-timeline-item {
  display: flex;

  .status-container {
    width: $timeline-item-status-width;
    position: relative;

    .triangle {
      position: absolute;
      bottom: 0;
      left: $timeline-item-triangle-top-position;
      width: 0;
      height: 0;
      border-width: $timeline-item-triangle-border;
      border-color: $timeline-item-triangle-border-color;
      border-style: solid;
      z-index: 3;
    }

    .line {
      position: absolute;
      top: $timeline-item-line-top-position;
      bottom: $timeline-item-line-bottom-position;
      left: $timeline-item-line-left-position;
      width: 0;
      border: $timeline-item-line-border;
      z-index: 2;
    }
  }

  .arrow-container {
    width: $timeline-item-arrow-width;
    position: relative;

    .triangle {
      position: absolute;
      top: $timeline-item-arrow-top-position;
      right: 0;
      width: 0;
      height: 0;
      border-width: $timeline-item-arrow-border;
      border-color: $timeline-item-arrow-border-color;
      border-style: solid;
    }
  }

  .panel-container {
    flex-grow: 1;
  }

  .panel {
    border-radius: $modern-radius-xs;
  }
}

@mixin enrollee-timeline-color($color) {
  .status-container {
    .shp-circle {
      background-color: $color;
    }
  }
}

.enrollee-timeline-item-success {
  @include enrollee-timeline-color($blue)
}

.enrollee-timeline-item-info {
  @include enrollee-timeline-color($lazur)
}

.enrollee-timeline-item-danger {
  @include enrollee-timeline-color($red)
}

.enrollee-timeline-item-warning {
  @include enrollee-timeline-color($yellow)
}

.enrollee-timeline-item-amazing {
  @include enrollee-timeline-color($amazing)
}

.enrollee-timeline-item-default {
  @include enrollee-timeline-color($dark-gray)
}
