$fixed-modern-dropdown-height: 280px;

.modern-dropdown-list > .dropdown-menu,
.modern-dropdown-list.dropdown-menu,
.modern-list-group {
  padding: $modern-gap-sm;
  width: auto;
  margin: 0;
  border-radius: $modern-radius-lg;
  box-shadow: $modern-shadow-4dp;
  font-size: $modern-font-md;
  font-weight: $font-regular;
  line-height: $modern-line-height;
  letter-spacing: 0;
  text-align: left;
  list-style-type: none;
  white-space: nowrap;
  background-color: $modern-layer;
  min-width: 200px;

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    &.clickable-children > li {
      cursor: pointer;
    }
  }

    .modern-dropdown-list__title {
    display: flex;
    font-weight: $font-demi;
    align-items: center;
  }

  .modern-dropdown-list__arrow {
    transition: all .2s ease-in-out;
    display: block;
  }

  &.open {
    .modern-dropdown-list__arrow {
      transform: scale(1, -1);
    }

    .modern-dropdown-list__title {
      color: $modern-primary;
    }
  }

  .modern-list-group__item {
    padding: $modern-gap-xs;
    color: $modern-text-black;
    cursor: pointer;
    display: flex;
    border-radius: $modern-radius-sm;
    gap: $modern-gap-sm;

    & > a {
      width: 100%;
    }

    &:after {
      content: '';
      display: none;
      width: $modern-font-icon;
      height: $modern-font-icon;
      background-image: url('/static/img/root/selected-arrow.png');
      background-repeat: no-repeat;
      margin-left: auto;
    }

    &.active {
      background: $modern-primary-subpanel-soft-background;
      color: $modern-text-black;

      &:after {
        display: block;
      }
    }

    &:hover {
      background-color: $modern-grey-soft-background;
      border-radius: $modern-radius-sm;
    }

    &.modern-list-group__item_primary, &.active {
      color: $modern-primary;
    }

    &.modern-list-group__item_danger {
      color: $modern-error;
    }

    &.modern-list-group__item_subtext-color {
      color: $modern-subtext;
    }

    &.modern-active {
      background-color: $modern-primary-subpanel-soft-background;
      border-radius: $modern-radius-sm;
    }
  }

  .modern-list-group__subgroup .modern-list-group__subgroup {
    padding-inline-start: $modern-gap;
  }

  .modern-list-group__group-title {
    padding: $modern-gap-sm;
    font-size: $modern-font-sm;
    font-weight: $font-bold;
    line-height: $modern-line-height-condensed;
    letter-spacing: 1px;
    color: $modern-placeholder;
    text-transform: uppercase;
  }

  .modern-list-group__group:not(:last-child) {
    &:after {
      content: '';
      display: block;
      height: 1px;
      margin: $modern-gap-xs $modern-gap-sm;
      width: calc(100% - (2 * #{$modern-gap-sm}));
      background-color: $modern-line;
    }
  }

  .modern-list-group__group.limited-height  {
    max-height: $fixed-modern-dropdown-height;
    overflow-y: auto;

    @include modern-scrollbar();
  };

  li > a {
    margin: 0;
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  .divider {
    margin: $modern-gap-sm;
  }
}

.modern-dropdown-list.limited-height {
  .dropdown-menu {
    max-height: $fixed-modern-dropdown-height;
    overflow-y: auto;

    @include modern-scrollbar();
  }
}

.modern-list-group.modern-list-group__condensed > .dropdown-menu,
.modern-dropdown-list.modern-list-group__condensed > .dropdown-menu {
  .modern-list-group__item {
    padding-top: $modern-gap-xs;
    padding-bottom: $modern-gap-xs;
  }
}
