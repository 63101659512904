@use "sass:math";

.question .slider-selection {
  background: none repeat scroll 0 0 #BABABA;

}

.question .slider-track {
  background-image: linear-gradient(to bottom, #F3F3F3, #F5F5F5);
}

.radio.small {
  float: left;
  width: 40px;
}

.question .control-group.inline {
  margin-left: 10px;
}

.question {
  margin-left: 20px;
  margin-bottom: 5px;
}

.extra .question {
  margin-left: 0;
}

.poll_course__detail_table {
  tr {
    border-bottom: 1px solid rgba(#333, 0.25);
  }

  td {
    padding: 8px;
    padding-right: 16px;
  }
}

.poll_link__active {
  color: black;
}

.poll_link {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px;
}

.poll_question {
  padding: 16px;
  background: #fff;

  &__disabled {
    opacity: 0.75;
  }

  .shp_checklist__list {
    padding-left: 0;
  }

  .shp-radio, .shp-checkbox {
    margin-right: math.div($line-height, 4);
  }

  .poll_question__radio .pretty label:before {
    border-color: #2980b9;
  }

  .poll_question__radio .pretty input:checked ~ .state label:after {
    background-color: #333 !important;
  }

  .poll_question__radio:hover .pretty label:before {
    border-color: #20638f;
  }

  .poll_question__textarea {
    width: 100%;
    min-height: 80px;
    padding: 8px;
    resize: none;
    border: 1px solid #2980B9;
    border-radius: 4px;
  }
}

.poll-question-preview {
  position: relative;

  &--mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.5;
    background-color: white;
    z-index: 100;
  }
}

.user-polls-container {
  margin: 0 auto;
  padding: $modern-gap-md;
  max-width: $base-container-max-width;

  @media (max-width: $screen-mobile-max) {
    padding: $modern-gap-md $modern-gap;
  }
}

.user-polls-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $modern-gap-lg;

  @media (max-width: $screen-tablet-max) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $screen-mobile-max) {
    grid-template-columns: 1fr;
  }
}

.user-polls-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $modern-gap;
  padding: $modern-gap-md;
  background: $modern-layer;
  border-radius: $modern-radius-lg;

  &:not(.user-polls-item__finished) {
    cursor: pointer;

    &:hover {
      .user-poll-name {
        color: $modern-primary;
      }
    }
  }
}

.user-poll-detail {
  min-height: 100%;
  margin: 0 auto;
  max-width: math.div($base-container-max-width, 2);
}
