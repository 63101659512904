@use "sass:meta";

//   for search: text-success, text-primary, text-error, text-warning, text-amazing, text-grey, text-darkness
@mixin text_colors($args...) {
  @each $name, $color in meta.keywords($args) {
    .text-modern-#{$name} {
      color: $color;
    }

    .modern-page .text-#{$name} {
      color: $color;
    }
  }
}

@mixin background_colors($args...) {
  @each $name, $color in meta.keywords($args) {
    .bg-modern-#{$name} {
      background-color: $color;
    }

    .modern-page .bg-#{$name} {
      background-color: $color;
      color: inherit;
    }
  }
}

@include text_colors(
  $error: $modern-error,
  $primary: $modern-primary,
  $primary-pressed: $modern-primary-pressed,
  $primary-disable: $modern-primary-disable,
  $success: $modern-success,
  $warning: $modern-warning,
  $amazing: $modern-amazing,
  $white: $modern-text-white,
  $black: $modern-text-black,
  $disable: $modern-disable,
  $grey: $modern-disable,
  $subtext-color: $modern-subtext,
  $color-caption: $modern-subtext,
  $placeholder-color: $modern-placeholder,
  $orange: $modern-orange,
  $grey-dark: $modern-line,
);

@include background_colors(
  $error: $modern-error-background,
  $primary: $modern-primary-background,
  $success: $modern-success-background,
  $success-disable: $modern-success-disable,
  $warning: $modern-warning-background,
  $warning-disable: $modern-warning-disable,
  $amazing: $modern-amazing-background,
  $grey: $modern-background,
  $white: $modern-layer,
  $primary-dark: $modern-primary-dark-background,
  $primary-main: $modern-primary,
);

@mixin typography_case($garniture, $size, $line-height, $spacing) {
  font-weight: $garniture;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $spacing;
}

.modern-page {
  font-family: "DIN 2014 Rounded", Arial, sans-serif;
  color: $modern-text-black;
  @include typography_case($font-regular, $modern-font-md, $modern-line-height, 0.5px);

  .text-title {
    @include typography_case($font-demi, $modern-mobile-font-title, $modern-mobile-line-height-title, 0)
  }

  h1, .h1 {
    @include typography_case($font-demi, $modern-mobile-font-h1, $modern-mobile-line-height-h1, 0)
  }

  h2, .h2 {
    @include typography_case($font-demi, $modern-mobile-font-h2, $modern-mobile-line-height-h2, 0)
  }

  h3, .h3 {
    @include typography_case($font-demi, $modern-mobile-font-h3, $modern-mobile-line-height-h3, 0)
  }

  h4, .h4 {
    @include typography_case($font-demi, $modern-font-h4, $modern-line-height, 0)
  }

  .text-subtitle {
    @include typography_case($font-demi, $modern-font-sm, $modern-line-height-condensed, 0.5px)
  }

  .text-caption {
    @include typography_case($font-regular, $modern-font-xs, $modern-line-height-condensed, 0)
  }

  .text-button {
    @include typography_case($font-demi, $modern-font-md, $modern-line-height, 0.5px)
  }

  .text-label {
    @include typography_case($font-bold, $modern-font-sm, $modern-line-height-condensed, 1px);
    text-transform: uppercase;
  }

  .text-overlay {
    @include typography_case($font-bold, $modern-font-sm, $modern-line-height-condensed, 1px);
    text-transform: uppercase;
  }

  .text-tag {
    @include typography_case($font-regular, $modern-font-sm, $modern-line-height-condensed, 0)
  }

  .text-overline {
    @include typography_case($font-bold, $modern-font-sm, $modern-line-height-condensed, 1px)
  }

  p {
    margin-bottom: $modern-p-margin;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .text-disabled {
    color: $modern-disable;
  }

  .text-link {
    color: $modern-primary;
  }

  .text-danger {
    color: $modern-error;
  }

  .shp-link, a {
    color: $modern-primary;

    &:hover {
      color: $modern-primary-hover;
    }
  }

  .text-decoration-line-through {
    text-decoration-line: line-through;
  }

  @media (min-width: 768px) {
    .text-title {
      @include typography_case($font-demi, $modern-font-title, $modern-line-height-title, 0)
    }
    h1, .h1 {
      @include typography_case($font-demi, $modern-font-h1, $modern-line-height-h1, 0)
    }
    h2, .h2 {
      @include typography_case($font-demi, $modern-font-h2, $modern-line-height-h2, 0)
    }
    h3, .h3 {
      @include typography_case($font-demi, $modern-font-h3, $modern-line-height-h3, 0)
    }
  }
}

.font-bold {
  font-weight: $font-bold;
}

.font-demi {
  font-weight: $font-demi;
}

.font-regular {
  font-weight: $font-regular;
}

.text-dashed {
  border-bottom: 1px dashed $modern-text-black;
}

.text-through {
  text-decoration: line-through;
}

.text-first-letter-up {
  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin text-marker-size($size) {
  margin-right: calc($modern-gap-sm * 2) + $size;

  &::after {
    right: -($modern-gap-sm + $size);
    height: $size;
    width: $size;
  }
}

.text-marker {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: $label-gray-color;
  }

  &:last-child::after {
    background: transparent;
  }

  @include text-marker-size(4px);

  &.text-marker-sm {
    @include text-marker-size(2px);
  }

  &.text-marker-md {
    @include text-marker-size(6px);
  }
}
