
.shp-circle {
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  font-size: 12px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  color: #FFFFFF;
  position: relative;
  margin-right: 4px;
}

.shp-circle.shp-circle-md {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 14px;
}

.shp-circle.shp-circle-lg {
    height: 26px;
    width: 26px;
    line-height: 26px;
    font-size: 14px;
}

.shp-circle.shp-circle-sm {
    height: 12px;
    width: 12px;
    min-width: 12px;
    line-height: 12px;
    font-size: 5px;
}

.shp-circle.shp-circle-xs {
    height: 8px;
    width: 8px;
    min-width: 8px;
    line-height: 8px;
    font-size: 5px;
}

.shp-circle.shp-circle-xxs {
  height: 4px;
  width: 4px;
  min-width: 4px;
  line-height: 4px;
  font-size: 2px;
}

.bootstrap-datetimepicker-widget .shp-circle {
    margin-right: 0;
}

.inline-datepicker .bootstrap-datetimepicker-widget table td.day {
    max-width: 20px;
    height: 26px;
    min-height: 26px;
  line-height: 26px;
}


@mixin shp-circle-variant ($color) {
  background-color: $color;
}

.shp-circle-danger {
  @include shp-circle-variant($red)
}

.shp-circle-warning {
  @include shp-circle-variant($yellow)
}

.shp-circle-info {
  @include shp-circle-variant($lazur)
}

.shp-circle-navy {
  @include shp-circle-variant($navy)
}

.shp-circle-lazur {
  @include shp-circle-variant($lazur)
}

.shp-circle-success {
  @include shp-circle-variant($blue)
}

.shp-circle-amazing {
  @include shp-circle-variant($amazing)
}

.shp-circle-default {
  @include shp-circle-variant($dark-gray)
}

.shp-circle-gray {
  @include shp-circle-variant($placeholder-color)
}

.shp-circle-center {
  position: relative;
  top: 4px;
  display: inline-block;
}
