.help-icon {
    min-width: 70px;
    margin-right: 20px;
    vertical-align: middle;
    display: table-cell;
}

.help-content {
    width: 100%;
    display: table-cell;
}

.help-item.closed .help-body{
    display: none;
}

.help-item .help-body{
    display: block;
}

.help-item.closed .help-open {
    display: block;
}

.help-item.closed {
    display: inline-block;
}

.help-item .help-open {
    display: none;
}

.bs-callout.bs-callout-info .help-icon {
    color: #1b809e;
}
