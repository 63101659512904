.sortable {
  border: 1px dashed transparent;
  transition: border .4s;

  &-placeholder {
    border: 1px dashed #cecece;
  }

  &-drag {
    opacity: 1;
  }

  &-chosen {
    opacity: 1;
  }
}
