.btn-amazing {
  @include button-variant($btn-amazing-color, $btn-amazing-bg, $btn-amazing-border);
}

.btn-inverse {
  @include button-variant($btn-inverse-color, $btn-inverse-bg, $btn-inverse-border);
}

.btn-white {
  @include button-variant(#333, #fff, #ccc);
}

.btn-red {
  @include button-variant($text-color-white, $red, $red);
}

.btn-red-inverse {
  @include button-variant($red, $text-color-white, $text-color-white);
}
