.searchable_field {
    background-image: url('../../img/triangle_icon.png');
    background-position: right bottom;
    background-repeat: no-repeat;
}

.searchable_field_in_many_to_many{
    float: left;
    border-radius: 3px 0 0 3px !important;
    width: 200px;
}
