$classes-navigation-sidebar-width: 328px;
$classes-navigation-top-nav-bar-height: 56px;

.modern-navigation.classes-navigation {
  .navigation-header .top-nav-bar {
    height: $classes-navigation-top-nav-bar-height;
  }
}

.modern-navigation.classes-navigation {
  .top-nav-bar {
    height: $classes-navigation-top-nav-bar-height;
    display: flex;
    align-items: center;
    gap: $modern-gap;
    justify-content: space-between;
    border-bottom: 1px solid $modern-grey-soft-background;
  }

  .classes_navigation_title {
    font-size: $modern-font-h4;
    font-weight: $font-demi;
    line-height: $modern-line-height;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .btn-modern {
      padding: 0;
    }

    @media (max-width: $screen-mobile-max) {
      font-size: $modern-font-md;
      font-weight: $font-regular;
    }
  }

  .content-wrapper {
    position: relative;

    .classes_navigation_sidebar {
      position: fixed;
      top: $first-level-menu-item-height + $modern-navigation-border-bottom;
      bottom: 0;
      background-color: $modern-layer;
      padding: $modern-gap-md;
      width: $classes-navigation-sidebar-width;
      height: calc(100% - #{$first-level-menu-item-height});
      overflow-x: auto;
      flex-shrink: 0;
      z-index: 1000;
      @include modern-scrollbar();

      left: 0;
      transition: left 0.4s ease-in-out;

      &.hide-to-left-side {
        left: -$classes-navigation-sidebar-width;
      }

      @media (max-width: $screen-tablet-max) {
        position: fixed;
        z-index: 1000;
      }
    }

    .main-content {
      position: relative;
      left: $classes-navigation-sidebar-width;
      max-width: calc(100% - ($classes-navigation-sidebar-width));
      transition: left 0.4s ease-in-out, max-width 0.4s ease-in-out;

      @media (max-width: $screen-tablet-max) {
        left: 0;
        max-width: 100%;
      }
    }

    .classes_navigation_sidebar.hide-to-left-side + .main-content {
      left: 0;
      max-width: 100%;
    }
  }

  .classes_navigation_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $modern-gap-condensed;
  }
}

.classes-navigation-step, .classes-navigation-unit {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.classes-navigation-step-element {
  border-radius: $modern-radius-sm;
  cursor: pointer;
  padding: $modern-gap-sm;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: $modern-gap-sm;

  &:hover {
    background-color: $modern-background;
  }

  &.active {
    background-color: $modern-grey-soft-background;
  }

  &:disabled, &.disabled {
    background-color: unset;
    cursor: not-allowed;
  }
}