@mixin paginator-modern($prev, $next, $refresh, $item) {
  display: flex;
  align-items: center;
  gap: $modern-gap;
  margin: 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: $modern-line-height;
    border: none;
    background-color: transparent;
    padding: 0;

    &:focus {
      outline: none;
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
    }
  }

  #{$prev},
  #{$next},
  #{$refresh} {
    padding: $modern-gap-xs $modern-gap-sm;
    border-radius: $modern-radius-lg;
    background-color: $modern-layer;
    color: $modern-text-black;
    border: 1px solid $modern-line;

    &:not(.disabled):focus-within {
      outline: 1px solid $modern-primary-outline;
      outline-offset: 1px;
    }

    &.disabled {
      background-color: $modern-disable;
    }

    &:not(.disabled) {
      a:hover {
        color: $modern-subtext;
      }

      a:focus {
        color: $modern-text-black;
      }
    }
  }

  #{$item} {
    border-radius: $modern-radius;
    white-space: nowrap;

    a {
      color: $modern-subtext;
      min-width: 8px;
      padding: 0 $modern-gap-sm;
    }

    &.active {
      background: $modern-primary-disable;

      a {
        color: $modern-text-black;
      }
    }

    &:not(.active):not(.disabled):hover {
      background-color: $modern-layer;
    }
  }
}

@mixin paginator-compact() {
  flex-direction: column;
  gap: $modern-gap;

  > div:first-child, .modern-paginator__items-amount {
    flex-basis: auto;
    width: 100%;
  }

  .modern-paginator__pagination {
    gap: $modern-gap-sm;

    .pagination {
      gap: $modern-gap-sm;
    }
  }
}

.modern-paginator__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "DIN 2014 Rounded", Arial, sans-serif;
  font-size: $modern-font-md;
  font-weight: $font-regular;
  line-height: $modern-line-height;
  letter-spacing: 0;

  > div:first-child {
    flex-basis: 55%;
  }

  .modern-paginator__rows-handler {
    display: flex;
    align-items: center;

    .modern-paginator__row-active {
      display: flex;
      align-items: center;
      gap: $modern-gap-sm;
      background-color: $modern-layer;
      border-radius: $modern-radius-lg;
      padding: $modern-gap-xs $modern-gap-sm;
      min-width: 48px;
      cursor: pointer;
      border: 1px solid $modern-line;
    }

    .open .dropdown-menu {
      min-width: $mobile-item-touchable-size;
      margin-top: $modern-gap-xs;
      margin-left: $modern-gap-xxs;
      border-radius: $modern-radius-sm;
      box-shadow: $modern-shadow-4dp;
      font-size: $modern-font-md;
      overflow: hidden;

      li {
        cursor: pointer;
        padding: $modern-gap-xs $modern-gap-sm;

        &:hover {
          background-color: $modern-disable;
        }

        &.active {
          color: $modern-primary;
        }
      }
    }
  }

  .modern-paginator__items-amount {
    text-align: right;
    flex-basis: 45%;
    color: $modern-placeholder;
  }

  .modern-paginator__pagination {
    display: flex;
    gap: $modern-gap;

    .pagination {
      @include paginator-modern(
        '.paginator__prev-item',
        '.paginator__next-item',
        '.paginator__refresh',
        '.paginator-item'
      );
    }
  }
}

.modern-paginator__container.paginator-compact {
  @include paginator-compact();
}

@media (max-width: $screen-mobile-max) {
  .modern-paginator__container {
    @include paginator-compact();
  }
}

.modern-page {
  .default-paginator {
    display: flex;
    gap: $modern-gap;

    .pagination {
      @include paginator-modern(
        'li:first-child',
        'li:last-child',
        'li.default-paginator__refresh',
        'li:not(:first-child):not(:last-child)'
      );

      li:first-child, li:last-child {
        a {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
