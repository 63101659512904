$modern-badge-text-color-white: $modern-text-white;
$modern-badge-text-color-black: $modern-text-black;
$modern-badge-grey-background: $modern-grey;
$modern-badge-white-background: $modern-layer;
$modern-badge-blue-background: $modern-primary;
$modern-badge-green-background: $modern-success;
$modern-badge-red-background: $modern-error;
$modern-badge-yellow-background: $modern-warning-pressed;
$modern-badge-orange-background: $modern-orange;
$modern-badge-violet-background: $modern-amazing;

@mixin modern-badge-size-variants($font-size, $modern-line-height, $min-width, $font-weight) {
  padding: $modern-gap-xs $modern-gap-xs;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $modern-line-height;
  min-width: $min-width;
}

.modern-badge {
  display: inline-block;
  text-align: center;
  line-height: $modern-line-height-condensed;
  font-weight: $font-demi;
  font-size: $modern-font-sm;
  border-radius: $modern-radius-xs;
  vertical-align: middle;
  padding: 0 $modern-gap-xs;
  color: $modern-badge-text-color-white;

  &.modern-badge-round {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $modern-gap-xs;
    border-radius: 50%;
    width: $modern-gap-md;
    height: $modern-gap-md;

    $round_badge_sizes: (
      xxs : $modern-line-height-xxs,
      xs : $modern-line-height-h4,
      sm : $modern-line-height-h3,
      md : $modern-line-height-h2,
      lg : $modern-line-height-h1,
      xl : $modern-line-height-title,
    );

    @each $size_name, $size in $round_badge_sizes {
      &.modern-badge-#{$size_name} {
        width: $size;
        height: $size;
      }
    }
  }

  $badge_text_colors: (
    "black": $modern-badge-text-color-black,
    "white": $modern-badge-text-color-white,
  );

  @each $color_name, $text_color in $badge_text_colors {
    &.modern-badge-text-#{$color_name} {
      color: $text_color;
    }
  }

  $badge_colors: (
    "white": $modern-badge-white-background,
    "grey": $modern-badge-grey-background,
    "blue": $modern-badge-blue-background,
    "green": $modern-badge-green-background,
    "red": $modern-badge-red-background,
    "yellow": $modern-badge-yellow-background,
    "orange": $modern-badge-orange-background,
    "violet": $modern-badge-violet-background
  );

  @each $color_name, $bg-color in $badge_colors {
    &.modern-badge-#{$color_name} {
      background-color: $bg-color;
    }
  }

  &.modern-badge-xs {
    @include modern-badge-size-variants($modern-font-sm, $modern-line-height-condensed, 14px, $font-regular);
  }

  &.modern-badge-sm {
    @include modern-badge-size-variants($modern-font-md, $modern-line-height, 24px, $font-demi);
  }

  &.modern-badge-md {
    @include modern-badge-size-variants($modern-font-h4, $modern-line-height-h4, 30px, $font-demi);
  }

  &.modern-badge-lg {
    @include modern-badge-size-variants($modern-font-h3, $modern-line-height-h3, 42px, $font-demi);
  }

  &.modern-badge-xl {
    @include modern-badge-size-variants($modern-font-h2, $modern-line-height-h2, 52px, $font-demi);
  }
}
