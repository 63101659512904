.shp-media {
  position: relative;
  .media-body {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .shp-media.shp-media-sm {
    .media-body {
      display: block;
      width: auto;
    }
  }
}
