.shp-file {
    border: 1px $border-color solid;
    border-radius: 4px;
    margin-bottom: 20px;
}

.file-footer-container {
    position: relative;
    margin-bottom: -8px;
}

.file-footer {
    position: absolute;
    background-color: white;
    visibility: hidden;
    width: 100%;
    padding: 8px 6px;
    border-top: 1px solid #e3e3e3;
    bottom: -100%;
}

.shp-file:hover .file-footer {
    visibility: visible;
}

.file-header-container {
    position: relative;
    margin-top: -8px;
    z-index: 20;
}

.file-header-container.file-header-hover {
    z-index: 21;
    /*position: absolute;*/
}

.file-header-container.file-header-hover {
    min-height: 0;
}

.file-header-container.file-header-hover .file-header {
    background-color: white;
    visibility: hidden;
    top: 0;
}

.file-header-container.file-header-hover:hover .file-header {
    visibility: visible;
}

.file-header {
    position: absolute;
    overflow: hidden;
    width: 100%;
    padding: 8px 6px;
    /*border-bottom: 1px solid #e3e3e3;*/
    background-color: white;
}

.file-header .shp-file-name {
    display: inline-block;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-header .file-extension {
    display: inline-block;
    position: absolute;
}

.file-header .file-name-spacer {
    height: 0;
    padding-right: 3px;
    visibility: hidden;
}

.file-header .file-extension-spacer {
    float: right;
    visibility: hidden;
}

.file-header .shp-file-title {
    position: static;
    overflow: hidden;
    padding-left: 16px;
}

.file-header .file-header-icon {
    position: absolute;
}

.file-header .file-header-extension {
    position: absolute;
    right: 0;
    padding-left: 4px;
    z-index: 14;
}

.file .file-content {
    padding-left: 8px;
    padding-right: 8px;
}

.file:hover .file-header {
    visibility: visible;
}

.file .file-header .file-icon {
    visibility: visible;
}

.material__title {
    font-size: $font-lg;
}

.material-file__text-content {
    font-size: $font-lg;

    pre {
        font-size: $font-lg;
    }
}
