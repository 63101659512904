.panel > .bs-callout {
    margin-top: 0;
    margin-bottom: 0;
    border-width: 0 0 0 5px;
    border-radius: 0;
}

.panel > .bs-callout ~ .panel-body, .panel > .panel-body ~ .bs-callout {
    border-top-width: 1px;
}

.panel.panel-with-table table {
    margin-bottom: 0;
}

.bs-callout {
    border-color: #eee;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    margin: 20px 0;
    padding: 20px;
    background-color: #FFF;
}

.bs-callout.bs-callout-medium {
    margin: 10px 0;
    padding: 6px 14px 6px 14px;
}

.bs-callout h4 {
    margin-bottom: 5px;
    margin-top: 0;
}
.bs-callout p:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}
.bs-callout + .bs-callout {
    margin-top: -5px;
}

.bs-callout.bs-callout-block {
  margin-top: 0;
  margin-bottom: 0;
}


@mixin shp-callout-variant ($color, $text-color) {
  border-left-color: $color;
  h4 {
    color: $text-color;
  }
}

.bs-callout-danger {
  @include shp-callout-variant($red, $red-text)

}

.bs-callout-warning {
  @include shp-callout-variant($yellow, $yellow-text)
}

.bs-callout-info {
  @include shp-callout-variant($lazur, $lazur-text)
}

.bs-callout-success {
  @include shp-callout-variant($blue, $blue-text)
}

.bs-callout-amazing {
  @include shp-callout-variant($amazing, $amazing-text)
}

.bs-callout.bs-callout-mini {
    margin: 0;
    margin-bottom: 10px;
}

.bs-callout.no-top {
    margin-top: 0;
}

.errors.help-block {
  color: $red-text
}
