@use "sass:meta";
@use "sass:math";

.modern-panel {
  border-radius: $modern-radius-lg;
  background-color: $modern-panel-layer;

  .panel-heading {
    padding: $modern-gap-md;
    border: none;

    &.panel-heading-plug {
      padding: $modern-gap $modern-gap-md;
    }

    .panel-title {
      font-size: $modern-font-h3;
      font-weight: $font-demi;
      line-height: $modern-line-height-h3;
      word-break: break-word;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    @media (max-width: $screen-mobile-max) {
      padding: $modern-gap $modern-gap 0 $modern-gap;
    }
  }

  .panel-heading ~ .panel-body,
  .panel-body ~ .panel-body {
    padding-top: 0;
  }

  .panel-body {
    padding: $modern-gap-md;

    &.padding-none {
      padding: 0;
    }

    @media (max-width: $screen-mobile-max) {
      padding: $modern-gap;
    }
  }

  .panel-footer {
    padding: $modern-gap 0 $modern-gap-md 0;
    margin: 0 $modern-gap-md;
    border-top: $modern-panel-border-width solid $modern-line;
    background-color: $modern-panel-layer;

    &.no-border {
      border: none;
    }
  }

  .panel-body-extended.panel-body {
    padding-left: 0;
    padding-right: 0;

    .table-modern {
      border-radius: 0;
    }

    .modern-paginator__container {
      padding-right: $modern-gap-md;
      padding-left: $modern-gap-md;
    }
  }

  &.transparent-background {
    background-color: transparent;
  }

  @media (max-width: $screen-mobile-max) {
    .panel-heading {
      padding: $modern-gap $modern-gap $modern-gap-md $modern-gap;

      &:only-child {
        padding-bottom: $modern-gap;
      }

      &.panel-heading-plug {
        padding: $modern-gap;
      }

      .panel-title {
        font-size: $modern-mobile-font-h3;
        font-weight: $font-bold;
        line-height: $modern-mobile-line-height-h3;
      }
    }

    .panel-body {
      padding: $modern-gap $modern-gap $modern-gap-md $modern-gap;

      &:last-child {
        padding-bottom: $modern-gap;
      }
    }

    .panel-footer {
      padding: $modern-gap 0;
      margin: 0 $modern-gap;
    }
  }
}

@mixin modern-panel-size($size) {
  .panel-heading {
    padding: $size $size 0 $size;
  }

  .panel-body {
    padding: $size $size;
  }

  .panel-footer {
    padding: $size 0;
    margin: 0 $size;
  }

  &.modern-panel-decorated {
    .panel-heading, .panel-body, .panel-footer {
      padding: $size;
    }
  }
}

.modern-panel.modern-panel-s {
  @include modern-panel-size($modern-gap-sm)
}

.modern-panel.modern-panel-md {
  @include modern-panel-size($modern-gap-md)
}

.modern-panel.modern-panel-sm {
  .panel-heading {
    padding: $modern-gap-sm $modern-gap 0 $modern-gap;
  }

  .panel-body {
    padding: $modern-gap $modern-gap;
  }

  .panel-footer {
    padding: $modern-gap-sm 0;
    margin: 0 $modern-gap;
  }

  @media (max-width: $screen-mobile-max) {
    .panel-heading {
      padding: $modern-gap $modern-gap $modern-gap-md $modern-gap;

      &:only-child {
        padding-bottom: $modern-gap;
      }
    }
  }
}

.modern-panel.modern-panel-lg {
  .panel-heading {
    padding: $modern-gap-lg $modern-gap-lg 0 $modern-gap-lg;
  }

  .panel-body {
    padding: $modern-gap-lg $modern-gap-lg;
  }

  .panel-footer {
    padding: $modern-gap-lg 0;
    margin: 0 $modern-gap-lg;
  }
}


.modern-panel.modern-panel-dashed {
  border: $modern-panel-border-width dashed $modern-panel-black;
  background-color: transparent;
}

.modern-panel.modern-panel-bordered {
  border: $modern-panel-border-width solid $modern-panel-black;
}

@mixin modern-panel-decorated-border($border-color) {
  border: $modern-panel-decorated-outside-border solid $border-color;
  border-top: $modern-panel-decorated-border-top solid $border-color;
  background-color: $border-color;

  > *:not(:last-child) {
    border-bottom: $modern-panel-border-width solid $border-color
  }
}

@mixin modern-panel-decorated-background($background-color) {
  .panel-heading, .panel-body, .panel-footer {
    background-color: $background-color;
  }
}

.modern-panel.modern-panel-decorated {
  @include modern-panel-decorated-border($modern-panel-black);
  @include modern-panel-decorated-background($modern-panel-layer);

  > *:first-child {
    border-top-left-radius: $modern-radius-lg;
    border-top-right-radius: $modern-radius-lg;
  }

  > *:last-child {
    border-bottom-right-radius: $modern-radius-lg;
    border-bottom-left-radius: $modern-radius-lg;
  }

  .panel-heading, .panel-body, .panel-footer {
    padding: $modern-gap-md;
    margin: 0;
  }
}

.padding-transition {
  transition: 0.3s padding ease-out;
}

.modern-panel {
  .panel-collapsed {
    .panel-heading {
      padding: $modern-gap-md;
    }

    &.modern-panel-sm .panel-heading {
      padding: $modern-gap-sm $modern-gap;
    }
  }

  .collapse, .collapsing {
    .panel-body {
      padding-top: 0;
    }
  }
}

.modern-panel.panel-collapsed.resizable-padding {
  .panel-heading {
    padding: $modern-gap $modern-gap-md;
  }

  @media (max-width: $screen-mobile-max) {
    .panel-heading {
      padding: $modern-gap;
    }
  }
}

.modern-placement {
  border-radius: $modern-radius-lg;
  background-color: $modern-panel-layer;
  padding: $modern-gap-md;

  &.transparent-background {
    background-color: transparent;
  }

  &.giant-padding {
    padding-right: $modern-gap-4xl;
    padding-left: $modern-gap-4xl;

    @media (max-width: $screen-mobile-max) {
      padding-right: $modern-gap-md;
      padding-left: $modern-gap-md;
    }
  }

  &.no-padding {
    padding: 0;
  }
}

.modern-placement.modern-placement-dashed {
  border: $modern-panel-border-width dashed $modern-panel-black;
  background-color: transparent;
}

.modern-placement.modern-placement-sm {
  padding: $modern-gap;
}

.modern-placement.modern-placement-xs {
  padding: $modern-gap-sm;
}

.modern-placement.modern-placement-xxs {
  padding: $modern-gap-xs;
}

.modern-placement.modern-placement-lg {
  padding: $modern-gap-lg;
}

.modern-placement.modern-placement-rounded {
  border-radius: 50%;
}

.modern-placement.modern-placement-avatar {
  padding: 2px $modern-gap-sm;
}

@media (max-width: $screen-mobile-max) {
  .modern-placement, .modern-placement.modern-placement-lg {
    padding: $modern-gap;
  }
  .modern-placement.modern-placement-xs {
    padding: $modern-gap-sm;
  }
}

.modern-panel.panel-horizontal {
  .panel-field {
    margin-top: 0;
    margin-right: $modern-gap-lg;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin panel-colors($args...) {
  @each $name, $color in meta.keywords($args) {
    .modern-panel.modern-panel-#{$name},
    .modern-placement.modern-placement-#{$name} {
      border: $modern-panel-border-width solid $color;
    }

    .modern-panel.modern-panel-decorated.modern-panel-#{$name} {
      @include modern-panel-decorated-border($color);
    }
  }
}

@include panel-colors(
  $layer: $modern-panel-layer,
  $black: $modern-panel-black,
  $grey: $modern-panel-grey,
  $grey-soft: $modern-panel-grey-soft,
  $primary-subpanel-soft: $modern-primary-subpanel-soft-background,
  $primary-subpanel: $modern-primary-subpanel-background,
  $primary-subpanel-dark: $modern-primary-subpanel-dark-background,
  $primary-soft: $modern-panel-primary-soft,
  $primary: $modern-panel-primary,
  $primary-dark: $modern-panel-primary-dark,
  $error: $modern-panel-error,
  $error-soft: $modern-panel-error-soft,
  $success: $modern-panel-success,
  $success-soft: $modern-panel-success-soft,
  $warning: $modern-panel-warning,
  $warning-soft: $modern-panel-warning-soft,
  $amazing: $modern-panel-amazing,
  $amazing-soft: $modern-panel-amazing-soft,
);

@mixin placement-backgrounds($args...) {
  @each $name, $color in meta.keywords($args) {
    .modern-panel.modern-panel-bgc-#{$name}:not(.modern-panel-decorated),
    .modern-placement.modern-placement-bgc-#{$name} {
      background-color: $color;
    }

    .modern-panel.modern-panel-decorated.modern-panel-bgc-#{$name} {
      @include modern-panel-decorated-background($color);
    }
  }
}

@include placement-backgrounds(
  $background: $modern-panel-background,
  $layer: $modern-panel-layer,
  $black: $modern-panel-black,
  $grey: $modern-panel-grey,
  $grey-soft: $modern-panel-grey-soft,
  $primary-subpanel-soft: $modern-primary-subpanel-soft-background,
  $primary-subpanel: $modern-primary-subpanel-background,
  $primary-subpanel-dark: $modern-primary-subpanel-dark-background,
  $primary-soft: $modern-panel-primary-soft,
  $primary: $modern-panel-primary,
  $primary-dark: $modern-panel-primary-dark,
  $error: $modern-panel-error,
  $error-soft: $modern-panel-error-soft,
  $success: $modern-panel-success,
  $success-soft: $modern-panel-success-soft,
  $warning: $modern-panel-warning,
  $warning-soft: $modern-panel-warning-soft,
  $amazing: $modern-panel-amazing,
  $amazing-soft: $modern-panel-amazing-soft,
);

.modern-panel.modern-panel-dashed,
.modern-placement.modern-placement-dashed {
  border-style: dashed;
}
