.classes_lessons_list__import_text {
  display: none;
}

.classes_lessons__panel {
  flex: 1;

  .classes_lessons__panel_header {
    background: white;
    padding: 1px;
    margin-bottom: 16px;
    text-align: center;
  }
  .classes_lessons__panel_body {
    background: white;
    padding: 16px 24px;
  }
}