.shp-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 500px;
  height: 350px;
  background: $bg-color;
  border: 1px solid transparent;
  z-index: 1000;
  resize: both;
  overflow: hidden;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: $border-radius-base;

  .webinar {
    height: 100%;
  }

  &__content {
    height: calc(100% - #{$line-height} - 5px);
    overflow: hidden;

    div {
      height: 100%;
    }
  }

  &__title {
    cursor: grab;
    display: flex;
    justify-content: space-between;
    background: white;
    align-items: center;
    height: $line-height;


    &_is-grabbing {
      cursor: grabbing;
    }
  }

}

.modal.shp-modal-metro {
  @media (min-width: $screen-md-min) {
    .modal-lg {
      width: $modal-lg;
    }
  }

  .modal-content {
    border-radius:8px;
  }

  .modal-header {
    border-bottom: 0;
    padding: $line-padding;
    .modal-title {
      h1 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .modal-body {
    padding: 0 $line-padding 0;
    textarea {
      resize: vertical;
    }
    .thanks{
      text-align: center;
      font-size: 16px;
    }
  }

  .modal-footer {
    padding: $line-padding;
    margin-top: 0;
    border-top: 0;
    text-align: center;
  }
}
