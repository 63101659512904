@use "sass:math";

.row-sm {
  &.row {
    margin-right: - math.div($line-height, 4);
    margin-left: - math.div($line-height, 4);
  }

  [class*="col-"] {
    padding-right: math.div($line-height, 4);
    padding-left: math.div($line-height, 4);
  }
}

.grid {
  display: grid;
}

.grid-two-columns {
  grid-template-columns: 1fr 1fr;
}

.grid-mobile-one-column {
  @media (max-width: $screen-mobile-max) {
    grid-template-columns: 1fr;
  }
}
