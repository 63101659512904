$avatar-sm-size: 32px;
$avatar-default-size: 38px;
$avatar-lg-size: 48px;
$avatar-xl-size: 56px;

.avatar {
  height: $avatar-default-size;
  width: $avatar-default-size;
}

.avatar img {
  border-radius: 4px;
}

.avatar.bordered-success {
  border: 2px solid #27ae60;
}

.shp-avatar {
  max-width: 200px;
}

.shp-avatar img {
  margin-bottom: 4px;
}

.teacher-avatar {
  height: 64px;
  width: 64px;
}

.avatar-mini {
  height: $avatar-lg-size;
  width: $avatar-lg-size;
}


.teacher-avatar.teacher-avatar-lg {
  height: 96px;
  width: 96px;
}

.inline-block, .img-responsive.inline-block {
  display: inline-block;
}

@mixin avatar_size($avatar-size, $indicator-size, $badge-size) {
  width: $avatar-size;
  height: $avatar-size;

  .shp-modern-avatar__indicator {
    width: $indicator-size;
    height: $indicator-size;
  }

  .shp-modern-avatar__badge {
    width: $badge-size;
    height: $badge-size;
  }
}


.shp-modern-avatar {
  position: relative;
  width: $avatar-default-size;
  height: $avatar-default-size;

  &.shp-modern-avatar_sm {
    @include avatar_size($avatar-sm-size, 8px, 18px)
  }

  &.shp-modern-avatar_lg {
    @include avatar_size($avatar-lg-size, 12px, 20px)
  }

  &.shp-modern-avatar_xl {
    @include avatar_size($avatar-xl-size, 14px, 22px)
  }

  .shp-modern-avatar__indicator, .shp-modern-avatar__badge {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    font-size: $modern-font-xs;
    font-style: normal;
    font-weight: $font-regular;
    line-height: $modern-line-height-condensed;
  }

  .shp-modern-avatar__badge {
    width: 20px;
    height: 20px;
    color: $modern-text-white;
    background: $modern-text-black;
    border: 1px solid $modern-layer;
  }

  .shp-modern-avatar__indicator {
    width: 10px;
    height: 10px;
    color: $modern-text-white;
    background: $modern-text-black;
    border: 2px solid $modern-layer;
  }
}

.pupil-avatar {
  position: relative;
  display: inline-block;
  background-color: $modern-layer;
  border-radius: 50%;

  &.pupil-avatar--editable {
    cursor: pointer;
  }

  .pupil-avatar__edit-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    .pupil-avatar__edit-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
