.formulate-input {
  &[data-classification="file"] {
    .formulate-input-upload-area {
      width: 100%;
      position: relative;
      padding: 2em 0;

      input {
        cursor: pointer;
        appearance: none;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }

      &[data-has-files] {
        padding: 0;

        input {
          display: none;
        }
      }

      &-mask {
        border-radius: .4em;
        position: absolute;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 2px dashed $navy;
        z-index: 2;

        &::before {
          content: '';
          background-color: $navy;
          -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
          -moz-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
          width: 2em;
          height: 2em;
          position: absolute;
          pointer-events: none;
        }
      }
    }

    .formulate-files {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .formulate-file-add {
        appearance: none;
        display: block;
        min-width: 75px;
        border: 1px solid $navy;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
        position: relative;
        font-size: 13px;
        background-color: transparent;
        cursor: pointer;
        margin: .5em 0;

        input {
          position: absolute;
          display: block;
          cursor: pointer;

          &::-webkit-file-upload-button {
            display: none;
          }
        }
      }

      .formulate-file-progress {
        background-color: $gray;
        height: .3em;
        border-radius: 1.25em;
        width: 5em;
        overflow: hidden;
        position: relative;
        flex: 0 0 5em;
        right: .75em;
        transition: height .25s, width .25s, flex-basis .25s;
        z-index: 2;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          opacity: 0;
          transform: scale(.08);
          background-color: #fff;
          -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
          -moz-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
          backgroun-size: 77%;
          mask-repeat: no-repeat;
          mask-position: center;
          z-index: 3;
        }

        &[data-just-finished] {
          width: 1.25em;
          height: 1.25em;
          flex: 0 0 1.25em;

          &::before {
            transition: transform .25s .2s, opacity .25s .2s;
            transform: scale(1);
            opacity: 1;
          }
        }

        &[data-is-finished] {
          transition: height .25s, width .25s, left .25s, top, .25s, border-radius .25s;
          width: .3em;
          flex: 0 0 .3em;
          height: 100%;
          right: 0;
          border-radius: 0 .23em .23em 0;


          &::before {
            transition: opacity .1s;
            opacity: 0;
          }
        }

        .formulate-file-progress-inner {
          background-color: $navy;
          width: 1%;
          position: absolute;
          left: 0;
          bottom: 0;
          top: 0;
          z-index: 2;
        }
      }

      .formulate-file-name {
        padding-left: 1.5em;
        padding-right: 2em;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: auto;
      }

      .formulate-file-remove {
        width: 1.25em;
        height: 1.25em;
        border-radius: 1em;
        background-color: $navy;
        -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
        -moz-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
        image-size: .6em;
        image-repeat: no-repeat;
        image-position: center;
        cursor: pointer;
        position: absolute;
        right: .75em;
        z-index: 1;
        transition: transform .25s;
      }

      li {
        display: block;

        &[data-has-error] {
          .formulate-file-progress {
            background-color: $red;
          }
        }

        &[data-has-preview] {
          .formulate-file-name {
            &:before {
              display: none;
            }
          }
        }

        & + li {
          margin-top: .5em;
        }
      }

      .formulate-file {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @mixin progress {
          appearance: none;
          height: .5em;
          border-radius: .5em;
          overflow: hidden;
        }

        ::-webkit-progress-bar {
          @include progress;
        }
      }

      .formulate-file-image-preview {
        width: 3em;
        height: 3em;
        flex: 0 0 3em;
        position: relative;
        z-index: 2;
        left: -1px;
        box-shadow: 0 0 0 1px $gray;
        transition: transform .25s, box-shadow .25s, background-color .25s;

        @media (pointer: fine) {
          &:hover {
            transition-delay: .2s;
            transform: scale(3);
            background-color: #fff;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1);
          }
        }

        img {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          object-fit: contain;
          transition: all .25s;
        }
      }
    }
  }
}
