.modern-navigation {
  .first-level-menu {
    height: $navigation-menu-height;
    background-color: $modern-navigation-background;
    display: flex;
    align-items: center;
    gap: $modern-gap-lg;

    .menu-item {
      position: relative;
      display: flex;
      align-items: center;
      color: $modern-navigation-item-color;
      height: $first-level-menu-item-height;

      &__title {
        margin-top: $modern-gap-xs;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__icon {
        margin-right: $modern-gap-xxs + $modern-gap-xs;
        font-size: $modern-font-md;
        line-height: $modern-line-height-condensed;
      }

      &__title {
        font-size: $modern-font-md;
        line-height: $modern-line-height;
        margin-top: unset;
      }

      &.active {
        color: $modern-navigation-item-active-color;

        .active-state-marker {
          display: block;
        }
      }

      .active-state-marker {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $modern-navigation-item-active-color;
        border-radius: $modern-radius-xs $modern-radius-xs 0 0;
        height: $mobile-menu-active-marker-height;
      }

      &:hover, &.hover {
        color: $modern-navigation-item-hover-color;
      }
    }

    &.mobile-variant {
      height: $navigation-mobile-menu-height;
      background-color: $modern-navigation-background;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: $modern-gap-xs $modern-gap-sm;
      gap: $modern-gap;

      .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $modern-navigation-item-color;
        width: unset;
        height: unset;
        padding: $modern-gap-xs;

        &__title {
          margin-top: unset;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: $mobile-menu-font-size;
          line-height: $modern-line-height-small;
          white-space: nowrap;
        }


        &__icon {
          margin-right: 0;
          font-size: $modern-font-icon;
          line-height: $modern-font-icon;
        }

        .active-state-marker {
          display: none;
        }

        &.active {
          color: $modern-navigation-item-active-color;

          .menu-item__icon {
            margin-bottom: $modern-gap-xs;
          }

          .menu-item__title {
            display: none;
          }

          .active-state-marker {
            display: block;
            margin: 0 auto;
            width: $modern-gap-condensed;
            height: $modern-gap-xs;
            border-radius: $modern-radius;
          }
        }

        [class^="shp_icons-"] {
          font-size: $icon-font;
        }
      }

      > *, .menu-item {
        width: 100%;
      }

      .modern-dropdown-list.mobile-navigation-dropdown .dropdown-menu {
        position: absolute;
        right: $modern-gap;
        bottom: calc(100% + #{$modern-gap});
      }
    }
  }

  .second-level-menu {
    column-count: 3;
    column-gap: $modern-gap-2xl;

    > * {
      break-inside: avoid;
    }

    > .second-level-menu-item:not(:last-child) {
      margin-bottom: $modern-gap-md;
    }

    .second-level-menu-item {
    display: flex;
    flex-direction: column;

    .menu-item {
      font-size: $modern-font-h4;
      line-height: $modern-line-height;
      font-weight: $font-demi;
      color: $modern-navigation-item-color;

      &.active {
        color: $modern-primary;
      }

      &:hover {
        color: $modern-primary-hover;
      }
    }

    &__children {
      display: flex;
      flex-direction: column;

      .menu-item {
        margin-top: $modern-gap-sm;
        font-size: $modern-font-md;
        line-height: $modern-line-height;
        font-weight: $font-regular;
        color: $modern-navigation-item-color;
      }

      &.active {
        color: $modern-primary;
      }

      &:hover {
        color: $modern-primary-hover;
      }
    }
  }
  }

  .navigation-second-level-menu {
    position: absolute;
    left: 0;
    right: 0;
    background-color: $modern-navigation-background;
    padding: $modern-gap-md;
    border-top: 1px solid $modern-navigation-border-color;

    &__content {
      width: $navigation-second-level-content-width;
    }

    &__close {
      position: absolute;
      cursor: pointer;
      right: $modern-gap-md;
      top: $modern-gap-md;
    }
  }
}
