@use "sass:math";

$build-path: '/static/styles/';

.body-edueng {
  $red: #FE0000;

  .navbar-link.btn-logout {
    padding: $modern-gap-sm;
  }

  h1 {
      font-weight: 500;
      font-style: normal;
  }
  .navbar-static-top {
    min-height: 60px;
  }

  .has_pattern {
    background-image: url('../img/edueng/Rectangle 21.png');
    background-position: left top;
    background-size: cover;
    padding-top: 3 * $line-height;
    padding-bottom: 3 * $line-height;
  }

  .slogan {
    color: $text-color-white;
  }

  .h0 {
    font-size: 48px;
    font-weight: 500;
    font-style: normal;
  }

  .page-container {
    padding-top: 3 * $line-height;
    padding-bottom: 3 * $line-height;
  }

  .root_achievement {
    border-radius: 8px;
    border-width: 0;
    .panel-body {
      //padding: 2 * $line-height $line-height $line-height $line-height;
      padding: 1.5 * $line-height 1.5 * $line-height 0 1.5 * $line-height;
      min-height: $line-height * 10;
    }
    img {
      width: 2 * $line-height;
      height: 2 * $line-height;
    }
  }

  .root_subject {
    background: none;
    border-radius: 8px;
    color: $text-color-white;
    border-width: 0;
    .panel-body {
      //padding: 2 * $line-height $line-height $line-height $line-height;
      padding: 1.5 * $line-height;
      padding-bottom: 2 * $line-height;
      min-height: $line-height * 10;
    }
    img {
      width: 2 * $line-height;
      height: 2 * $line-height;
    }
    &.root_mark {
      background-image: url('../img/edueng/mark2.png');
      background-position: left top;
      background-size: cover;
      //background-color: #3895D3;
    }
    &.root_python {
      background-image: url('../img/edueng/python.png');
      background-position: center top;
      background-size: cover;
    }
    &.root_cpp {
      background-image: url('../img/edueng/с++.png');
      background-position: center top;
      background-size: cover;
    }

  }

  .popup_mark {
    background-image: url('../img/edueng/mark_light.png');
    background-position: center top;
    background-size: cover;

    min-height: 450px;
  }

  .popup_python {
      background-image: url('../img/edueng/python_light.jpg');
      background-position: center top;
      background-size: cover;
      min-height: 450px;
  }

  .popup_cpp {
      background-image: url('../img/edueng/c++_light.jpg');
      background-position: center top;
      background-size: cover;
      min-height: 450px;
  }

  .founder_logo {
      display: inline-block;
      width: 180px;
  }

  .founder_logo.founder_sit {
    width: 200px;
  }

  .founder_delimiter {
    height:  2 * $line-height;
    width: 1px;
    background-color: $light-gray;
    display: inline-block;
    vertical-align: middle;
  }
  .dash {
    width: 2 * $line-height;
    border: 2px solid $red;
  }

  .main-page {
    .btn {
        min-width: 200px;
    }
  }



  .btn-bottom {
    position: absolute;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 32px;
  }

  .root_feedback {
    .avatar {
      width: 250px;
    }

    .root-feedback_img {
      max-width: 250px + 2 * $line-height;
    }
  }

  .social-auth-vk, .social-auth-github, .github_account, .vk_account {
    display: none;
  }

  .social-auth-root {
    text-align: center;
  }

  #login_form {
    text-align: center;
  }

  .ibox-content {
    border-radius: 8px;
  }

  .text-red {
    color: $red;
  }

  .modal-content {
    border-radius: 8px;
  }

  .modal-body {
    padding: $line-height;
  }

  .navbar-static-top .navbar-right {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding-top: 0;
  }

  .shp-copyright {
    display: none;
  }
}
