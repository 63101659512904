
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    background-color: #F7F7F9;
    background-image: none;
    filter: none;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    color: #555555;
    font-size: inherit;
    border: 1px solid #DDDDDD;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    overflow: visible;
    border-radius: 4px;
}

.ui-accordion-content-active {
    border: 1px solid #DDDDDD;
}

.ui-widget-header .ui-accordion-header{
    border: 1px solid #DDDDDD;
}

.ui-state-hover {
    background-color: #EEEEEE !important;
}

.ui-state-active{
    background-color: #FFFFFF !important;
}

.ui-draggable{
    background: rgba(238, 238, 238, 0.5);
    border-radius: 4px;
    padding: 2px 0 0 2px;
}


.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  _width: 160px;
  padding: 4px 0;
  margin: 2px 0 0 0;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  overflow-y: scroll;
  max-height: 200px;
}

.ui-menu-item > .ui-menu-item-wrapper {
    display: block;
    padding: 1px 10px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #555555;
    white-space: nowrap;
}

.ui-menu-item:hover .ui-menu-item-wrapper {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
    border-width: 0;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
}

.ui-widget-content.ui-autocomplete .ui-state-active {
   background-color: #0088cc !important;
}

.accordion-item{
    display: block;
    padding-left: 2px;
    padding-right: 2px;
    cursor: auto;
}

.accordion-item.ui-draggable{
    cursor: pointer;
}

.accordion-item.ui-draggable.ui-draggable-dragging{
    cursor: move;
}
