.list-group.separated {
  .list-group-item {
    border-radius: unset;
    border-left: unset;
    border-right: unset;
  }
}

@media (min-width: $screen-tablet-max) {
  .columns-2-md {
    columns: 2 auto;
  }
}
