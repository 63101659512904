@use "sass:math";
@import "bootstrap-sass-master/bootstrap/variables";

// Basic Colors
$navy: #2980b9 !default;       // Primary color
$dark-gray: #c2c2c2 !default;  // Default color
$blue: #27ae60 !default;       // Success color
$lazur: #3498db !default;      // Info color
$yellow: #e67e22 !default;     // Warning color
$red: #e74c3c !default;        // Danger color
$amazing: #8e44ad !default;        // Danger color
$inverse: #333333 !default;
$red: #FE0000  !default;
$light-red: #D9534F !default;

// Various colors
//$text-color: #676a6c;   // Body text
$text-color: #333;   // Body text
$text-color-white: #ffffff;
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$disabled-input-color: lighten($light-gray, 5%);
$banner-bg-color: #f9f8f5;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;


// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 230px;

$build_path : '' !default;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: url('../img/patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profile-pattern: url("../img/patterns/header-profile.png");
$nav-text-color: #a7b1c2;

$navy-text: $navy;
$green-text: #27AE60;
$blue-text: darken($blue, 10%);
$lazur-text: darken($lazur, 5%);
$yellow-text: darken($yellow, 5%) !default;
$red-text: darken($red, 10%);
$muted-color: #888888;
$amazing-text: $amazing;
$night-blue: #2B3B54;

$male-icon-color: #2D8BCF;
$female-icon-color: #D1397E;

$navy-badge-text: $text-color-white;
$blue-badge-text: $text-color-white;
$lazur-badge-text: $text-color-white;
$yellow-badge-text: $text-color-white;
$red-badge-text: $text-color-white;
$amazing-badge-text: $text-color-white;

$fonts: "Roboto",Arial,sans-serif;
$fonts-roboto-monospace: "Roboto Mono",Arial,sans-serif;
$fonts-monospace: Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', Courier, monospace;

$bg-color : #f4f6fa;
$bg-highlight : #F9F8F5;

// Fonts
$font-xs: 10px;
$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 18px;
$font-xxl: 20px;
$font-3xl: 32px;
$font-extra: 48px;
$font-4xl: 64px;

$icon-font: 24px;

//Bootstrap 4

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

$line-height:         32px;
$line-padding:         math.div($line-height, 2);
$grid-size:         4px;
$black-logo: '../img/brand/informatics-horizontal-grey.png';
$black-logo-mobile: '../img/brand/informatics-horizontal-grey__mobile.png';
$edu-eng-logo: '../img/brand/sit-horizontal.png';
$menu-bg: #3e495f;
$skin-1-a-active: #fff;
$skin-1-color: #2980b9;
//$skin-1-nav-bg: #fff;

$placeholder-color: #888888;
$label-gray-color: #999999;

$brand-amazing: #8e44ad;
$btn-amazing-color:               #fff !default;
$btn-amazing-bg:                  $brand-amazing !default;
$btn-amazing-border:              darken($brand-amazing, 5%) !default;

$brand-inverse: #2c3e50;
$btn-inverse-color:               #fff !default;
$btn-inverse-bg:                  $brand-inverse !default;
$btn-inverse-border:              darken($brand-inverse, 5%) !default;

$red: #FE0000;
$text-color-white: #FFF;

$gap-xxs: math.div($line-padding, 8);
$gap-xs: math.div($line-padding, 4);
$gap-sm: math.div($line-padding, 2);
$gap: $line-padding;
$gap-md: math.div($line-padding * 3, 2);
$gap-lg: $line-padding * 2;
$gap-xl: $line-padding * 4;

$input-border-color: #e5e6e7;
$input-focus-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);

$collapsed-overlay-gradient: linear-gradient(rgba(255, 255, 255, 0) 10px, #fff 70px, #fff);

// progress-donut
$progress-donut-blue: #0062FFBF;
$progress-donut-amazing: #A15BD7;
$progress-donut-green: #82C43C;
$progress-donut-red: #FF3333;
$progress-donut-orange: #FFC133;
$progress-donut-blue-light: #e2edff;
$progress-donut-amazing-light: #f1e6f9;
$progress-donut-green-light: #ecf6e1;
$progress-donut-red-light: #ffe5e5;
$progress-donut-orange-light: #fff5e0;
