@use "sass:meta";

a.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.badge-important, .panel-default > .panel-heading .badge.badge-important {
  background-color: $red;
}

.badge-important[href] {
  background-color: $red-text;
}

.badge-warning, .panel-default > .panel-heading .badge.badge-warning {
  background-color: $yellow;
}

.badge-warning[href] {
  background-color: $yellow-text;
}

.badge-success, .panel-default > .panel-heading .badge.badge-success {
  background-color: $blue;
}

.badge-success[href] {
  background-color: $blue-text;
}

.badge-info, .panel-default > .panel-heading .badge.badge-info {
  background-color: $lazur;
}

.badge-primary, .panel-default > .panel-heading .badge.badge-primary {
  background-color: $navy;
}

.badge-info[href] {
  background-color: $lazur-text;
}

.badge-inverse {
  background-color: $inverse;
}

.badge-inverse[href] {
  background-color: $inverse;
}

.badge-click:hover {
    opacity: 1;
}

.badge-click {
    cursor: pointer;
    opacity: 0.9;
}

.badge.badge-right{
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 6px;
    padding-right: 7px;
}

.badge.badge-left{
    margin-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 4px;
    padding-left: 9px;
}

.filter.badge-filter {
    margin-top: 4px;
}

.filter.badge-filter .badge {
    margin-top: 0;
    margin-bottom: 0;
}

.map_legend .badge {
    padding: 3px 5px;
}

.badge.context-badge {
    display: inline-block;
    width: 29px;
    font-size: 10px;
    padding: 0;
    margin: 0 3px 0;
    text-align: center;
}


.badge-amazing, .panel-default > .panel-heading .badge.badge-amazing {
  color: #ffffff;
  background-color: $amazing;
  &[href] {
    background-color: $amazing;
  }
}

.label-amazing, .badge-amazing {
  background-color: $amazing;
  color: #FFFFFF;
}

.badge-inverse {
  background-color: #333333;
}

.badge-inverse[href] {
  background-color: #1a1a1a;
}


.badge.badge-abbr {
  max-width: 40px;
  width: 40px;
}

@mixin badge-light-variant ($color, $background-color) {
  color: $color;
  background-color: $background-color;
  &[href] {
    background-color: $background-color;
  }
  font-weight: 400;
}

.badge-light-default {
  @include badge-light-variant($text-color, transparentize($dark-gray, 0.5))
}

.badge-light-important {
  @include badge-light-variant($red, transparentize($red, 0.85))
}

.badge-light-danger {
  @include badge-light-variant($red, transparentize($red, 0.85))
}

.badge-light-warning {
  @include badge-light-variant($yellow, transparentize($yellow, 0.85))
}

.badge-light-success {
  @include badge-light-variant($blue, transparentize($blue, 0.85))
}

.badge-light-info {
  @include badge-light-variant($lazur, transparentize($lazur, 0.85))
}

.badge-light-primary {
  @include badge-light-variant($navy, transparentize($navy, 0.85))
}

.badge-amazing-light {
  @include badge-light-variant($amazing, transparentize($amazing, 0.85))
}

svg.shp-bookmark path {
  fill: $modern-background;
}

@mixin bookmark_colors($args...) {
  @each $name, $color in meta.keywords($args) {
    svg.shp-bookmark.shp-bookmark-#{$name} path {
      fill: $color;
    }
  }
}

@include bookmark_colors(
  $danger: $modern-error,
  $warning: $modern-warning,
  $primary: $modern-primary,
  $success: $modern-success,
  $amazing: $modern-amazing,
  $layer: $modern-layer,
);
