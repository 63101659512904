.pupil_distribution {
  width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  .pupil_distribution {
    width: 30%;
    flex: 0 0 30%;
  }
}
