.extra-tabs .nav.nav-tabs {
    margin-bottom: 0;
}

.extra-tabs .nav.nav-tabs.well-tabs li.active a {
    border-bottom-width: 0;
}

.extra-row.extra .extra{
    max-width: inherit;
}

.extra.wide-extra {
    max-width: none;
    margin-left: 0;
}
table .extra-content [class*="span"] {
    display: block;
}

.limited-height-extra {
    max-width: 450px;
    max-height: 550px;
    overflow-y: auto;
}

.preview .extra {
    margin-bottom: 4px;
}
