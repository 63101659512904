.skin-1 .wrapper-content .nav-stacked.nav  {
    > li.step_item.current_step > a, .set-navigation.radio-active {
        color: $blue-text;
    }
}

.step_item.current_step > a.muted {
    color: #999999;
}


.video-play-icon {
    left: 50%;
    transform: translate(-50%,-50%);
    top: 50%;
    min-width: 70px;
}

.step_element_icon {
    color: $navy;
    font-size: $modern-font-icon;
    cursor: pointer;
}
