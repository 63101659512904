
.popover.popover-fixed-height .popover-content {
    max-height: 300px;
    overflow-y: auto;
}

.popover.popover-fixed-height .popover-content.overflow-visible {
    overflow-y: visible;
}

.popover.popover-fixed-width .popover-content {
    max-width: 300px;
}

.popover-static {
    display: block;
    float: left;
    margin: 0 0 20px 0;
    position: inherit;
}


.popover.popover-flat {
    box-shadow: none;
    /*box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);*/
}

.popover {
    padding: 0;
}

.popover-title.popover-info-title {
    background-color: #D9EDF7;
}

.popover-title.popover-success-title {
    background-color: #DFF0D8;
}

.popover-title.popover-warning-title {
    background-color: #fcf8e3;
}

.popover-title.popover-error-title {
    background-color: #f2dede;
}

.popover.error-popover .popover-title {
    color: #B94A48;
    font-weight: bold;
}

.popover-form .form-stacked-buttons .btn-group {
    margin-left: -5px;
}

.popover-form .form-group .col-md-12 {
    padding-left: 0;
    padding-right: 0;
}


.popover.form_title_div .popover-title {
    padding-top: 10px;
    padding-bottom: 10px;
}

.popover.form_title_div {
    margin-bottom: 15px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.popover.form-actions {
    margin-bottom: 15px;
    box-shadow: 0 0 0 0;
}

.popover .value_input {
    width: 135px;
}

.popover .calendar_picker_input {
    /*width: 108px;*/
}

.popover .popover-form .form-horizontal {
    margin-bottom: 0;
}

.popover .popover-form .form-horizontal :last-child  .control-group {
    margin-bottom: 4px;
}

.popover .popover-form .form-horizontal .control-group {
    line-height: 18px;
}

.popover .popover-form .form-horizontal .control-group .control-label{
    width: 80px;
    text-align: left;
}

.popover .popover-form .form-horizontal {
    margin-right: 1px;
}

.popover .popover-form .form-horizontal .control-group .controls{
    margin-left: 100px;
}

.popover .popover-form .form-horizontal .controls-without-label .control-group .controls {
    margin-left: 0;
}

.popover .popover-form .form-horizontal .help-inline {
    display: block;
    margin-left: -90px;
    max-width: 300px;
}

.popover-filter .input-append {
    margin-bottom: 0;
}

.inline-form.popover .popover-form .form-horizontal .control-group .control-label {
    margin-bottom: 0;
    padding-top: 0;
}

.inline-form.popover .popover-form .form-horizontal .control-group .controls {
    margin-left: 0;
}

.inline-form.popover .popover-content.popover-form {
    padding-bottom: 4px;
}

.inline-form.popover .alert{
    margin-bottom: 6px;
}

.inline-form.popover .popover-form .form-horizontal .help-inline {
    margin-left: 0;
}

.tooltip-popover {
    width: auto;
    z-index: 1100;
}

.tooltip-popover.popover-wide {
    width: 300px;
}

.tooltip-popover.popover-wide > .tooltip-inner {
    max-width: 300px;
}

.tooltip-popover.popover-fixed-width {
    width: 200px;
}

.tooltip-popover.top {
    margin-top: -10px;
}

.tooltip-popover.left {
    margin-left: -10px;
}

.popover-content.tooltip-inner {
    color: #333333;
    background-color: #ffffff;
    padding: 5px 9px;
}

.operation_select, .value_select {
    width: auto;
}

.popover-form .input-group {
    margin-right:2px;
}

.modern-popover.popover {
  font-family: $modern-font-family;
  font-size: $modern-font-md;
  line-height: $modern-line-height;
  border-radius: $modern-radius-xs;

  &.no-border {
    border: none;
  }

  .popover-content {
    padding: $modern-gap-sm;
  }
}

.modern-page {
  .hovered-popover {
    @media (max-width: $screen-mobile-max) {
      display: none;
    }
  }
}
