

.label {
  background-color: $light-gray;
  color: $label-badge-color;
  font-family: $fonts;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
}

.nav .label, .ibox .label {
  font-size: 10px;
}

.badge {
  background-color: $light-gray;
  color: $label-badge-color;
  font-family: $fonts;
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none;
  line-height: 13px;
}

.badge-md {
  font-size: 14px;
}

.badge-group {
  > .badge {
    margin-bottom: 2 * $grid-size;

    &:not(:last-child) {
      margin-right: 2 * $grid-size;
    }
  }
}

.badge-rounded {
  border-radius: 4px;
}

.label-primary, .badge-primary {
  background-color: $navy;
  color: $navy-badge-text;
}

.label-success, .badge-success {
  background-color: $blue;
  color: $blue-badge-text;
}

.label-warning, .badge-warning {
  background-color: $yellow;
  color: $yellow-badge-text;
}

.label-warning-light, .badge-warning-light {
  background-color: $yellow;
  color: $yellow-badge-text;
}

.label-danger, .badge-danger {
  background-color: $red;
  color: $red-badge-text;
}

.label-info, .badge-info {
  background-color: $lazur;
  color: $lazur-badge-text;
}

.label-inverse, .badge-inverse {
  background-color: #262626;
  color: #FFFFFF;
}

.label-transparent, .badge-transparent {
  background-color: rgba(5, 25, 54, 0.4);
  color: #FFFFFF;
}

.label-white, .badge-white {
  background-color: #FFFFFF;
  color: #5E5E5E;
}

.label-disable, .badge-disable {
  background-color: #2A2E36;
  color: #8B91A0;
}


@mixin label-light-variant ($color, $background-color) {
  color: $color;
  background-color: $background-color;
  &[href] {
    background-color: $background-color;
  }
  font-weight: 400;
}

.label-light-default {
  @include label-light-variant($text-color, scale-color($dark-gray, $lightness:50%))
}

.label-light-important {
  @include label-light-variant($red, scale-color($red, $lightness:85%))
}

.label-light-danger {
  @include label-light-variant($red, scale-color($red, $lightness:85%))
}


.label-light-warning {
  @include label-light-variant($yellow, scale-color($yellow, $lightness:85%))
}

.label-light-success {
  @include label-light-variant($blue, scale-color($blue, $lightness:85%))
}

.label-light-info {
  @include label-light-variant($lazur, scale-color($lazur, $lightness:85%))
}

.label-light-primary {
  @include label-light-variant($navy, scale-color($navy, $lightness:85%))
}

.label-light-amazing {
  @include label-light-variant($amazing, scale-color($amazing, $lightness:85%))
}
