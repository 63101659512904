@use "sass:map";

$btn-modern-border-width: 1px;
$btn-modern-paddings-lg: $modern-gap-condensed $modern-gap-md;
$btn-modern-bordered-paddings-lg: ($modern-gap - $btn-modern-border-width) ($modern-gap-md - $btn-modern-border-width);
$btn-modern-paddings-md: $modern-gap-sm $modern-gap-condensed;
$btn-modern-bordered-paddings-md: ($modern-gap-sm - $btn-modern-border-width) ($modern-gap-condensed - $btn-modern-border-width);
$btn-modern-paddings-sm: $modern-gap-xs $modern-gap-condensed;
$btn-modern-bordered-paddings-sm: ($modern-gap-xs - $btn-modern-border-width) ($modern-gap-condensed - $btn-modern-border-width);
$btn-modern-paddings-xs: $btn-modern-border-width $modern-gap-sm;
$btn-modern-bordered-paddings-xs: 0 ($modern-gap-sm - $btn-modern-border-width);

$btn-modern-grey: $modern-subtext;
$btn-modern-grey-soft: $modern-line;
$btn-modern-text: $modern-text-white;
$btn-modern-black: $modern-text-black;
$btn-modern-disable: $modern-disable;
$btn-modern-focus-outline: $modern-primary-outline;

@mixin icon-color($color) {
  i {
    color: $color;
  }
}

@mixin btn-modern-base($text-color) {
  font-weight: $font-demi;
  border: none;
  color: $text-color;
  padding: $btn-modern-paddings-md;
  text-align: center;
  border-radius: $modern-radius-lg;
  font-size: $font-lg;
  line-height: $modern-line-height;
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  i {
    font-size: $modern-font-icon;
    font-weight: $font-regular;
    color: $text-color;
    vertical-align: middle;
  }

  &.btn-modern-icon-3xs i[class^=shp_icons-] {
    font-size: $modern-font-xxxs;
  }
}

@mixin btn-modern-variant($background, $text-color, $hover, $disabled, $pressed) {
  @include btn-modern-base($text-color);
  background-color: $background;

  &:hover, &.hover {
    background-color: $hover;
    color: $text-color;
  }

  &:focus-visible, &.focus {
    outline: 1px solid $btn-modern-focus-outline;
    outline-offset: 1px;
  }

  &:active, &.active {
    background-color: $pressed;
  }

  &:disabled, &.disabled {
    background-color: $disabled;
    cursor: not-allowed;

    &:focus, &.focus {
      outline: none;
    }
  }
}

@mixin btn-modern-bordered($base-colors, $hover-colors, $focus-colors, $active-colors, $disabled-colors) {
  @include btn-modern-base(map.get($base-colors, 'color'));
  border: $btn-modern-border-width solid map.get($base-colors, 'border-color');
  background-color: map.get($base-colors, 'background-color');
  padding: $btn-modern-bordered-paddings-md;

  &:hover, &.hover {
    border-color: map.get($hover-colors, 'border-color');
    color: map.get($hover-colors, 'color');
    background-color: map.get($hover-colors, 'background-color');
    @include icon-color(map.get($hover-colors, 'color'));
  }

  &:focus-visible, &.focus {
    border-color: map.get($focus-colors, 'border-color');
    color: map.get($focus-colors, 'color');
    background-color: map.get($focus-colors, 'background-color');
    outline: 1px solid $btn-modern-focus-outline;
    outline-offset: 1px;
    @include icon-color(map.get($focus-colors, 'color'));
  }

  &:active, &.active {
    border-color: map.get($active-colors, 'border-color');
    color: map.get($active-colors, 'color');
    background-color: map.get($active-colors, 'background-color');
    @include icon-color(map.get($active-colors, 'color'));
  }

  &:disabled, &.disabled {
    border-color: map.get($disabled-colors, 'border-color');
    color: map.get($disabled-colors, 'color');
    background-color: map.get($disabled-colors, 'background-color');
    cursor: not-allowed;
    @include icon-color(map.get($disabled-colors, 'color'));

    &:focus, &.focus {
      outline: none;
    }
  }

  &.btn-modern-lg, &.btn-modern-lg {
    padding: $btn-modern-bordered-paddings-lg;
  }

  &.btn-modern-sm, &.btn-modern-sm {
    padding: $btn-modern-bordered-paddings-sm;
  }

  &.btn-modern-xs, &.btn-modern-xs {
    padding: $btn-modern-bordered-paddings-xs;
  }
}

@mixin btn-modern-borderless($text-color, $hover, $disabled, $pressed) {
  @include btn-modern-base($text-color);
  padding: 0;
  background-color: inherit;
  border-radius: $modern-radius;

  &.btn-modern-thin-link {
    font-weight: $font-regular;
    font-size: $modern-font-sm;
    line-height: $modern-line-height-condensed;
    letter-spacing: 0.5px;
    justify-content: start;
  }

  &:hover:not(.btn-hover-disable), &.hover:not(.btn-hover-disable) {
    color: $hover;
    @include icon-color($hover);
  }

  &:focus-visible, &.focus {
    outline: 1px solid $btn-modern-focus-outline;
    outline-offset: 2px;
  }

  &:active:not(.btn-active-disable), &.active:not(.btn-active-disable) {
    color: $pressed;
    outline: none;
    @include icon-color($pressed);
  }

  &:disabled, &.disabled {
    color: $disabled;
    cursor: not-allowed;
    @include icon-color($disabled);

    &:focus, &.focus {
      outline: none;
    }
  }
}

button.btn-modern-primary, .btn-modern.btn-modern-primary, .modern-page .btn-primary, a.btn-modern-primary {
  @include btn-modern-variant(
    $modern-primary,
    $btn-modern-text,
    $modern-primary-hover,
    $modern-primary-disable,
    $modern-primary-pressed,
  );
}

button.btn-modern-success, .btn-modern.btn-modern-success, .modern-page .btn-success, a.btn-modern-success {
  @include btn-modern-variant(
    $modern-success,
    $btn-modern-text,
    $modern-success-hover,
    $modern-success-disable,
    $modern-success-pressed,
  );
}

button.btn-modern-warning, .btn-modern.btn-modern-warning, .modern-page .btn-warning, a.btn-modern-warning {
  @include btn-modern-variant(
    $modern-warning,
    $btn-modern-text,
    $modern-warning-hover,
    $modern-warning-disable,
    $modern-warning-pressed,
  );
}

button.btn-modern-danger, .btn-modern.btn-modern-danger, .modern-page .btn-danger, a.btn-modern-danger {
  @include btn-modern-variant(
    $modern-error,
    $btn-modern-text,
    $modern-error-hover,
    $modern-error-disable,
    $modern-error-pressed,
  );
}

button.btn-modern-amazing, .btn-modern.btn-modern-amazing, .modern-page .btn-amazing, a.btn-modern-amazing {
  @include btn-modern-variant(
    $modern-amazing,
    $btn-modern-text,
    $modern-amazing-hover,
    $modern-amazing-disable,
    $modern-amazing-pressed,
  );
}

button.btn-modern-white, .btn-modern.btn-modern-white {
  @include btn-modern-base($modern-primary);
  background-color: $modern-layer;

  &:hover, &.hover {
    opacity: 0.75;
  }

  &:focus-visible, &.focus {
    outline: 1px solid $btn-modern-focus-outline;
    outline-offset: 1px;
    opacity: 0.75;
  }

  &:active, &.active {
    outline: none;
    background-color: rgba(0, 0, 0, 0.5);

    > i {
      opacity: 0.5;
    }
  }

  &:disabled, &.disabled {
    opacity: 0.4;
  }
}

button.btn-modern-default, .btn-modern.btn-modern-default, .modern-page .btn-default, a.btn-modern-default {
  @include btn-modern-bordered(
    $base-colors: ("color": $btn-modern-black, "background-color": $modern-layer, "border-color": $btn-modern-grey-soft),
    $hover-colors: ("color": $btn-modern-grey),
    $focus-colors: ("color": $btn-modern-black),
    $active-colors: ("background-color": $btn-modern-grey-soft),
    $disabled-colors: ("color": $btn-modern-grey, "background-color": $btn-modern-disable),
  )
}

button.btn-modern-info, .btn-modern.btn-modern-info {
  @include btn-modern-bordered(
    $base-colors: ("color": $modern-primary, "background-color": transparent, "border-color": $modern-primary),
    $hover-colors: ("color": $modern-primary-hover, "border-color": $modern-primary-hover),
    $focus-colors: ("color": $modern-primary, "border-color": $modern-primary),
    $active-colors: ("color": $modern-primary-pressed, "border-color": $modern-primary-pressed),
    $disabled-colors: ("color": $modern-primary-disable, "border-color": $modern-primary-disable),
  );
}

button.btn-modern-link, .btn-modern.btn-modern-link, a.btn-modern-link {
  @include btn-modern-borderless(
    $modern-primary,
    $modern-primary-hover,
    $modern-primary-disable,
    $modern-primary-pressed,
  );
}

button.btn-modern-link-danger, .btn-modern.btn-modern-link-danger {
  @include btn-modern-borderless(
    $modern-error,
    $modern-error-hover,
    $modern-error-disable,
    $modern-error-pressed,
  );
}

button.btn-modern-link-black, .btn-modern.btn-modern-link-black {
  @include btn-modern-borderless(
    $btn-modern-black,
    $btn-modern-grey,
    $btn-modern-disable,
    $btn-modern-black,
  );
}

button.btn-modern-link-grey, .btn-modern.btn-modern-link-grey {
  @include btn-modern-borderless(
    $btn-modern-grey,
    $btn-modern-black,
    $btn-modern-disable,
    $btn-modern-black,
  );
}

button.btn-modern-link-white, .btn-modern.btn-modern-link-white {
  @include btn-modern-base($btn-modern-text);
  background-color: inherit;

  &:hover,
  &.hover {
    color: $btn-modern-text;
    opacity: 0.75;
  }

  &:focus-visible, &.focus {
    outline: 2px solid $btn-modern-focus-outline;
    outline-offset: 1px;
    opacity: 0.75;
  }

  &:active,
  &.active {
    color: invert($btn-modern-text, 100%);
    outline: none;
    opacity: 0.5;

    > i {
      color: invert($btn-modern-text, 100%);
    }
  }

  &:disabled,
  &.disabled {
    color: $btn-modern-text;
    opacity: 0.4;
  }
}

button.btn-modern-lg, .btn-modern.btn-modern-lg {
  padding: $btn-modern-paddings-lg;
  font-weight: $font-bold;
  line-height: $modern-line-height-h4;
  font-size: $font-xxl;
   > i {
    font-size: $font-xxl;
  }
}

button.btn-modern-sm, .btn-modern.btn-modern-sm {
  padding: $btn-modern-paddings-sm;

  i, i[class^=shp_icons-] {
    font-size: $modern-font-h4;
  }
}

button.btn-modern-xs, .btn-modern.btn-modern-xs {
  padding: $btn-modern-paddings-xs;
  border-radius: $modern-radius;
}

button.btn-modern-no-background, .btn-modern.btn-modern-no-background {
  background-color: transparent;
}

.btn-modern-group {
  display: flex;
  > button {
    border-radius: 0;
    &:not(:last-child) {
      border-right: 0;
    }
    &:first-child {
      border-radius: $modern-radius-lg 0 0 $modern-radius-lg;
    }
    &:last-child {
      border-radius: 0 $modern-radius-lg $modern-radius-lg 0;
    }
  }
}

.btn-modern-dropdown {
  .modern-dropdown {
    border-radius: 0 $modern-radius-lg $modern-radius-lg 0;
  }
}

[class^="btn-modern-"], [class*=" btn-modern-"] {
  &.disabled {
    cursor: not-allowed;

    &:focus {
      outline: none;
    }
  }
}

.modern-page {
  .file-input-button {
    border-radius: $modern-radius-lg;
    padding: 0 $gap-xs;

    span {
      font-size: $modern-font-sm;
      font-weight: $font-demi;
      line-height: $modern-line-height;
      text-align: center;
    }
  }

  a.btn.disabled {
    pointer-events: unset;
  }

  .btn.disabled {
    opacity: 1;
  }
}
