
// Tabs
// -------------------------

// Give the tabs something to sit on
.skin-1 .nav.nav-underscored {
  $active-border-width: 2px;
  border-bottom-width: 0;
  margin-bottom: math.div($line-padding, 2);

  > li {
    font-size: $font-size-large;
    display: inline-flex;
    margin-bottom: math.div($line-padding, 2);
    margin-right: $line-padding;

    > a {
      border-width: 0;
      font-weight: normal;
      color: $link-color;
      padding: 0 0 (math.div($grid-gutter-width, 8) + $active-border-width) 0;
      line-height: $line-height-base;
    }

    &.active, &:hover, &:focus {
      > a {
        background: none;
        color: $text-color;
        border-bottom: $active-border-width solid $text-color;
        padding-bottom: math.div($grid-gutter-width, 8);
      }
    }

    &.disabled {
      cursor: not-allowed;

      > a {
        pointer-events: none;
      }
    }
  }
}

.skin-1 .nav.nav-underscored > li {
  &.active, &:focus, > a:focus {
    background: none;
  }
}

.skin-1 .nav.nav-underscored > li {
  &.active, &:focus, &:hover {
    > a {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}

.modern-page .nav.nav-underscored {
  margin-bottom: $modern-gap-md;

  > li {
    a {
      font-size: $modern-font-h4;
      font-weight: $font-bold;
      line-height: $modern-line-height;
      letter-spacing: 0;
      text-align: center;
      color: $modern-primary;
      border: none;
      padding: 0;
    }

    &:hover {
      a {
        color: $modern-primary-hover;
        border-bottom: unset;
      }
    }

    &.router-link-exact-active.router-link-active {
      a {
        color: $modern-text-black;
        border-bottom: 2px solid $modern-primary;
      }
    }

    &:focus-visible {
      border: none;
      color: $modern-primary-hover;
    }
  }
}
