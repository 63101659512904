.popover {
    z-index: 2100;
    max-width: none;
}

.popover .popover-content .badge, .popover .popover-content .badge-filter {
    float: none !important;
    line-height: 16px;
    padding: 1px 9px 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    display: inline-block;
}

.popover .popover-content .badge-filter {
    padding-left: 0;
    padding-right: 0;
}

.popover .close {
    line-height: 14px;
}

.popover .popover-footer {
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #ebebeb;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
}
