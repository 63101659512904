.fc-event.calendar-active {
  -o-transition:color .1s ease-out, background .2s ease-in;
  -ms-transition:color .1s ease-out, background .2s ease-in;
  -moz-transition:color .1s ease-out, background .2s ease-in;
  -webkit-transition:color .1s ease-out, background .2s ease-in;
  /* ...and now for the proper property */
  transition:color .1s ease-out, background .2s ease-in;
}

.fc-event.calendar-classes, .fc-event.calendar-other-classes, .fc-event.calendar-selected-classes:hover {
    background-color: #3a87ad;
    border: 1px solid #3a87ad;
}


.fc-event.calendar-selected-classes, .fc-event.calendar-classes:hover, .fc-event.calendar-other-selected-consultation {
    background-color: $red;
    border: 1px solid $red;
}

.fc-event.calendar-missed-other-consultation {
    background-color: $blue;
    border: 1px solid $blue;
}

@mixin fc-event-variant ($hover-color, $background-color, $border-color) {
  background-color: $background-color;
  border: 1px solid $background-color;
  border-right: 8px solid $border-color;
  &:hover {
    background-color: $hover-color;
    border: 1px solid $hover-color;
    border-right: 8px solid $border-color;
  }
}


.fc-event.calendar-replacement {
  @include fc-event-variant($amazing, #999999, $amazing)
}

.fc-event.calendar-replacement-suited-theme  {
  @include fc-event-variant($amazing, #999999, $amazing);
  &:hover {
    border-left: 2px solid $amazing;
    border-top: 2px solid $amazing;
    border-bottom: 2px solid $amazing;
  }
  border-left: 2px solid $amazing;
  border-top: 2px solid $amazing;
  border-bottom: 2px solid $amazing;
}

.fc-event.calendar-selected-replacement, .fc-event.calendar-other-selected-replacement {
  @include fc-event-variant(#999999, $amazing, $amazing)
}


.fc-event.calendar-selected-consultation {
  @include fc-event-variant(#999999, #468847, #468847)
}

.fc-event.calendar-consultation {
  @include fc-event-variant(#468847, #999999, #468847)
}

.fc-event.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
   -o-animation: progress-bar-stripes 2s linear infinite;
      animation: progress-bar-stripes 2s linear infinite;

}
