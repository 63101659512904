@use "sass:math";

.shp_checklist {

  .shp_checklist__list {
    height: auto;
    overflow: auto;
    border: none;
    box-shadow: unset;
    padding-top: 0;
    padding-bottom: 0;

    &.shp_checklist__horizontal {
      display:  flex;
      flex-wrap: wrap;
      min-width: 0;

      > * {
        min-width: 0;
        margin-right: $line-padding;
      }
    }

    &.shp_checklist__multi_sketchy {
      display: flex;
      flex-direction: column;
      gap: $modern-gap-sm;
      padding: 0;

      label.shp_checklist__row, label.shp_checklist__all_checker {
        display:  flex;
        align-items: center;
        gap: $modern-gap;
        padding: $modern-gap;
        margin: 0;
        border-radius: $modern-radius-lg;
        border: 1px solid $modern-grey-soft-background;
        background-color: $modern-layer;
        width: 100%;
        cursor: pointer;

        &.active {
          border: 1px solid $modern-primary;
          background-color: $modern-primary-background;
        }
      }
    }

    &.form-control {
      background: none;
    }
  }

  .shp_checklist__dropList {
    height: auto;
    transition: height 0.5s;
    border-radius: $border-radius-base;
  }

  .shp_checklist__main_row {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

   .shp_checklist__info {
     flex: 1 auto;
   }
  }

  .shp_checklist__row {
    margin-bottom: math.div($line-padding, 4);
    display: block;
    .shp-radio {
      margin-right: $grid-size;
    }
  }

  .shp_checklist__arrow {
    width: 16px;
    height: 16px;

    transform: rotate(0deg);
    transition: transform 0.5s;

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGdJREFUOI3tzjsOwjAURNGDUqSgTxU5K2AVrJtswjUsgHSR0qdxAZZFPrS+3ZvRzBsqf9MUtBtazJk+oMe0VTriiZCFX8nbpENMgfARjsn74vKj5IFruhfc8d6zIF9S/Hyk5HS4spMVeFcOjszaOwMAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
  }

  .shp_checklist__dropList-expanded {
    .shp_checklist__main_row {
      margin-bottom: math.div($line-padding, 2);
    }

    .shp_checklist__arrow {
      transform: rotate(-180deg);
    }

    .shp_checklist__drop {
      display: block;
      max-height: $line-height * 10;
      overflow-y: auto;
      margin-right: -13px;
      margin-bottom: -6px;
      padding-right: 12px;
      padding-bottom: 6px;
    }
  }
}
