@use "sass:meta";

.modern-form {
  display: block;

  .form-group {
    margin-bottom: $gap-lg;
  }

  .form-control {
    height: auto;
  }
}

.modern-form-group {
  margin-bottom: $modern-gap-md;
}

.modern-inline-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $gap-lg;

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    height: auto;
  }

  .formulate-input-wrapper {
    display: flex;
    align-items: center;

    .formulate-input-label {
      margin-bottom: 0;
      margin-right: $gap-sm;
      text-align: right;
    }
  }
}

@mixin horizontal-form($size) {
  .formulate-input-wrapper {
    display: flex;

    .formulate-input-label {
      display: flex;
      align-items: center;
      justify-content: right;
      min-width: $size;
      max-width: $size;
      padding: 0;
      margin: 0 $gap-sm 0 0;
      text-align: right;
    }

    .formulate-input-element, .modern-select {
      flex-grow: 1;
    }

    .shp_checkbox__wrapper-inline .formulate-input-label {
      justify-content: left;
    }
  }

  .formulate-input-help {
    margin-left: $gap-sm + $size;
  }

  .form-group {
    margin-bottom: $gap-lg;
  }
}

;

@mixin horizontal-form-sizes($args...) {
  @each $name, $min-size in meta.keywords($args) {
    .modern-horizontal-#{$name} {
      @include horizontal-form($min-size)
    }

    .modern-page .horizontal-#{$name} {
      @include horizontal-form($min-size)
    }
  }
}

@include horizontal-form-sizes(
        $form-xxs: 20px,
        $form-xs: 80px,
        $form-sm: 120px,
        $form: 160px,
        $form-lg: 200px,
        $form-xl: 240px,
        $form-xxl: 320px,
);

.modern-page .formulate-form--horizontal {
  @include horizontal-form(160px);

  .shp_checkbox__wrapper-inline {
    justify-content: start;
    align-items: center;

    .shp-checkbox {
      flex-grow: 0;
    }

    .formulate-input-label {
      justify-content: left;
      margin: 0 0 0 $gap-sm;
      max-width: none;
      font-size: $modern-font-sm;
      font-weight: $font-demi;
      line-height: $modern-line-height-condensed;
      letter-spacing: 0.5px;
      text-align: left;
    }
  }
}

.modern-form,
.modern-inline-form,
[class^="modern-horizontal-form"], [class*=" modern-horizontal-form"] {
  .formulate-input-element--file_button {
    align-items: center;
  }
}

@media (max-width: $screen-mobile-max) {
  [class^=modern-horizontal-form],
  [class*=" modern-horizontal-form"],
  .modern-page .horizontal-form {
    .formulate-input-wrapper {
      display: block;

      .formulate-input-label {
        display: inline-block;
        min-width: auto;
        max-width: none;
        margin: 0 0 $gap-sm 0;
      }

      .shp_checkbox__wrapper-inline {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
