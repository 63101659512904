.return-info {
    margin: 15px 0;
    font-weight: bold;
}

.return-info span {
    margin-bottom: 15px;
}

.return-info img {
    display: block;
}

.feed-hidden-readed, .feed-hidden-unreaded {
    display: none;
}

.feed-readed .feed-hidden-unreaded {
    display: unset;
}

.feed-unreaded .feed-hidden-readed {
    display: unset;
}

.two-line {
    position: relative;
    top: 4px;
}

.two-line .top-line {
    position: relative;
    line-height: 0.7;
}

.panel-content-with-calendar {
    height: 181px
}

.img-panel-calendar {
    width: 181px;
    height: 181px;
    margin-right: 20px;
}

@mixin shp-datepicker_day($color, $background-color) {
    > span {
      border-radius: 100%;
      color: $color;
      background: $background-color;
    }
}

@mixin modern-shp-datepicker_day($color, $background-color) {
  > span {
    border-radius: $modern-radius-xs;
    color: $color;
    background: $background-color;
  }
}

.shp-datepicker {


  &.uiv-datepicker-date {
    .btn {
      &, &:hover, &:active, &:focus, &.active {
        background: none;
      }
    }
  }

  .btn {
    font-weight: 500;
    padding: $grid-size 0;

    &, &:hover, &:active, &:focus, &.active {
      border: none;
    }

    &:focus {
      outline: none;
    }

    > span {
      background: none;
      padding-top: 2px;
      width: 25px;
      height: 25px;
      display: inline-block;

    }
  }

  .shp-datepicker__selected_week {
    background: transparentize(#327AB7, 0.75);
    color: #ccc;
  }

  .uiv-datepicker-week {
    text-transform: uppercase;
    margin-bottom: $line-padding - $grid-size;
    font-weight: 500;
  }

  .uiv-datepicker-title, .uiv-datepicker-pager-prev, .uiv-datepicker-pager-next {
    margin-bottom: $line-padding;
    font-size: $font-size-base;
  }

  .shp-datepicker__today {
      position: relative;
      color: $text-color;

      .text-muted {
          color: inherit;
      }

      &::before {
          display: block;
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          border: 4px solid #337ab7;
          border-top-color: transparent;
          border-left-color: transparent;
      }
  }

  .shp-datepicker__info {
    @include shp-datepicker_day($lazur, scale-color($lazur, $lightness:85%));
  }

  .shp-datepicker__success {
    @include shp-datepicker_day($blue, scale-color($blue, $lightness:85%));
  }

  .shp-datepicker__danger {
    @include shp-datepicker_day($red, scale-color($red, $lightness:85%));
  }

  .shp-datepicker__default {
    @include shp-datepicker_day($text-color, scale-color($dark-gray, $lightness:50%));
  }
}

.modern-page .shp-datepicker {
  .shp-datepicker__selected_week {
    background: $modern-zircon-white;
  }
  .btn {
    padding: 4px 0px;
    font-size: $font-lg;
    font-weight: $font-demi;

    > span {
      padding-top: 4px;
      width: 32px;
      height: 32px;
    }
  }

  .shp-datepicker__success {
    @include modern-shp-datepicker_day($modern-text-white, $modern-success);
  }

  .shp-datepicker__danger {
    @include modern-shp-datepicker_day($modern-text-white, $modern-error);
  }

  .shp-datepicker__default {
    @include modern-shp-datepicker_day($text-color, $modern-tag-grey);
  }

  .shp-datepicker__info {
    @include modern-shp-datepicker_day($modern-text-white, $modern-primary);
  }
}

.client__classes_list {

  .client__classes_list__name {
    display: flex;
    @media (max-width: $screen-xs-max) {
      flex-direction: column-reverse;
    }
  }
  .client__classes_list__lesson {
    margin-bottom: 2 * $grid-size;
    color: #888888;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .client__classes_list__time {
    display: inline-block;
    margin-top: 2 * $grid-size;
  }
  .client__classes_list__classroom {
    margin-top: 2 * $grid-size;
    @media (min-width: $screen-sm-min) {
      margin-left: $line-padding;
      display: inline-block;
    }
  }

  .client__classes_list__link {
    margin-top: 2 * $grid-size;
    @media (min-width: $screen-sm-min) {
      text-align: right;
      white-space: nowrap;
    }
  }
  .client__classes_list__labels {

    margin-bottom: $grid-size;

    @media (max-width: $screen-xs-max) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .client__classes_list__label {
    margin-bottom: $grid-size;

    @media (min-width: $screen-sm-min) {
      ~ .client__classes_list__label {
        margin-left: 2 * $grid-size;
      }
    }
  }
}
