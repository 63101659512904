$modern-select-selected-background: $modern-primary-select-soft-background;
$modern-select-selected-text: $modern-primary-pressed;
$modern-select-hovered-background: $modern-select-gray-soft;
$modern-select-min-width: 200px;
$modern-select-min-height: 40px;
$modern-select-min-height-large: 48px;
$modern-select-min-height-small: 32px;

$modern-multiple-select-selected-text: $modern-primary-pressed;
$modern-multiple-select-selected-background: $modern-zircon-white;
$modern-multiple-select-hover-color: $modern-input-hover-color;

.formulate-input-wrapper .modern-select,
.modern-page .shp__vs,
.guideline-select,
.vs__dropdown-menu--popper {
  border-radius: $modern-radius;

  input::placeholder {
    color: $modern-placeholder;
  }

  .vs__dropdown-menu, &.vs__dropdown-menu {
    margin-top: $modern-gap-xs;
    border-radius: $modern-radius-lg;
    box-shadow: $modern-shadow-4dp;
    padding: $modern-gap-sm;
    max-height: 322px;
    min-width: 100%;
    width: auto;
    background-color: $modern-input-layer-color;
    top: 100%;
    border-top-style: solid;
    display: flex;
    flex-direction: column;
    gap: $modern-gap-xs;

    .modern-select__option {
      display: flex;
      align-items: center;
      gap: $modern-gap-sm;
    }

    .modern-select__selected_mark {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: $modern-radius-lg;
      background-color: inherit;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $modern-radius-lg;
      background-color: $modern-input-caption-color;
    }

    @include modern-scrollbar;

    .vs__dropdown-list-header, .vs__dropdown-select-all, .vs__dropdown-list-footer {
      color: $modern-input-caption-color;
      padding: $modern-gap-xs 0;
      font-size: $modern-font-sm;
      font-weight: $font-bold;
      text-transform: uppercase;
    }

    .vs__dropdown-select-all {
      border-bottom: 1px solid $modern-input-border-color;
      padding: $modern-gap-xs;
    }

    .vs__dropdown-option {
      padding: $modern-gap-xs;
      font-weight: $font-regular;
      font-size: $modern-font-md;
      line-height: $modern-line-height;
      color: $modern-input-text-color;
      border-radius: $modern-radius-sm;
    }

    .vs__dropdown-option--highlight {
      background: $modern-select-hovered-background;
    }

    .vs__dropdown-option--disabled {
      color: $modern-placeholder;

      .modern-select__option > * {
        color: $modern-placeholder;
      }
    }

    .vs__dropdown-option--selected, .modern-select-selected {
      background: $modern-select-selected-background;

      .modern-select__selected_mark {
        display: block;
        width: $modern-font-icon;
        height: $modern-font-icon;
        background-image: url('/static/img/root/selected-arrow.png');
        background-repeat: no-repeat;
        margin-left: auto;
      }
    }
  }

  input, textarea {
    &:disabled {
      background: none;
    }
  }

  &:disabled, &.disabled, &[disabled='disabled'] {
    cursor: not-allowed;

    .vs__dropdown-toggle {
      cursor: not-allowed;
      background-color: $modern-input-disabled-background;
    }

    .vs__open-indicator {
      display: flex;
      background-color: $modern-input-disabled-background;
    }
  }

  .vs__open-indicator {
    display: flex;
    color: $modern-input-caption-color;
  }

  .vs--open .vs__open-indicator .shp_icons-pointer_down {
    color: $modern-input-focus-color;
  }

  .vs__selected-options {
    overflow: hidden;
    gap: $modern-gap-sm;

    > input {
      padding: 0;
      margin: 0;
      border: none;

      &:last-child:not(:first-child) {
        width: 1px;
      }
    }

    .vs__selected {
      display: flex;
      flex-wrap: nowrap;
      color: $modern-input-text-color;
      font-style: normal;
      font-weight: $font-regular;
      font-size: $modern-font-md;
      line-height: $modern-line-height;
      margin: 0;
      padding: 0;
      border: none;
      min-width: 0;

      .modern-select__selected_component {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .modern-select__all-selected + .modern-select__all-selected {
      display: none;
    }
  }

  .vs__actions {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
  }

  .vs--open {
    .shp_icons-pointer_down i {
      color: $modern-input-focus-color;
    }

    .vs__selected {
      position: static;
    }

    .vs__dropdown-toggle {
      border-color: $modern-input-focus-color;
    }
  }

  .vs--disabled .vs__dropdown-toggle {
    border: none;
    background-color: $modern-input-disabled-background;
    cursor: not-allowed;

    .vs__selected-options {
      cursor: not-allowed;
    }

    .vs__open-indicator {
      background-color: $modern-input-disabled-background;
    }

    .vs__selected {
      color: $modern-input-placeholder-color;
    }
  }

  .vs__dropdown-toggle {
    box-sizing: border-box;
    border: $modern-input-border-width solid $modern-input-border-color;
    border-radius: $modern-radius-lg;
    padding: $modern-input-padding;
    background-color: $modern-input-layer-color;
    cursor: pointer;
    min-width: $modern-select-min-width;
    min-height: $modern-select-min-height;
  }

  &.modern-select--large {
    .vs__dropdown-toggle {
      min-height: $modern-select-min-height-large;
      padding: $modern-input-paddings-md;

    }
  }

  &.modern-select--small {
    .vs__dropdown-toggle {
      min-height: $modern-select-min-height-small;
      padding: $modern-input-paddings-sm;
      border-radius: $modern-radius;
    }
  }

  .vs--single {
    .vs__selected {
      white-space: nowrap;
    }

    .vs__selected-options {
      flex-wrap: nowrap;
    }
  }

  .vs--multiple .vs__selected {
    display: inline-flex;
    align-items: stretch;
    line-height: $modern-line-height-condensed;
    font-weight: $font-regular;
    letter-spacing: 1px;
    font-size: $modern-font-sm;
    vertical-align: middle;
    border-radius: $modern-radius-sm;

    .modern-select__selected_component {
      color: $modern-tag-primary-dark;
      background-color: $modern-tag-primary-light;
      border-radius: $modern-radius-sm 0 0 $modern-radius-sm;
      padding: $modern-gap-xs $modern-gap-xs $modern-gap-xs $modern-gap-condensed;
      max-width: $modern-tag-max-width;
    }

    &.hover, &.focus {
      > .vs__deselect {
        background: $modern-tag-hover;
      }
    }

    &.focus > .vs__deselect {
      outline: 1px solid $modern-primary;
    }
  }

  .vs__deselect {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: $font-regular;
    fill: $modern-tag-primary-dark;
    background: $modern-tag-primary-light;
    border-radius: 0 $modern-radius-sm $modern-radius-sm 0;
    padding: 0 $modern-gap-sm 0 $modern-gap-xs;
    margin-left: 0;

    &:hover, &:focus {
      background: $modern-tag-hover;
    }

    &:focus-visible {
      outline: 1px solid $modern-primary;
    }
  }

  &.text-mode {
    pointer-events: none;

    .vs__open-indicator {
      display: none;
    }

    .vs__deselect {
      display: none;
    }

    .vs--multiple .vs__selected > .modern-select__selected_component {
      padding: $modern-gap-xs $modern-gap-xs * 3;
      border-radius: $modern-radius-sm;
    }

    .vs__dropdown-toggle {
      padding: 0;
      border: initial;
      background: transparent;
      min-height: initial;
    }

    .vs__selected-options {
      padding: 0;
    }
  }
}

.formulate-input[data-is-showing-errors] {
  .formulate-input-wrapper .modern-select .vs__dropdown-toggle {
    border-color: $modern-error;
  }
}

.modern-page {
  .modern-select-opened {
    .shp_icons-pointer_down {
      transform: rotate(180deg);
    }
  }

  .modern-select-opened, .modern-select-focus {
    .shp_icons-pointer_down {
      color: $modern-input-focus-color;
    }

    .vs__open-indicator {
      color: $modern-input-caption-color;
    }

    .formulate-input-wrapper .vs__dropdown-toggle {
      border: 1px solid $modern-input-focus-color;
    }
  }

  .modern-select-success .formulate-input-wrapper .vs__dropdown-toggle {
    border: 1px solid $modern-success;
  }

  .modern-select-error, [data-type='vue-select'][data-is-showing-errors='true'] {
    .formulate-input-wrapper .vs__dropdown-toggle {
      border: 1px solid $modern-input-error-color;
    }

    .modern-input__caption-container {
      color: $modern-input-error-color;
    }

    .modern-input__caption-text {
      color: $modern-input-error-color;
    }

    .vs__open-indicator {
      color: $modern-input-caption-color;
    }

    .formulate-input-errors {
      margin-top: $modern-gap-xs;
    }
  }
}

.modern-select-hover .vs__dropdown-toggle {
  border: 1px solid $modern-outline-hover !important;
}

.modern-select:hover .vs__dropdown-toggle {
  border: 1px solid $modern-outline-hover;
}
