.modern-dropdown {
  border: 1px solid $modern-input-border-color;
  border-radius: $modern-radius-lg;
  padding: $gap-sm;
  background-color: $modern-input-layer-color;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    border: 1px solid $modern-outline-hover;
  }

  > a {
    color: $modern-input-text-color;
  }

  .modern-dropdown-caret {
    display: flex;
  }

  .dropdown-menu {
    margin-top: $modern-gap-xs;
    border-radius: $modern-radius-lg;
    box-shadow: $modern-shadow-4dp;
    padding: 0;
    min-width: 100%;
    width: auto;
    background-color: $modern-input-layer-color;

    .modern-select-label {
      margin-left: $modern-gap-xs * 3;
    }
  }

  .dropdown-menu > li {
    &:first-child > a {
      border-radius: $modern-radius-lg $modern-radius-lg 0 0;
    }

    &:last-child > a {
      border-radius: 0 0 $modern-radius-lg $modern-radius-lg;
    }
  }

  .dropdown-menu > li > a, .dropdown-menu .dropdown-menu__item {
    padding: $modern-gap-sm;
    font-weight: $font-regular;
    font-size: $modern-font-md;
    line-height: $modern-line-height;
    margin: 0;

    & > label {
      cursor: pointer;
    }



    &:hover {
      background: $modern-select-hovered-background;
      cursor: pointer;
    }

    &.active {
      background: $modern-primary-background;
      color: $modern-primary-pressed;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:after {
        content: url('/static/img/icons/modern_droprown_item_check.svg');
        font-size: $modern-font-icon;
      }
    }
  }

  .modern-dropdown-caret > i {
    fill: rgba(60, 60, 60, 0.5);
    transform: scale(1);
    transition: transform 150ms cubic-bezier(1, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  }
}

.modern-dropdown.open {
  border-color: $modern-input-focus-color;

  .shp_icons-pointer_down {
    transform: rotate(180deg) scale(1);
    color: $modern-input-focus-color;
  }
}
