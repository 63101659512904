$modern-progress-amazing-text: $modern-amazing;
$modern-progress-amazing-background: $modern-amazing-background;
$modern-progress-success-text: $modern-success;
$modern-progress-success-background: $modern-success-background;
$modern-progress-primary-text: $modern-primary;
$modern-progress-primary-background: $modern-primary-background;
$modern-progress-yellow-text: $modern-warning-pressed;
$modern-progress-yellow-background: $modern-yellow-background;
$modern-progress-error-text: $modern-error;
$modern-progress-error-background: $modern-error-background;
$modern-progress-rgb-gradient: $modern-gradient-rgb;

$modern-progress-battery-border-color: $modern-subtext;
$modern-progress-battery-default-color: $modern-grey;
$modern-progress-battery-low-color: $modern-orange;
$modern-progress-battery-low-background: $modern-orange-background;
$modern-progress-battery-medium-color: $modern-warning-pressed;
$modern-progress-battery-medium-background: $modern-yellow-background;
$modern-progress-battery-high-color: $modern-success;
$modern-progress-battery-high-background: $modern-success-background;

@mixin shp-progress-bar-variant ($color) {
  .progress-bar {
    background-color: $color;
    background-repeat: repeat-x;
    color: $text-color-white;
    // Deprecated parent class requirement as of v3.2.0
    //.progress-striped & {
    //  #gradient > .striped();
    //}
  }
}

.progress-bar.badge {
  color: $text-color-white;
}

.progress-bar-blue {
  @include shp-progress-bar-variant(#049cdb);
}

.progress-bar-green {
  @include shp-progress-bar-variant(#46a546);
}

.progress-bar-red {
  @include shp-progress-bar-variant(#9d261d);
}


.progress-bar-yellow {
  @include shp-progress-bar-variant(#ffc40d);
}

.progress-bar-green-success {
  @include shp-progress-bar-variant($blue);
}

.progress-bar-info {
  @include shp-progress-bar-variant(#c3325f);
}

.progress-bar-warning {
  @include shp-progress-bar-variant(#7a43b6);
}

.progress-bar-orange {
  @include shp-progress-bar-variant(#f89406);
}

.progress-bar-pink {
  @include shp-progress-bar-variant(#c3325f);
}

.progress-bar-track_group {
  @include shp-progress-bar-variant(#00b9b7);
}

.progress-bar-success {
  @include shp-progress-bar-variant(#f89406);
}

.progress-bar--purple {
  @include shp-progress-bar-variant(#7a43b6);
}


.linear-progress__wrapper {
  position: relative;
  height: 8px;
  border-radius: $modern-radius;
  overflow: hidden;

  .linear-progress__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .linear-progress__progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: $modern-radius;
    overflow: hidden;
  }

  .linear-progress__color {
    height: 100%;
  }
}

@mixin linear-progress-bar-variant($background, $color) {
  .linear-progress__background {
    background: $background;
  }

  .linear-progress__color {
    background: $color;
  }
}

.linear-progress__wrapper.linear-progress__amazing {
  @include linear-progress-bar-variant($modern-progress-amazing-background, $modern-progress-amazing-text);
}

.linear-progress__wrapper.linear-progress__success {
  @include linear-progress-bar-variant($modern-progress-success-background, $modern-progress-success-text);
}

.linear-progress__wrapper.linear-progress__primary {
  @include linear-progress-bar-variant($modern-progress-primary-background, $modern-progress-primary-text);
}

.linear-progress__wrapper.linear-progress__yellow {
  @include linear-progress-bar-variant($modern-progress-yellow-background, $modern-progress-yellow-text);
}

.linear-progress__wrapper.linear-progress__error {
  @include linear-progress-bar-variant($modern-progress-error-background, $modern-progress-error-text);
}

.linear-progress__gradient {
  background: $modern-progress-rgb-gradient;
}

@mixin shp-battery-variant($filled_color, $color) {
  border-color: $filled_color;

  .shp-battery__segment {
    background: $color;
  }

  .shp-battery__segment_filled {
    background: $filled_color;
  }
}

.shp-battery__wrapper {
  display: flex;
  gap: 2px;
  padding: $modern-gap-xs - 1;
  height: $modern-line-height;
  width: 54px;
  border: 1px solid $modern-progress-battery-border-color;
  border-radius: $modern-radius-xs;

  .shp-battery__segment {
    height: $modern-line-height-condensed;
    width: 6px;
    border-radius: $modern-radius-sm;
    background: $modern-progress-battery-default-color;
  }

  &.shp-battery__low {
    @include shp-battery-variant($modern-progress-battery-low-color, $modern-progress-battery-low-background);
  }

  &.shp-battery__medium {
    @include shp-battery-variant($modern-progress-battery-medium-color, $modern-progress-battery-medium-background);
  }

  &.shp-battery__high {
    @include shp-battery-variant($modern-progress-battery-high-color, $modern-progress-battery-high-background);
  }
}
