body.skin-1 {
  background: $bg-color none repeat scroll 0 0;
  .navbar-default {
    background-color: $menu-bg;

  }
  .navbar-static-side {
    //padding-bottom: 150px;
  }
  .navbar-background {
    background-color: $menu-bg;
    position: fixed;
    top: 0;
    left: 0;
    height: 100000px;
    width: 230px;
    z-index: 1;
  }
}

body.mini-navbar, body.body-small.mini-navbar {
  .navbar-background {
    width: 70px;
  }
}
@media (max-width: $screen-xs-max){
  body.skin-1 .navbar-background {
      width: 0;
    }
}

.row.row-no-padding {
    margin-left: 0;
    margin-right: 0;
}

#page-wrapper {
    padding: 0;
}

.modern-wrapper {
  .wrapper.wrapper-content {
    padding: $modern-gap-md;
  }

  @media (max-width: $screen-mobile-max) {
    .wrapper.wrapper-content {
      padding: $modern-gap;
    }
  }
}

.wrapper.wrapper-content {
  padding-right: $modern-gap-md;
  padding-left: $modern-gap-md;
}

.skin-1 .navbar-top-links > li:last-child {
  margin-right: 0px;
}


.navbar-static-top .navbar-right {
  margin-right: 0;
  padding-right: $line-padding;
}

.bg-color {
  background: $bg-color;
}

@media (max-width: 767px) {
  .wrapper.wrapper-content {
    padding: 2 * $grid-size;
  }
}

.modern-page {
  @media (min-width: $screen-desktop-min) {
    .third_level_menu__content,
    .third_level_menu__title,
    .third_level_menu__footer {
      padding-left: $modern-gap-md;
      padding-right: $modern-gap-md;
    }
  }

  @media (max-width: $screen-mobile-max) {
    .third_level_menu__content,
    .third_level_menu__title,
    .third_level_menu__footer {
      padding-left: $modern-gap;
      padding-right: $modern-gap;
    }
  }
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}
