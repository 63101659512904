.icon-14 {
  font-size: 16px;
}

.shp-icon {
  width: $line-padding;
  height: $line-padding;
  display: inline-block;
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;

  &.shp-icon__phone {
    background-image: url(/static/img/icons/phone.svg);
  }

  &.shp-icon__letter {
    background-image: url(/static/img/icons/letter.svg);
  }

  &.shp-icon__home {
    background-image: url(/static/img/icons/home.svg);
  }
}

@mixin icon-sizes {
  &.shp_icons-xxs {
    font-size: 4px;
    line-height: 4px;
  }

  &.shp_icons-xsm {
    font-size: 8px;
    line-height: 8px;
  }

  &.shp_icons-sm {
    font-size: $font-sm - 2;
    line-height: $font-sm - 2;
  }

  &.shp_icons-md {
    font-size: $font-md;
    line-height: $font-md;
  }

  &.shp_icons-lg {
    font-size: $font-lg;
    line-height: $font-lg;
  }

  &.shp_icons-xl {
    font-size: $font-xl;
    line-height: $font-xl;
  }

  &.shp_icons-xxl {
    font-size: $font-xxl;
    line-height: $font-xxl;
  }

  &.shp_icons-3xl {
    font-size: $font-3xl;
    line-height: $font-3xl;
  }

  &.shp_icons-size-15 {
    font-size: 15px;
    line-height: 15px;
  }

  &.shp_icons-default {
   font-size: $modern-font-icon;
   line-height: $modern-font-icon;
  }

  &.shp_icons-2xl {
    font-size: $font-xxl + 2;
    line-height: $font-xxl + 2;
  }

  &.shp_icons-standart {
    font-size: $font-xxl + 4;
    line-height: $font-xxl + 4;
  }

  &.shp_icons-text {
    vertical-align: bottom;
  }

  &.shp_icons-default {
    font-size: $modern-font-icon;
  }

  &.shp_icons-extra {
    font-size: $font-extra;
  }
}

[class^="shp_icons-"], [class*=" shp_icons-"] {
  font-size: $font-md - 1px;
  font-weight: initial;

  @include icon-sizes;
}
.shp_icons_rounded-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $modern-gap-lg;
  height: $modern-gap-lg;
  flex-shrink: 0;
  border-radius: 50%;
}

.text-muted {
  [class^="shp_icons-"], [class*=" shp_icons-"] {
    color: #777;
  }
}

//  Figures (Lobby)
$figure_colors: (
        'black' : $modern-figure-black,
        'blue' : $modern-figure-blue,
        'green' : $modern-figure-green,
        'yellow' : $modern-figure-yellow,
        'red' : $modern-figure-red,
        'violet' : $modern-figure-violet,
        'orange' : $modern-figure-orange,
        'grey' : $modern-figure-grey,
);

//  shp_icons-black, shp_icons-blue, shp_icons-green, shp_icon-yellow, shp_icons-red, shp_icons-violet, shp_icons-orange
@each $color_name, $color_value in $figure_colors {
  .shp_icons-#{$color_name} {
    color: $color_value;
  }
}

.modern-page {
  [class^="shp_icons-"], [class*=" shp_icons-"] {
    font-size: $modern-font-icon;
    line-height: $modern-font-icon;

    @include icon-sizes;
  }
}

.gender-icon-male {
  color: $modern-primary;
}

.gender-icon-female {
  color: $female-icon-color;
}
