$modern-stepper-vertical-navigation-xs-size: 160px;
$modern-stepper-vertical-navigation-sm-size: 200px;
$modern-stepper-vertical-navigation-size: 240px;
$modern-stepper-vertical-navigation-md-size: 280px;
$modern-stepper-vertical-navigation-lg-size: 320px;

$modern-stepper-padding-size: $modern-gap-md;
$modern-stepper-padding-mobile-size: $modern-gap-sm;
$modern-stepper-progress-size: $modern-gap-xs;

$modern-stepper-icon-container-size: $modern-gap-lg;

$modern-step-wizard-icon-size: 24px;
$modern-step-wizard-passed-icon-size: 24px;

$modern-stepper-background: $modern-layer;
$modern-stepper-inner-line-color: $modern-line;
$modern-stepper-radius: $modern-radius;

$modern-step-passed-title-color: $modern-subtext;
$modern-step-passed-text-color: $modern-text-black;
$modern-step-passed-icon-color: $modern-text-white;
$modern-step-passed-icon-background: $modern-primary;

$modern-step-active-title-color: $modern-subtext;
$modern-step-active-text-color: $modern-text-black;
$modern-step-active-icon-color: $modern-primary;
$modern-step-active-icon-background: $modern-primary;

$modern-step-hover-title-color: $modern-primary-hover;
$modern-step-hover-text-color: $modern-primary-hover;

$modern-step-disabled-title-color: $modern-placeholder;
$modern-step-disabled-text-color: $modern-placeholder;
$modern-step-disabled-icon-color: $modern-line;
$modern-step-disabled-icon-background: $modern-placeholder;

$modern-step-progress-passed-color: $modern-primary;
$modern-step-progress-available-color: $modern-primary-disable;
$modern-step-progress-disable-color: $modern-placeholder;
$modern-step-mobile-progress-passed-color: $modern-primary;
$modern-step-mobile-progress-available-color: $modern-primary-disable;
$modern-step-mobile-progress-disable-color: $modern-primary-background;

.modern-stepper {
  &.modern-stepper-vertical {
    @mixin navigation-size($width) {
      .modern-stepper-navigation-container {
        width: $width;
      }
    }

    &.fit-vertical-size {
      height: 100%;

      .modern-step-wizard-tabs {
        .modern-step-wizard-tab {
          &:first-child {
            flex-grow: 0;
          }

          &__progress_container {
            max-height: 80px;
            height: 100%;
            min-height: 20px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .modern-step-wizard-tabs {
      flex-direction: column;
      flex-grow: 2;

      .modern-step-wizard-tab {
        display: flex;
        flex-direction: column;

        &__main_container {
          display: flex;
          padding: 0;
        }

        &__progress_container {
          order: -1;
          height: 80px;
          padding: 4px 14px;
        }

        &__progress_item {
          height: 100%;
          width: $modern-stepper-progress-size;
          border-radius: $modern-stepper-radius;
          flex-grow: 1;
        }

        &:first-child {
          .modern-step-wizard-tab__progress_container {
            display: none;
          }
        }
      }
    }

    .modern-stepper-content-container {
      flex-grow: 3;
    }

    @media (min-width: $screen-tablet-min) {
      display: flex;

      &.modern-stepper-xs { @include navigation-size($modern-stepper-vertical-navigation-xs-size) }
      &.modern-stepper-sm { @include navigation-size($modern-stepper-vertical-navigation-sm-size) }
      &.modern-stepper-md { @include navigation-size($modern-stepper-vertical-navigation-md-size) }
      &.modern-stepper-lg { @include navigation-size($modern-stepper-vertical-navigation-lg-size) }

      .modern-stepper-navigation-container {
        padding: $modern-stepper-padding-size;
        width: $modern-stepper-vertical-navigation-size;
        border-right: 1px solid $modern-stepper-inner-line-color;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }

  &.modern-stepper-mobile {
    .modern-step-wizard-tabs {
      display: none;
    }

    .modern-step-wizard-mobile-tabs {
      display: block;
    }
  }

  .modern-step-wizard-tabs {
    display: flex;
  }

  .modern-step-wizard-mobile-tabs {
    display: none;
  }

  .modern-stepper-content-container {
    &.container_indented {
      padding: $modern-gap-md;

      @media (max-width: $screen-mobile-max) {
        padding: $modern-gap;
      }
    }
  }

  @media (max-width: $screen-mobile-max) {
    .modern-step-wizard-tabs {
      display: none;
    }

    .modern-step-wizard-mobile-tabs {
      display: block;
    }
  }
}

.modern-step-wizard-mobile-tabs {
  .modern-step-wizard-tab__main_container {
    padding: $modern-stepper-padding-mobile-size;
  }

  .modern-step-wizard-tab.disabled {
    .modern-step-wizard-tab__progress_item {
      background-color: $modern-step-progress-disable-color;
    }
  }
}

.modern-step-wizard-tab {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;

  &__main_container {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    padding:
      $modern-stepper-padding-size
      $modern-stepper-padding-size
      ($modern-stepper-padding-size - $modern-stepper-progress-size)
      $modern-stepper-padding-size;
    cursor: pointer;
  }

  &__icon_container {
    display: flex;
    align-items: center;
    margin-right: $modern-gap-sm;
  }

  &__title_container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
  }

  &__title, &__index {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__progress_item {
    height: $modern-stepper-progress-size;
    background-color: $modern-step-progress-available-color;
    width: 100%;
  }

  &__index {
    color: $modern-step-active-title-color;
  }

  .modern-step-wizard-icon {
    width: $modern-stepper-icon-container-size;
    height: $modern-stepper-icon-container-size;
    border-width: 2px;
    border-style: solid;
    border-color: $modern-step-active-icon-background;
    border-radius: 100%;
    color: $modern-step-active-icon-color;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: $modern-step-wizard-icon-size;
    }
  }

  &.passed {
    .modern-step-wizard-tab__progress_item {
      background-color: $modern-step-progress-passed-color;
    }

    .modern-step-wizard-icon {
      background-color: $modern-step-passed-icon-background;
      color: $modern-step-passed-icon-color;

      i {
        font-size: $modern-step-wizard-passed-icon-size;
      }
    }
  }

  &.active {
    .modern-step-wizard-tab__progress_item {
      background-color: $modern-step-progress-passed-color;
    }
  }

  &.disabled {
    .modern-step-wizard-tab__main_container {
      cursor: not-allowed;
    }

    .modern-step-wizard-icon {
      border-color: $modern-step-disabled-icon-background;
      color: $modern-step-disabled-icon-color;
    }

    .text-button {
      color: $modern-step-disabled-text-color;
    }

    .modern-step-wizard-tab__index {
      color: $modern-step-disabled-title-color;
    }

    .modern-step-wizard-tab__progress_item {
      background-color: $modern-step-progress-disable-color;
    }
  }

  &:hover:not(.disabled) {
    .text-button {
      color: $modern-step-hover-text-color;
    }

    .modern-step-wizard-tab__index {
      color: $modern-step-hover-title-color;
    }
  }
}