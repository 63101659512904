@use "sass:math";

.top_mobile_menu {
  top: 0;
  z-index: 2000;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s, visibility 0.15s;

  .navbar-static-top {
    min-height: auto;
  }

  .top_mobile_menu__burger {
    padding: 0 $line_padding;
    font-size: 20px;
    color: $navy;
    flex: 1;

    transition: width 250ms ease-out, height 250ms ease-out, padding 250ms;
  }
  .top_mobile_menu__avatar {
    padding: math.div($line-padding, 2) $line-padding;
    float: right;
    transition: all .5s linear;

    img {
      width: 40px;
      height: 40px;
      transition: width 250ms ease-out, height 250ms ease-out, padding 250ms;
    }
  }

  &.top_mobile_menu-collapsed, &.top_mobile_menu-hidden {
    position: fixed;

    .top_mobile_menu__avatar {
      padding-top: math.div($line_padding, 4);
      padding-bottom: math.div($line_padding, 4);

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .brand-logo {
    margin: 3 * $grid-size $line-padding;
  }

  &.top_mobile_menu-hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }
}

.top_mobile_menu__wrapper {
  height: 57px;
  min-height: 57px;
}

$mobile-navbar-text-color: #9ea6b9;

body {
  .shp-navbar-mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 2001;
    top: 0;
    left: 0;
    color: $mobile-navbar-text-color;

    .sidebar-collapse {
      background-color: $menu-bg;
    }

    .shp-navbar-mobile__content {
        top: 56px;
        margin: 0;
        padding: 0;
        height: calc(100vh - 114px);
        overflow-y: auto;
        -ms-overflow-style: none;  // Internet Explorer 10+
        scrollbar-width: none;  // Firefox
        &::-webkit-scrollbar {
            display: none;
        }

      .nav-label {
        display: inline;
      }

      > li {
        list-style: none;
        &.active, &:hover {
          color: $text-color-white;
        }

        > a {
          color: $nav-text-color;
          font-weight: 400;
          padding: 14px $line-padding 14px $line-padding;
          display: block;
        }

        &.active a, > a:hover, > a:focus {
          background-color: rgba(0, 0, 0, 0.2);
          color: $text-color-white;
        }
      }
    }

    .shp-copyright {
      list-style: none;

      margin: 0;
      padding: 0;
      bottom: 0;
      left: 0;
      position: fixed;
      background-color: $menu-bg;
      color: $text-color-white;
      width: 100%;

      > li {
        list-style: none;

        > a {
          color: $nav-text-color;
          font-weight: 400;
          padding: 24px $line-padding 14px $line-padding;
          display: block;

          .nav-label {
            display: inline;
          }
        }
      }
    }
  }
}

#chat-24-widget-container {
  z-index: 1100 !important;
  font-family: $fonts;
}

@media (max-width: $screen-tablet-max) {
  #chat-24-widget-container {
    bottom: $modern-gap-xl !important;
  }
}

#chat-24-roll-icon {
  width: 42px !important;
  height: 42px !important;
}

//#chat-24-inner-container {
//  background: none;
//  padding: 0;
//}

.dropdown-dark .dropdown-menu {
  background-color: $menu-bg;
  > li > a {
    color: $text-color-white;
  }
}

.dropdown-condensed .dropdown-menu {
  padding-top: $grid-size;
  padding-bottom: $grid-size;
  min-width: auto;
  font-size: $font-md;

  > li > a {
    padding: 1.5 * $grid-size 4 * $grid-size $grid-size 2 * $grid-size;
    margin: 0;
    line-height: 20px;
    font-weight: 400;
    color: $nav-text-color;
    border-radius: 0;

    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.2);
      color: $text-color-white;
    }
  }
}
