.nearest-classes {
  transition: .3s;
}

.nearest-classes:hover {
  background: $table-bg-hover;
}

.subject-description {
  .subject-description-card {
    border-radius: $modern-radius;
    padding: $modern-gap-md;
    background-color: $modern-layer;
    margin-bottom: $modern-gap-md;
  }

  .header-divider {
    margin: 0 auto $modern-gap-md;
    width: 100px;
    height: 8px;
    border-radius: $modern-radius;
    background: $modern-primary-hover;
  }

  .heading-dark {
    color: $modern-text-white;
  }

  .subject-description-card__main-info {
    display: flex;
    gap: $modern-gap-md;
    justify-content: space-between;

    @media (max-width: $screen-mobile-max) {
      flex-direction: column-reverse;
    }
  }
}

.client-subject-info__container {
  display: flex;
  justify-content: space-between;
  gap: $modern-gap-md;
  min-height: 224px;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;
    min-height: auto;
  }
}

.client-subject-info__description {
  max-width: 1024px;
}

.client-subject-info__header {
  display: flex;
  gap: $modern-gap;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;
  }
}

.client-subject-info__subheader {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $modern-gap;
  line-height: $modern-line-height-condensed;
}

.client-subject-info__image {
  width: 64px;
  height: 64px;
  object-fit: contain;
  border-radius: $modern-radius;
}

.client-subject-info__classes-container {
  display: flex;
  justify-content: space-between;
  gap: $modern-gap-md;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;
  }
}

.client-subject-info__classes {
  padding: $modern-gap-sm 0 $modern-gap-sm $modern-gap-md;
  width: 380px;

  @media (max-width: $screen-mobile-max) {
    padding: 0;
    width: auto;
  }
}

.client-subject-course__part-info {
  display: flex;
  gap: $modern-gap-lg;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;
  }
}

.client-subject-detail__classes-container {
  display: flex;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column-reverse;
    gap: $modern-gap-md;
  }
}

.client-subject-detail__classes-list {
  flex-basis: 66%;

  @media (max-width: $screen-mobile-max) {
    flex-basis: 100%;
  }
}

.client-subject-detail__subject-info {
  display: flex;
  flex-direction: column;
  gap: $modern-gap-lg;
  flex-basis: 33%;
  margin-left: $modern-gap-lg;

  @media (max-width: $screen-mobile-max) {
    flex-basis: 100%;
    margin-left: 0;
  }
}

.client-subject-detail__marks-info {
  display: flex;
  gap: $modern-gap-md;
  flex-wrap: wrap;
}
