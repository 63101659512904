
.divider {
  margin: 0 10px 10px 0;
}

.small-divider {
  margin: 0 20px 5px 20px;
}

hr.divider {
  margin: 10px 0 10px 0;
}

.divider.divider-large {
  margin: 40px 0 40px 0;
}

hr.divider.divider-tiny {
  margin: 5px 0 5px 0;
}

hr.divider.divider-no-top {
  margin-top: 0;
}

hr.divider.title-divider {
  margin-top: 4px;
}

hr.divider.divider-short {
  max-width: 100px;
}

hr.divider-field {
  margin-top: 4px;
  margin-bottom: 15px;
}

.thin-line {
  line-height: 14px;
}

.modern-divider {
  width: 100%;
  height: 1px;
  background-color: $modern-disable;

  &.modern-divider-vertical {
    width: 1px;
    height: 100%;
  }

  &.modern-divider_indent {
    margin-top: $modern-gap-md;
    margin-bottom: $modern-gap-md;
  }

  &.modern-divider_blue {
    background-color: $modern-primary-hover;
  }
}

.border-left {
  border-left: 2px solid $modern-disable;

  &.border-left_indent {
    padding-left: $modern-gap-md;
  }

  &.border-left_blue {
    border-color: $modern-primary-disable;
  }
}

.shp-dash {
  display: block;
  background: $modern-line;
  width: 8px;
  height: 2px;
  border-radius: $modern-radius-lg;
}

.shp-dash {
  display: block;
  background: $modern-text-black;
  width: 8px;
  min-width: 8px;
  height: 2px;
  border-radius: $modern-radius-lg;
}

