.client-course-registration {
  min-width: 250px;
  cursor: pointer;
  transition: .3s;
  position: relative;

  &:hover {
    background: darken($panel-bg, 3);
  }

  &::after {
    content: '✔';
    position: absolute;
    color: $brand-primary;
    font-size: 24px;
    border-radius: 50%;
    right: 8px;
    top: 8px;
    opacity: 0;
    transition: .3s ease-in-out;
  }

  &_selected {
    &::after {
      color: $brand-success;
      right: 8px;
      top: 8px;
      opacity: 1;
    }
  }

  &_intersected {
    &::after {
      content: '❌';
      color: $brand-danger;
      right: 8px;
      top: 8px;
      opacity: 1;
      font-size: 24px;
    }
  }
}

.course-registration__card {
  width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  .course-registration__card {
    min-width: 320px;
    width: 30%;
    flex: 0 0 30%;
  }
}

.pupil_distribution {
  .registration-status {
    position: absolute;
    bottom: 24px;
    left: 16px;
  }
}

.selected {
  .client-course-registration {
    background: darken($panel-bg, 5);
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);

    &::after {
      opacity: 1;
    }
  }
}

.schedule_list {
  &__item {
    margin-bottom: 10px;
  }

  &__item:last-child {
    margin: 0;
  }
}

.schedule__day_name {
  width: 128px;
  display: inline-block;
}

.course-recommendations__icons {
  min-height: $line-height;
}

.preparatory-course-card {
  display: flex;
  flex-direction: column;
  gap: $modern-gap;
  padding-top: $modern-gap-md;

  &:not(:first-child) {
    border-top: 1px solid $modern-line;
  }
}

.modern-page {
  .client-course-registration {
    cursor: default;

    .row {
      margin: 0;
    }

    .course-landing {
      cursor: default;

      .main-section {
        min-height: 700px;

        .main-section__background-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 100px auto 0;
          height: 500px;
          width: 1280px;
          padding-top: $modern-gap-4xl;
          background: url(/static/img/course_registrations/default_img_group.png) 50%/cover;

          @media (max-width: 1280px) {
            width: auto;
            background: none;
          }
        }
      }
    }
  }
}

$course-registration-container-width: 1144px;

.modern-page {
  .course-registration-description {
    padding: 0;
    background: $modern-layer;
    cursor: default;
    min-height: calc(100vh - $modern-gap-xl);

    .course-registration-description__limited-container {
      width: $course-registration-container-width;
      margin: 0 auto;

      @media (max-width: $screen-tablet-max) {
        width: 100vw;
      }
    }

    .course-registration-description__head-section {
      padding: $modern-gap-2xl 0;

      @media (max-width: $screen-mobile-max) {
        padding-top: $modern-gap-2xl;
      }

      .dark-theme {
        color: $modern-text-white;

        .head-section__course-role {
          color: $modern-text-white;
        }
      }
    }

    .schedule-divider {
      width: 100%;
      height: 1px;
      background-color: $modern-primary-background;
      margin: $modern-gap-lg 0;
    }

    .header-divider {
      margin: $modern-gap auto $modern-gap-xl;
      width: 100px;
      height: 8px;
      border-radius: $modern-radius;
      background: $modern-primary-hover;
    }
  }

  .course-registration {
    min-height: calc(100vh - $modern-gap-xl);

    .course-registration-stepper-layout {
      .registration-layout__content {
        height: 1px;

        @media (max-width: $screen-mobile-max) {
          border-radius: $modern-radius;
          height: auto;
          margin-bottom: 0;
        }

        .modern-stepper-content-container {
          height: 100%;
        }
      }

      .registration-layout__description {
        height: 100%;

        @media (max-width: $screen-mobile-max) {
          margin-bottom: $modern-gap-md;
          background-color: transparent;
          box-shadow: none;
        }
      }

      .registration-layout__login {
        position: static;
        margin-top: auto;
        margin-left: 0;
      }

      .registration-accounts-layout {
        margin-top: 0;

        .registration-accounts-container {
          height: 640px - $gap-xl * 2;

          @media (max-width: $screen-mobile-max) {
            height: auto;
          }
        }
      }

      .channel-enter-form {
        margin-top: $modern-gap-2xl;

        @media (max-width: $screen-mobile-max) {
          margin-top: 0;
        }
      }
    }

    .course-registration__information {
      max-height: 360px;
      overflow-y: scroll;
      margin-bottom: unset;
      padding: unset;
      background-color: transparent;

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }

    .course-registration__title {
      font-size: $modern-font-h3;
      font-weight: $font-bold;
      line-height: $modern-line-height-h3;
      letter-spacing: 0;

      @media (max-width: $screen-mobile-max) {
        font-size: $modern-mobile-font-h3;
        line-height: $modern-mobile-line-height-h3;
      }
    }
  }

  .course-registration-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - $modern-gap-xl);
  }
}
