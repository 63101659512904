$optional-filter-choice-width: 240px;
$optional-filter-value-width: 320px;
$optional-filter-operation-width: 180px;
$optional-filter-search-width: 328px;
$optional-filter-search-open-width: 600px;

.shp-filter-panel {
  display: flex;
  place-items: center;

  .shp-filter-label {
    width: 5 * $line-height;
    margin-right: $line-padding;
  }

  .shp-filter-operation {
    width: 7 * $line-height;
    margin-right: $line-padding;
  }

  .shp-filter-value {
    flex: 1;
    margin-right: $line-padding;
  }

  .shp-filter-cancel {
    min-width: $line-height;
    text-align: right;
  }
}

.shp-filter-panel + .shp-filter-panel {
  margin-top: $gap-md;
}


.shp_filter__inline {
  display: flex;
  align-items: center;

  > label {
    margin-right: 2 * $grid-size;
  }
}

.shp-filter-optional {
  width: 100%;
  order: 10;

  &__search .formulate-input-element {
    width: 100%;
  }

  &__fields {
    padding: $gap;
    min-width: 0;

    &-empty {
      color: $modern-line;
    }
  }

  &__dropdown {
    position: relative;
    min-height: 144px;
    display: flex;
    justify-content: space-between;

    &-title {
      font-weight: $font-bold;
    }

    &-choice {
      padding: $gap;
      background: $modern-zircon-white;
      display: flex;
      flex-direction: column;
      gap: $gap;
      width: $optional-filter-choice-width;
      min-width: $optional-filter-choice-width;

      .shp_switch-list__row {
        gap: $modern-gap;
      }
    }
  }

  &__modal.shp-modern-modal {
    &.modal.fade:not(.modal-align-center) .modal-dialog {
      margin: 0;
      width: 100%;
    }

    .modal-content {
      border-radius: 0;
      min-height: var(--window-height);
    }
  }

  &__modal {
    .shp-filter-panel + .shp-filter-panel {
      margin-top: $modern-gap;
    }

    .shp-filter-optional__fields {
      padding: 0;
    }

    .shp-filter-panel {
      flex-wrap: wrap;
      gap: $modern-gap-sm;
    }

    .shp-filter-label, .shp-filter-operation, .shp-filter-value {
      width: 100%;
      margin: 0;
    }
    .nav.nav-tabs.modern-nav-tabs > li.active > a, .nav.nav-tabs.modern-nav-tabs li.open.active > a {
      color: $modern-primary;
    }
    .nav.nav-tabs.modern-nav-tabs > li > a, .nav.nav-tabs.modern-nav-tabs li.open > a {
      padding-bottom: $modern-gap-xs;
      width: 100%;
      text-align: center;
      text-transform: initial;
      color: $modern-text-black;
      font-size: $modern-font-md;
      line-height: $modern-line-height;
      font-weight: $font-demi;
    }
    .nav.nav-tabs.modern-nav-tabs {
      flex-wrap: nowrap;
    }
    .nav.nav-tabs.modern-nav-tabs > li, .nav.nav-tabs.modern-nav-tabs li.open {
      flex: 50%;
    }
  }

  .date.L, .shp-form-inline .date.LT {
    max-width: 100%;
  }

  .shp-filter-operation {
    width: $optional-filter-operation-width;
    .formulate-input-wrapper .modern-select .vs__dropdown-toggle, .modern-page .shp__vs .vs__dropdown-toggle {
      min-width: initial;
    }
  }

  .shp-filter-value {
    min-width: $optional-filter-value-width;
  }

  .shp_checklist .shp_checklist__row .shp-radio {
    margin-right: $modern-gap-sm;
  }

  .shp-form-inline .shp_checklist__list .shp_checklist__row ~ .shp_checklist__row {
    margin-left: $modern-gap;
  }

  &,
  &__modal {
    .shp_checkbox__wrapper-inline label.shp-checkbox {
      margin: 0;
    }

    .shp_checklist .shp_checklist__list.shp_checklist__horizontal > * {
      margin-right: 0;
    }

    .shp_checklist .shp_checklist__row .shp-radio {
      margin-right: $modern-gap-sm;
    }

    @media (max-width: $screen-mobile-max) {
      .shp_checklist .shp_checklist__list.shp_checklist__horizontal {
        flex-direction: column;
      }

      .shp-form-inline .shp_checklist__list .shp_checklist__row ~ .shp_checklist__row {
        margin-left: 0;
        margin-top: $modern-gap;
      }

      .shp-form-inline .shp_checklist__list {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .shp-filter-optional__selector > .dropdown-menu {
    width: $screen-md;
    padding: 0;
    margin-top: $gap-xs;
    white-space: initial;
  }

  .formulate-input-wrapper {
    display: block;
  }


  &__selector {
    width: 100%;
    max-width: $optional-filter-search-width;
  }

  &__selector.open {
    max-width: $optional-filter-search-open-width;
  }
}
