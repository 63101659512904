@import "../mixins";

.btn-toolbar.text-toolbar, .btn-group.text-btn-group{
    font-size: inherit;
    margin-top: 0;
    margin-bottom: 0;
}

.dropdown-menu a.hidden {
    display: none;
}

.btn-group .btn-label {
    padding-top: 4px;
    padding-right: 4px;
    float: left;
}

.btn.btn-xs.btn-icon {
    width: 24px;
    max-width: 24px;
    padding: 1px 5px 1px 5px;
    text-align: center;
}

.btn.btn-sm.btn-icon {
    width: $line-height ;
    max-width: $line-height;
    height: $line-height;
    max-height: $line-height;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

.btn.btn-sm.btn-icon i {
    font-size: 14px;
    padding-top: 1px;
    display: inline-block;
}

.btn-group.btn-group-icon {
    height: 20px;
    font-size: 14px;
}

.btn-group.text-btn-group > a, .normal-font {
    font-size: 14px;
}

small .btn-group.text-btn-group > a {
    font-size: 12px;
}

.btn-group.btn-group-icon > a:hover{
    text-decoration: none;
}

.btn-group.btn-group-icon > a{
    padding: 2px 6px;
    border-radius: 4px;
}

.btn.btn-icon.btn-fixed-height {
    line-height: 19px;
}

.btn-dark-blue {
    background: #2C3E50;
    color: white;

    &:hover {
        color: white;
    }
}

.discussion_button_outline__success {
  @include discussion-button-outline-mixin($brand-success, white);
}

.discussion_button_outline__danger {
  @include discussion-button-outline-mixin($brand-danger, white);
}

.discussion_button_outline__primary {
  @include discussion-button-outline-mixin($brand-primary, white);
}

.btn-default-selected,
.btn-default-hovered:hover {
  background: #fff;
  border: 1px solid #2980b9;
}


.shp-btn-edit {
    line-height: 1em;
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    color: $brand-primary;
    background-color: lighten($brand-primary, 45%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        background-color: lighten($brand-primary, 35%);
    }
}

.btn-rounded {
  border-radius: 50%;
}
