.shp_flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: $line-height;
  height: 20px;

  &.shp_flag__gb {
    background-image: url(/src/static/img/shp_flag__gb.svg);
  }

  &.shp_flag__ru {
    background-image: url(/src/static/img/shp-flag__ru.svg);
  }

  &.shp_flag-bordered {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
    //border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }

}

.shp_flag__container {
  height: 20px;
}
