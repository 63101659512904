.week-datepicker .bootstrap-datetimepicker-widget {
  table {
    tr:hover td.active, tr td.active ~ td {
      background-color: #337ab7;
      color: #fff;
    }
    td {
      border-radius: 0;
    }
    tr:hover td {
      background-color: #eeeeee;
    }
  }
}
