@use "sass:color";
@use "sass:math";

$table-modern-cell-padding: $grid-size * 2;
$table-modern-row-padding: $grid-size * 3;
$table-modern-line-height: 40px;
$table-modern-line-height-condensed: 32px;
$table-modern-line-height-large: 48px;
$table-modern-radius: $modern-radius-lg;

$modern-table-header-text-color: $modern-subtext;
$modern-table-row-text-color: $modern-text-black;
$modern-table-header-background: #F1F1F5;
$modern-table-line-color: #F1F1F5;
$modern-table-row-background: $modern-layer;
$modern-table-row-hover-background: #F6F6F9;
$modern-table-row-striped-background: color.mix($modern-transparent-colors-layout, #0062FF, 90%);
$modern-table-row-success-background: $modern-success-background;
$modern-table-row-warning-background: color.mix($modern-transparent-colors-layout, #FFB100, 85%);
$modern-table-row-error-background: $modern-error-background;
$modern-table-row-opened-background: #E9E9ED;
$modern-table-row-selected-background: $modern-primary-background;

table.table-modern {
  font-family: $modern-font-family;
  font-size: $modern-font-md;
  font-weight: $font-regular;
  line-height: $modern-line-height;
  border-collapse: collapse;
  width: 100%;
  border-radius: $table-modern-radius;
  margin-bottom: $modern-gap-md;

  .table-header__title {
    font-size: $modern-font-sm;
    font-weight: $font-bold;
    line-height: $modern-line-height-condensed;
    letter-spacing: 1px;
    vertical-align: middle;
  }

  .table-modern {
    margin-bottom: 0;
  }

  th {
    padding: $table-modern-row-padding $table-modern-cell-padding;
  }

  td {
    padding: $table-modern-cell-padding;
  }

  tr {
    background-color: $modern-table-row-background;
  }

  tr.checked {
    background-color: $modern-table-row-selected-background;
  }

  .table-modern-active {
    background-color: $modern-table-row-hover-background;
  }

  .table-modern-info {
    background-color: $modern-table-row-opened-background;
  }

  .table-modern-success {
    background-color: $modern-table-row-success-background;
  }

  .table-modern-warning {
    background-color: $modern-table-row-warning-background;
  }

  .table-modern-danger {
    background-color: $modern-table-row-error-background;
  }

  > thead {
    position: relative;
    text-transform: uppercase;
    font-size: $modern-font-sm;
    line-height: $modern-line-height-condensed;
    font-weight: $font-bold;
    letter-spacing: 1px;
    color: $modern-table-header-text-color;

    > tr {
      background-color: $modern-table-header-background;
      color: $modern-table-header-text-color;
      border-bottom: 1px solid $modern-table-line-color;
      text-transform: uppercase;

      > th {
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }

  > tbody {
    > tr:not(:last-child) {
      border-bottom: 1px solid $modern-table-line-color;
    }

    > tr, > tr > td {
      color: $modern-table-row-text-color;
    }

    > tr.alert-row {
      border-bottom: none;

      td {
        padding: 0;
      }
    }
  }
}

table.table-modern.table-modern-top-content {
  td {
    vertical-align: top;
  }
}

table.table-modern-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $modern-table-row-striped-background;
  }
}

table.thead-modern-uppercase {
  thead > tr {
    text-transform: uppercase;
  }
}

table.table-modern-hover {
  > tbody > tr:hover {
    background-color: $modern-table-row-hover-background;
  }
}

table.table-modern-bordered {
  > thead,
  > tbody {
    > tr {
      > th,
      > td {
        border: 1px solid $modern-table-line-color;
      }
    }
  }
}

table.table-modern-condensed {
  > tbody {
    > tr {
      > th,
      > td {
        padding: math.div($table-modern-cell-padding, 2) $table-modern-cell-padding;
        height: $table-modern-line-height-condensed;
      }
    }
  }
}

table.table-modern-large {
  > tbody {
    > tr {
      > th,
      > td {
        padding: $table-modern-row-padding $table-modern-cell-padding;
        height: $table-modern-line-height-large;
      }
    }
  }
}

.scrollable-table {
  table:first-child{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .scrollable-table-body {
    overflow-x: auto;
    border-bottom-left-radius: $modern-radius;
    border-bottom-right-radius: $modern-radius;

    table {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: $modern-radius;
    background-color: $modern-scrollbar-thumb-color;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: $modern-radius;
    background-color: $modern-scrollbar-thumb-color;
  }
}


.modern-panel table.table-modern, table.table-modern.table-outlined {
  outline: 1px solid $modern-table-line-color;
  outline-offset: -1px;

  > thead > tr {
    border-bottom: 1px solid $modern-table-line-color;
  }

  > tbody > tr:not(:last-child) {
    border-bottom: 1px solid $modern-table-line-color;
  }

  > tbody > tr.alert-row {
    border-bottom: none;

    td {
      padding: 0;
    }
  }
}

.modern-panel table.table-modern.table-modern-simple, table.table-modern-simple {
  outline: none;
  > thead > tr {
    background: none;
  }
}

table.table-fixed {
  table-layout: fixed;

  th {
    width: 100%;
  }
}
