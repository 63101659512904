.shp_container-xxs {
   max-width: 384px;
}

.shp_container-xs {
  @media (min-width: $screen-sm-min)  {
     max-width: $screen-xs-max;
  }
}

.shp_container-sm {
  @media (min-width: $screen-md-min)  {
     max-width: $screen-sm-max;
  }
}

.shp_container-center {
  margin-left: auto;
  margin-right: auto;
}