@use "sass:math";

.nav li.active a {
  outline: 0;
}

.nav.nav-pills.nav-thin-pills {
  margin-bottom: 0;
}

.nav.nav-pills.nav-thin-pills li a {
  margin-top: 0;
  margin-bottom: 0;
}

.nav.nav-stacked > li > .nav.nav-stacked {
  margin-left: 15px;
}

.nav-tabs.nav-row > li > a, .nav-pills.nav-row > li > a {
  line-height: 18px;
  padding: 0;
}

.nav-thin-pills > li.h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar.visible .navbar-inner {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.navbar.visible .well {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar .nav-collapse .nav > li > a {
  padding-left: 6px;
  padding-right: 6px;
}

.nav li a.hidden {
  display: none;
}


.skin-1 .wrapper-content .nav.nav-buttons {
  > li {
    border: 2px solid transparent;
    border-radius: 4px;

    > a {
      &, &:hover, &:focus {
        color: $text-color;
      }
    }

    &, &.active {
      background-color: transparent;

      a {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: lighten($state-info-bg, 5%);
    }

    // Links rendered as pills
    > a {
      padding: math.div($panel-body-padding, 2) $panel-body-padding;
      border-radius: $nav-pills-border-radius;
      color: $text-color;
    }

    + li {
      margin-left: 2px;
    }

    // Active state
    &.active {
      border: 2px solid $brand-primary;

      & > a {
        &,
        &:hover,
        &:focus {
          color: $text-color;
        }
      }
    }

    &.disabled {
      > a {
        color: $text-muted;
      }
    }

  }

  &.nav-stacked {
    > li + li {
      margin-top: $line-padding;
    }
  }
}

.shp_breadcrumb {
  a {
    font-size: $font-xl;
    line-height: $line-height-computed;
    margin-right: $line-padding;
  }

  .active {
    color: black;
    border-bottom: 2px solid $navy;
  }
}

.step-wizard-tabs {
  display: flex;

  .step-wizard-tab {
    flex: 1 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.step-wizard-fixed-tabs {
  display: block;
}

@media (max-width: map_get($grid-breakpoints, 'md')) {
  .shp_breadcrumb-xs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.nav.nav-tabs.modern-nav-tabs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: $modern-gap-lg;
  row-gap: $modern-gap-xs;
  margin-bottom: $modern-gap-sm;
  border: none;
  font-family: "DIN 2014 Rounded", Arial, sans-serif;

  @media (max-width: $screen-mobile-max) {
    gap: $modern-gap;
  }

  &:before {
    content: none;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background: $modern-primary-disable;
    height: 1px;
    width: 100%;
  }

  > li, li.open {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    margin: 0;
    z-index: 5;

    > a {
      padding: 0;
      margin: 0;
      border-color: transparent;
      border-bottom: 2px solid transparent;
      font-size: $modern-font-h4;
      font-weight: $font-bold;
      line-height: $modern-line-height;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase;
      color: $modern-primary;

      @media (max-width: $screen-mobile-max) {
        min-height: 20px;
        font-size: $modern-font-sm;
        line-height: $modern-line-height-condensed;
        letter-spacing: 1px;

        i {
          font-size: $modern-font-xs;
          line-height: $modern-font-xs;
        }
      }

      .modern-badge:not([class*=" modern-badge-"]) {
        background-color: $modern-primary;
      }

      .tab-title {
        display: flex;
        align-items: center;
        gap: $modern-gap-xs;
      }

      &.dropdown-toggle {
        display: flex;
        align-items: center;
        gap: $modern-gap-xs;
      }

      i {
        margin: 0;
      }
    }

    &:hover, &.hover {
      > a {
        padding: 0;
        background: none;
        color: $modern-primary-hover;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom: 2px solid $modern-primary-hover;

        .modern-badge:not([class*=" modern-badge-"]) {
          background-color: $modern-primary-hover;
        }
      }
    }

    > a:focus-visible {
      padding: 0;
      background-color: transparent;
      color: $modern-primary-hover;
      border-bottom-color: transparent;
      border-radius: $modern-radius;
      outline: 1px solid $modern-primary-outline;
      outline-offset: 1px;

      .modern-badge:not([class*=" modern-badge-"]) {
        background-color: $modern-primary-hover;
      }
    }

    &.active {
      > a {
        padding: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom: 2px solid $modern-primary;
        color: $modern-text-black;

        .modern-badge:not([class*=" modern-badge-"]) {
          background-color: $modern-text-black;
        }

        &:focus-visible {
          border-bottom-color: transparent;
        }
      }

      &.disabled, &:disabled, &[disabled], &[disabled='disabled'] > a {
        border-bottom-color: transparent;
      }
    }

    &.dropdown.open {
      a {
        background: none;
      }
    }

    &.disabled, &:disabled, &[disabled], &[disabled='disabled'] {
      cursor: not-allowed;

      &:hover a {
        border-bottom-color: transparent;
      }

      > a {
        pointer-events: none;
        color: $modern-disable;

        &:focus {
          outline: none;
        }

        .modern-badge:not([class*=" modern-badge-"]) {
          background-color: $modern-disable;
        }
      }
    }
  }

  .dropdown-menu {
    border-radius: $modern-radius;
    padding: $modern-gap-sm;

    li {
      position: relative;

      a {
        padding: $modern-gap-sm;
        font-size: $modern-font-md;
        font-weight: $font-regular;
        line-height: $modern-line-height;
        text-transform: none;
        color: $modern-text-black;

        .modern-badge:not([class*=" modern-badge-"]) {
          background-color: $modern-text-black;
        }

        @media (max-width: $screen-mobile-max) {
          i {
            font-size: $modern-font-xs;
            line-height: $modern-font-xs;
          }
        }
      }

      &.active {
        a {
          background-color: transparent;
          color: $modern-primary;

          .modern-badge:not([class*=" modern-badge-"]) {
            background-color: $modern-primary;
          }
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 10px;
          width: $modern-line-height;
          height: $modern-line-height;
          background-image: url('/static/img/root/selected-arrow.png');
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.shp-tabs {
  font-family: $modern-font-family;
}

.nav.nav-tabs.modern-nav-tabs.panel-tabs {
  &:after {
    background: $modern-disable;
  }

  li > a {
    font-size: $modern-font-md;
    font-weight: $font-demi;
    line-height: $modern-line-height;
    letter-spacing: 0;
    text-align: left;
    color: $modern-text-black;

    &:hover {
      color: $modern-primary-hover;

      .modern-badge:not([class*=" modern-badge-"]) {
        background-color: $modern-primary-hover;
      }
    }

    .modern-badge:not([class*=" modern-badge-"]) {
      background-color: $modern-text-black;
    }

    @media (max-width: $screen-mobile-max) {
      font-size: $modern-font-xs;
      font-weight: $font-regular;
      line-height: $modern-line-height-condensed;
    }

    i {
      font-size: $modern-font-h4;
      line-height: $modern-font-h4;
    }

    @media (max-width: $screen-mobile-max) {
      .tab-title {
        flex-direction: column;
      }
    }
  }

  li.active > a {
    color: $modern-primary;

    .modern-badge:not([class*=" modern-badge-"]) {
      background-color: $modern-primary;
    }
  }
}

.nav.nav-tabs.modern-nav-tabs-regular {
  display: flex;
  flex-wrap: wrap;
  column-gap: $modern-gap-md;
  row-gap: $modern-gap;
  border: none;
  font-family: "DIN 2014 Rounded", Arial, sans-serif;

  &:before, &:after {
    content: none;
  }

  > li, li.open {
    display: flex;
    align-items: center;
    margin: 0;

    > a {
      padding: $modern-gap-xs $modern-gap-condensed $modern-gap-xs $modern-gap-condensed;
      margin: 0;
      font-size: $modern-font-md;
      font-weight: $font-demi;
      line-height: $modern-line-height;
      letter-spacing: 0;
      text-align: left;
      background-color: $modern-primary-background;
      border: none;
      border-radius: $modern-radius-xxl;
      color: $modern-primary;
    }

    &:hover, &.hover {
      > a {
        background-color: $modern-primary-background;
        color: $modern-primary-hover;
      }
    }

    > a {
      &:focus-visible, &.focus {
        outline: 1px solid $modern-primary-outline;
        outline-offset: 1px;
        color: $modern-primary-hover;
        background-color: $modern-primary-background;
      }
    }

    &.active {
      > a {
        background-color: $modern-primary;
        color: $modern-text-white;
      }
    }

    &.disabled, &:disabled, &[disabled], &[disabled='disabled'] {
      cursor: not-allowed;

      > a {
        background-color: $modern-primary-background;
        color: $modern-primary-disable;
        pointer-events: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  & + .tab-content {
    padding: 0;
  }

  &__transparent {
    > li, li.open {
      > a {
        background-color: transparent;
        border: 1px solid $modern-select-gray-soft;
        color: $modern-text-black;
      }
    }
  }
}

.mobile-navigation-guideline-container {
  width: $screen-mobile-min;

  .nav.nav-tabs.modern-nav-tabs {
    column-gap: $modern-gap;
    row-gap: $modern-gap-xs;

    > li, li.open {
      > a {
        min-height: 20px;
        font-size: $modern-font-sm;
        line-height: $modern-line-height-condensed;
        letter-spacing: 1px;

        i {
          font-size: $modern-font-xs;
          line-height: $modern-font-xs;
        }
      }
    }

    .dropdown-menu li a {
      font-size: $modern-font-xs;
      line-height: $modern-font-xs;
    }

    &.panel-tabs li > a {
      font-size: $modern-font-xs;
      font-weight: $font-regular;
      line-height: $modern-line-height-condensed;

      i {
        font-size: $modern-font-h4;
        line-height: $modern-font-h4;
      }

      .tab-title {
        flex-direction: column;
      }
    }
  }
}
