@use "sass:math";

.well {
  background-color: #fff;
  padding: $line-padding;
}

.well.well-flat {
    box-shadow: none;
}

.well.well-small-shadow {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);;
}

.well.well-small {
    padding: $line-padding * 0.75 math.div($line-padding, 2);
}

.form-actions.well {
  padding-top: $line-padding * 0.75;
  padding-bottom: $line-padding * 0.75;
}
