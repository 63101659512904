.questions-list .scroll-item {
  margin-bottom: 10px;
}

.btn-question-create {
  padding: 7px 13px;
  vertical-align: top;
}

.testing-jumbotron {
  padding: 55px 15px 30px 15px;
}

.testing_panel__display_list,
.testing_panel__display_list .panel-body,
.testing_panel__display_list .panel,
.testing_panel__display_list .panel .panel-body,
.testing_panel__display_list .panel .panel-heading {
  padding: 0;
  border: none;
  box-shadow: none;
  margin: 0;
}

.testing_panel__display_list .panel .panel-heading {
  padding: 16px;
  padding-bottom: 8px;
}

.testing_panel__display_list .panel .panel-body {
  padding: 0 16px;
  padding-bottom: 32px;
}

.testing_detail__testing_card {
  max-width: 1200px
}

.modal-no-body .modal-body {
  padding: 0;
}

.list-group.list-group-square .list-group-item {
  border-left-width: 0;
  border-right-width: 0;
}

.list-group.list-group-square > :first-child {
  border-top-width: 0;
}

.testing_setting_info__item {
  padding: $line-padding 0;
  margin-right: $line-padding;
}

@media (min-width: 1200px) {
  .testing_setting_info__item {
    margin-right: 70px;
  }
}
.question__content {
  font-size: $font-lg;

  .field_pretty_radio__text {
    line-height: $modern-line-height;
  }

  p {
    margin: 0;
  }
}
