@use "sass:math";

.vs__dropdown-menu {
  z-index: 5000 !important;
}


.shp__vs {
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: $line-height-base;

  input::placeholder {
    color: #999;
    opacity: 1;
  }

  .vs__search::placeholder, .vs__dropdown-menu {
    color: $placeholder-color;
    cursor: pointer;
  }

  .vs__dropdown-option {
    padding: 4px 8px;

  }
  .vs__dropdown-toggle {
    border-radius: 2px;
    border: 1px solid $input-border-color;
    padding: 2px 12px 2px 12px;
    cursor: pointer;
  }

  .vs__actions {
    padding: 0;
    cursor: pointer;
  }

  .vs__clear, .vs__open-indicator {
    fill: $placeholder-color;
    cursor: pointer;
  }

  .vs--single {
    .vs__dropdown-toggle {
      padding: 6px 12px 6px 12px;
      min-height: 20px;
    }

    .vs__selected, .vs__search, .vs__search:focus, .vs--searchable {
      margin: 0;
      border: none;
    }
  }
  .vs__selected {
    margin: math.div($line-padding, 4) math.div($line-padding, 4) math.div($line-padding, 4) 0;
    border: none;
  }
  .vs__selected-options, .vs--searchable {
    cursor: pointer;
    color: $text-color;
  }

  .vs__search, .vs__search:focus, {
    cursor: pointer;
    color: $text-color;
    line-height: $line-height-base;
    border: none;
  }
  .vs-search, .vs__search:focus, .vs__search, .vs__selected {
    line-height: $line-height-base;
  }

  .vs--disabled  {
    .vs__dropdown-toggle, .vs__clear, .vs__search, .vs__open-indicator {
      background-color: #e0e6e9;
    }
  }

  .vs--single {
    &.vs--open .vs__selected {
      position: relative;
    }
  }
}

.select-chevron {
  width: $line-padding;
  height: $line-padding;

  transform: rotate(0deg);
  transition: transform 0.5s;

  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGdJREFUOI3tzjsOwjAURNGDUqSgTxU5K2AVrJtswjUsgHSR0qdxAZZFPrS+3ZvRzBsqf9MUtBtazJk+oMe0VTriiZCFX8nbpENMgfARjsn74vKj5IFruhfc8d6zIF9S/Hyk5HS4spMVeFcOjszaOwMAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
}
