@use "sass:math";

$loader-size: 50px;

@mixin shp-loader-color-schema($background-color, $circle-color) {
  .background {
    color: $background-color;
  }

  .circle {
    color: $circle-color;
  }
}

@mixin shp-loader-size-schema($size) {
  .shp-loader__container {
    width: auto;
    height: $size;
  }
}

.shp-loader {
  svg {
    -webkit-animation: svg-animation 2s linear infinite;
    animation: svg-animation 2s linear infinite;
  }

  .background {
    fill: currentColor;
  }

  .circle {
    -webkit-animation: circle-animation 1.8s ease-in-out infinite both;
    animation: circle-animation 1.8s ease-in-out infinite both;
    display: block;
    fill: transparent;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-dasharray: 144.5132642;
    stroke-width: 25;
    transform-origin: 50% 50%;
  }

  @keyframes svg-animation {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(1turn);
    }
  }

  @keyframes circle-animation {
    0%, 25% {
      stroke-dashoffset: 140.177866274;
      transform: rotate(0);
    }

    65% {
      stroke-dashoffset: 0;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 140.177866274;
      transform: rotate(1turn);
    }
  }

  @include shp-loader-color-schema($modern-primary, $modern-text-white);

  @include shp-loader-size-schema($loader-size);
}

$shp-loader-color-variants: (
  "success": $modern-success $modern-text-white,
  "error": $modern-error $modern-text-white,
  "warning": $modern-warning $modern-text-white,
  "amazing": $modern-amazing $modern-text-white,
);

@each $name, $colors in $shp-loader-color-variants {
  .shp-loader-#{$name} {
    @include shp-loader-color-schema(nth($colors, 1), nth($colors, 2));
  }
}

$shp-loader-size-variants: (
  "xs": math.div($loader-size, 4),
  "sm": math.div($loader-size, 2),
  "md": math.div($loader-size * 3, 2),
  "lg": $loader-size * 2,
  "xl": $loader-size * 4,
);

@each $name, $size in $shp-loader-size-variants {
  .shp-loader-#{$name} {
    @include shp-loader-size-schema($size);
  }
}
