.menu-badge {
  display: inline-block;
}

.mini-navbar .metismenu .menu-badge {
  //display: block;
  position: absolute;
  right: 15px;
  top: 8px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  overflow: hidden;
  padding: 4px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
}
