//text-muted more-info m-l inline-block pull-right

.code-mirror__hint {
  font-style: italic;
  margin-left: $line-padding;
  display: inline-block;
  text-align: right;
  color: #777
}

.CodeMirror-hint-active .code-mirror__hint {
  color: $text-color-white
}

.CodeMirror-autosize .CodeMirror {
  height: auto;
}

.vue-codemirror-wrap {
  .CodeMirror-gutters {
    background: transparent;
    border: none;
  }

  .CodeMirror-code {
    font-family: 'Roboto Mono', Arial, sans-serif;
    font-size: 16px;
  }

  .CodeMirror-linenumber {
    color: $modern-subtext;
    background: $modern-panel-layer;
  }

  .CodeMirror-gutter-background {
    top: 2px;
    z-index: 5;
  }
}

