@mixin modern-shp-checkbox-variant($default, $hover, $disabled, $border, $disabled-border) {
  border: 2px solid $border;

  &:hover, &.shp-checkbox-hover {
    border-color: $hover;
  }

  &.shp-checkbox-disabled, &[disabled], &:disabled {
    &, &:hover, &.shp-checkbox-hover {
      border-color: $disabled-border;
    }
  }

  &.shp-checkbox_checked {
    border-color: $default;
    background-color: $default;

    &.shp-checkbox-disabled, &[disabled], &:disabled {
      &, &:hover, &.shp-checkbox-hover {
        border-color: $disabled;
        background-color: $disabled;
        cursor: not-allowed;
      }
    }

    &:hover, &.shp-checkbox-hover {
      border-color: $hover;
      background-color: $hover;
    }
  }

  &.shp-checkbox_indeterminate {
    background-color: $default;
    border-color: $default;

    &:hover, &.shp-checkbox-hover {
      border-color: $hover;
      background-color: $hover;
    }
  }
}

.modern-shp-checkbox,
.modern-page .shp-checkbox {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: $modern-checkbox-size;
  height: $modern-checkbox-size;
  margin: 0;
  border-radius: $modern-radius-xs;
  background: transparent;
  user-select: none;
  line-height: $modern-line-height-condensed;
  cursor: pointer;
  overflow: hidden;

  .shp-checkbox__icon {
    position: absolute;
    top: 4px;
    left: 4px;
    display: none;
    font-size: $modern-font-xxs;
    line-height: $modern-font-xxs;
    color: $modern-layer;
  }

  &:focus, &.shp-checkbox-focus, &.shp-checkbox-pressed {
    outline: 1px solid $modern-primary-outline;
    outline-offset: 1px;
  }

  &.shp-checkbox-disabled, &[disabled], &:disabled {
    &, &:hover, &.shp-checkbox-hover {
      background-color: $modern-layer;
      cursor: not-allowed;
    }
  }

  &.shp-checkbox_checked,
  &.shp-checkbox_indeterminate {
    .shp-checkbox__icon {
      display: inline-block;
    }
  }

  @include modern-shp-checkbox-variant(
    $modern-primary,
    $modern-primary-hover,
    $modern-primary-disable,
    $modern-line,
    $modern-disable
  );

  &.shp-checkbox-danger {
    @include modern-shp-checkbox-variant(
      $modern-error,
      $modern-error-hover,
      $modern-error-disable,
      $modern-error,
      $modern-error-disable
    );
  }

  &.shp-checkbox-success {
    @include modern-shp-checkbox-variant(
      $modern-success,
      $modern-success-hover,
      $modern-success-disable,
      $modern-success,
      $modern-success-disable
    );
  }

  &.shp-checkbox-warning {
    @include modern-shp-checkbox-variant(
      $modern-warning,
      $modern-warning-hover,
      $modern-warning-disable,
      $modern-warning,
      $modern-warning-disable
    );
  }

  &.shp-checkbox-amazing {
    @include modern-shp-checkbox-variant(
      $modern-amazing,
      $modern-amazing-hover,
      $modern-amazing-disable,
      $modern-amazing,
      $modern-amazing-disable
    );
  }
}

.modern-page .shp-checkbox:not(.modern-shp-checkbox) .shp-checkbox__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modern-page {
  .shp_checklist__list.shp_checklist__horizontal {
    padding: 0;

    .shp_checklist__row {
      display: flex;
      gap: $modern-gap-sm
    }
  }

  .dropdown-menu {
    label.shp-checkbox {
      display: inline-flex;
    }
  }

  .shp_checkbox__wrapper-inline {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: $modern-gap-sm;

    label.shp-checkbox, label.formulate-input-label  {
      margin: 0;
    }
  }
}
