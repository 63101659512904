.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: $disabled-input-color;
    opacity: 1;
}

.non_editable_div {
    border-radius: 3px;
    background-color: $disabled-input-color;
    color: #555555;
    min-height: 34px;
    overflow-wrap: break-word;
}

.has-focus {
  border-color: rgba(82,168,236,.8);
  outline: 0;
  box-shadow: 0 0 8px rgba(82,168,236,.6);
}


input[type='checkbox'].simple-checkbox {
    margin-bottom: 2px;
    margin-top: 0;
    margin-right: 0;
}

select.input-mini, .select2-container.input-mini, .non_editable_div.input-mini, .input-mini, .input-group .input-mini {
  width: 60px;
}

select.input-small, .select2-container.input-large, .non_editable_div.input-large, .input-small, .input-group .input-small {
  width: 90px;
}

select.input-medium, .select2-container.input-medium, .non_editable_div.input-medium, .input-medium, .input-group .input-medium {
  width: 150px;
}

select.input-large, .select2-container.input-large, .non_editable_div.input-large, .input-large, .input-group .input-large {
  width: 210px;
}

select.input-xlarge, .select2-container.input-xlarge, .non_editable_div.input-xlarge, .input-xlarge, .input-group .input-xlarge {
  width: 270px;
}

select.input-xxlarge, .select2-container.input-xxlarge, .non_editable_div.input-xxlarge, .input-xxlarge, .input-group .input-xxlarge {
  width: 530px;
}


select.shp-input-xs, .select2-container.shp-input-xs, .non_editable_div.shp-input-xs, .shp-input-xs, .input-group .shp-input-xs {
  width: 120px;
}

.shp-input-xs .flex-grow-8 {
  width: 80px;
}

select.shp-input-sm, .select2-container.shp-input-sm, .non_editable_div.shp-input-sm, .shp-input-sm, .input-group .shp-input-sm {
  width: 170px;
}

select.shp-input-md, .select2-container.shp-input-md, .non_editable_div.shp-input-md, .shp-input-md, .input-group .shp-input-md {
  width: 220px;
}

select.shp-input-lg, .select2-container.shp-input-lg, .non_editable_div.shp-input-lg, .shp-input-lg, .input-group .shp-input-lg {
  width: 280px;
}

select.shp-input-xlg, .select2-container.shp-input-xlg, .non_editable_div.shp-input-xlg, .shp-input-xlg, .input-group .shp-input-xlg {
  width: 340px;
}

select.shp-input-row, .select2-container.shp-input-row, .non_editable_div.shp-input-row, .shp-input-row, .input-group .shp-input-row {
  width: 100%;
}

.input-group-addon.btn-icon, .input-group-addon.btn {
    width: 38px;
    max-width: 38px;
}

.input-group-addon.input-group-invisible {
    padding: 0;
    border-left: 0;
    width: 0
}

.inline_input {
    margin-bottom: 0;
    border-radius: 4px 4px 4px 4px !important;
}

input[type="radio"].table-checkbox, input[type="checkbox"].table-checkbox {
    margin-top: 0;
    margin-bottom: 4px;
    margin-right: 16px;
}

input[type="radio"] ~ label {
    margin-left: 10px;
    z-index: 10;
}

.field_input2__copy_icon {
  padding: 3px;
  border: 2px solid $input-border-color
}

.field_input2__copy_icon > i.icon_copy:before {
  width: 18px;
  height: 19px;
}

.field_input2__copy_icon_container {
  left: -25px;
  background-color: #ffffff
}

.time-input {
  border: none;
  box-shadow: none;
  width: 48px;
  padding: 4px;
}

.code-link-input .formulate-input-errors {
  list-style: none;
}
