@mixin shp-donut-variant($color) {
  border-color: $color;
}

@mixin progress-bar($barColor: false, $bg: false) {
  @if $bg and $barColor {
    .progress-donut__bg {
      stroke: $bg;
    }

    .progress-donut__bar {
      stroke: $barColor;
    }
  } @else {
    .progress-donut__bg {
      stroke: $modern-line;
    }
    .progress-donut__bar {
      stroke: $progress-donut-red;

      &.progress-donut__bar--red {
        stroke: $progress-donut-red;
      }

      &.progress-donut__bar--orange {
        stroke: $progress-donut-orange;
      }

      &.progress-donut__bar--green {
        stroke: $progress-donut-green;
      }
    }
  }
}

.shp-donut {
  border: 6px solid;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  font-size: 18px;
  background-color: #fff;
  width: 18px;
  height: 18px;
  vertical-align: top;
  position: relative;
  margin-right: 4px;
}

.shp-donut-danger {
  @include shp-donut-variant($red)
}

.shp-donut-warning {
  @include shp-donut-variant($yellow)
}

.shp-donut-info {
  @include shp-donut-variant($lazur)
}

.shp-donut-success {
  @include shp-donut-variant($blue)
}

.shp-donut-amazing {
  @include shp-donut-variant($amazing)
}

.shp-donut-default {
  @include shp-donut-variant($dark-gray)
}

.shp-donut-thin {
  border-width: 1px;
}

.progress-donut {
  display: block;
  border-radius: 100%;
  position: relative;

  &__svg {
    transform: rotate(-90deg);
  }

  &__bar {
    stroke: #FF9F1E;
  }

  &__text-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__text {
    font-size: $modern-font-xs;
    font-weight: $font-regular;
    line-height: $modern-line-height-condensed;
    letter-spacing: 0;
    text-align: center;
    color: $modern-subtext;
  }

  &__progress {
    font-size: $modern-font-md;
    font-weight: $font-demi;
    line-height: $modern-line-height;
    letter-spacing: 0;
    text-align: center;
    color: $modern-text-black;
  }

  &.progress-donut__small {
    .progress-donut__progress {
      font-size: $modern-font-xs;
      font-weight: $font-regular;
      line-height: $modern-line-height-condensed;
    }

    .progress-donut__text {
      display: none;
    }
  }


  &.progress-donut__big {
    .progress-donut__progress {
      font-size: $modern-font-h3;
      font-weight: $font-demi;
      line-height: $modern-line-height-h3;
    }

    .progress-donut__text {
      font-size: $modern-font-sm;
      font-weight: $font-demi;
      line-height: $modern-line-height-condensed;
    }
  }

  @include progress-bar();

  &.progress-donut__primary {
    @include progress-bar($progress-donut-blue, $progress-donut-blue-light);
  }

  &.progress-donut__amazing {
    @include progress-bar($progress-donut-amazing, $progress-donut-amazing-light);
  }

  &.progress-donut__success {
    @include progress-bar($progress-donut-green, $progress-donut-green-light);
  }
}
