/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left, .modal.right {
  .modal-header {
    width: 100%
  }
}

.modal.left, .modal.right {
  .modal-dialog {
    padding: 0;
    //min-height: 100%;
    //max-height: 100%;
    //height: 100%;
  }
  .modal-header {
    display: table;
  }
  .modal-content {
    border-radius: 0;
    border: none;
    display: flex;
    flex-direction: column !important;
  }

  .modal-body {
    flex: 1 0 auto;
    overflow-y: auto;
  }
}
.modal.left .modal-body,
.modal.right .modal-body {
    //padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
         -o-transition: opacity 0.3s linear, left 0.3s ease-out;
            transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
    left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.in .modal-dialog {
    right: 0;
}
