h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

//h3 {
//  font-size: 16px;
//}

//h4 {
//  font-size: 14px;
//}

//h5 {
//  font-size: 12px;
//}
//
//h6 {
//  font-size: 10px;
//}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}

.font-sm {
  font-size: $font-sm;
}

.font-md {
  font-size: $font-md;
}

.font-lg {
  font-size: $font-lg;
}

.font-xl {
  font-size: $font-xl;
}

.font-xxl {
  font-size: $font-xxl;
}

.font-4xl {
  font-size: $font-xxl + 4;
}

.font-xxxl {
  font-size: $font-3xl;
}

.font-xxxxl {
  font-size: $font-4xl;
}

.modern-page {
  .text-danger {
    color: $modern-error;
  }

  .text-info {
    color: $modern-primary;
  }
}
