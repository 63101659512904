.yandex-placemark {
  background-image: url('/static/img/registration/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 34px;
  height: 68px;
  margin-left: -17px;
  margin-top: -45px;
  transition: all 0.5s;
  position: relative;
}

.yandex-placemark_disabled {
  background-image: url('/static/img/registration/logo_disabled.svg');
}

.yandex-placemark_active {
  transform: scale(1.5);
  margin-top: -55px;
  /*transition: 0.5s;*/
  z-index: 9000;
}

@keyframes show-big-placemark {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
    margin-top: -55px;
  }
}

@keyframes show-small-placemark {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.schools__nav-list {
  height: 480px;
  overflow: auto;
  margin-bottom: 32px;
}