$modern-checkbox-size: 18px;
$modern-radio-button-size: 18px;
$modern-radio-button-icon-size: $modern-radio-button-size - 8;

@mixin modern-shp-radiobutton-variant($default, $hover, $disabled, $border, $disabled-border) {
  border: 2px solid $border;

  &:hover, &.shp-radio-hover {
    border-color: $hover;
  }

  &:disabled, &[disabled], &.disabled, &.shp-radio-disabled {
    border-color: $disabled-border;

    &:hover, &.shp-radio-hover {
      border-color: $disabled-border;
    }
  }

  &.shp-radio_selected {
    border: 2px solid $default;

    .shp-radio__icon {
      background: $default;
    }

    &:hover, &.shp-radio-hover {
      border-color: $hover;

      .shp-radio__icon {
        background: $hover;
      }
    }

    &:disabled, &.disabled, &[disabled], &.shp-radio-disabled {
      border-color: $disabled;

      .shp-radio__icon {
        background: $disabled;
      }
    }
  }
}

.modern-page .shp-radio {
  height: $modern-radio-button-size;
  min-width: $modern-radio-button-size;
  background: $modern-layer;

  &:focus, &.shp-radio-focus, &.shp-radio-pressed {
    outline: 1px solid $modern-primary-outline;
    outline-offset: 1px;
  }

  &.shp-radio_selected .shp-radio__icon {
    height: $modern-radio-button-icon-size;
    width: $modern-radio-button-icon-size;
  }

  @include modern-shp-radiobutton-variant(
    $modern-primary,
    $modern-primary-hover,
    $modern-primary-disable,
    $modern-line,
    $modern-disable
  );

  &.shp-radio-success {
    @include modern-shp-radiobutton-variant(
      $modern-success,
      $modern-success-hover,
      $modern-success-disable,
      $modern-success,
      $modern-success-disable
    );
  }

  &.shp-radio-danger {
    @include modern-shp-radiobutton-variant(
      $modern-error,
      $modern-error-hover,
      $modern-error-disable,
      $modern-error,
      $modern-error-disable
    );
  }

  &.shp-radio-warning {
    @include modern-shp-radiobutton-variant(
      $modern-warning,
      $modern-warning-hover,
      $modern-warning-disable,
      $modern-warning,
      $modern-warning-disable
    );
  }

  &.shp-radio-amazing {
    @include modern-shp-radiobutton-variant(
      $modern-amazing,
      $modern-amazing-hover,
      $modern-amazing-disable,
      $modern-amazing,
      $modern-amazing-disable
    );
  }
}

.shp_checklist {
  &.shp_checklist__modern {
    .shp_checklist__list {
      padding: 0;
    }

    .shp_checklist__row {
      display: flex;
      align-items: center;
      gap: $modern-gap;

      &:not(:first-child) {
        margin-top: $modern-gap;
      }
    }

    .shp_checklist__sketchy {
      display: flex;
      flex-direction: column;
      gap: $modern-gap-sm;
      padding: 0;

      .shp_checklist__row {
        margin: 0;
        padding: $modern-gap;
        border-radius: $modern-radius;
        border: 1px solid $modern-grey-soft-background;
        background-color: $modern-layer;
        width: 100%;

        &.active {
          border: 1px solid $modern-primary;
          background-color: $modern-primary-background;
        }
      }

      &.shp_checklist__horizontal .shp_checklist__row {
        min-width: 120px;
        width: auto;
      }
    }

    .shp_checklist__horizontal {
      label {
        margin-right: $modern-gap-md;
      }

      .shp_checklist__row {
        margin-top: 0;
      }
    }

    .shp_checklist__left-label {
      .shp-radio {
        order: 1
      }
    }

    label {
      margin: 0;
    }

    .shp-radio {
      margin: 0;
    }

    .shp_checklist__label {
      font-size: $modern-font-md;
      font-weight: $font-regular;
      line-height: $modern-line-height;
      letter-spacing: 0;
    }
  }
}

.shp_checklist .shp_checklist__radio-placement {
  display: flex;
  align-items: center;
  gap: $modern-gap-sm;
  border-radius: $modern-radius-lg;
  background-color: $modern-primary-subpanel-soft-background;
  padding: $modern-gap-md;
  cursor: pointer;
}
