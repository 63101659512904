@mixin discussion-button-outline-mixin(
  $color,
  $color-hover,
  $active-background: $color,
  $active-border: $color,
  $active-color: $color-hover
) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba($color, .5);
  }

  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
        box-shadow: 0 0 0 2px rgba($color, .5);
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin date_location_card($name) {
  .#{$name}-card {
    border-radius: $modern-radius;
    padding: $modern-gap;
    background-color: $modern-grey-soft-background;

    &.#{$name}-card_actual {
      border: 1px solid $modern-success;
      background-color: $modern-layer;
    }

    &.#{$name}-card_blanked {
      border-radius: 0;
      padding: 0;
      background-color: transparent;
    }

    .#{$name}-card__info {
      display: flex;
      justify-content: space-between;
      margin-bottom: $modern-gap;
      gap: $modern-gap-sm
    }

    .#{$name}-card__main-info,
    .#{$name}-card__additional-info {
      display: flex;
      flex-direction: column;
      gap: $modern-gap-sm
    }

    .#{$name}-card__additional-info {
      align-items: end;
    }

    @media (max-width: $screen-mobile-max) {
      .#{$name}-card__info {
        flex-direction: column;
      }

      .#{$name}-card__additional-info {
        align-items: start;
      }
    }
  }
}

$modern-scrollbar-width: 6px;

@mixin modern-scrollbar($margin-y: 0) {
  &::-webkit-scrollbar {
    width: $modern-scrollbar-width;
    height: $modern-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    margin: $margin-y 0;
    border-radius: $modern-radius;
    background-color: inherit;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $modern-radius;
    background-color: $modern-scrollbar-thumb-color;
  }
}

@mixin modern-invisible-scrollbar() {
  @include modern-scrollbar();

  @media (min-width: $screen-desktop-min) {
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $modern-scrollbar-thumb-color;
      }
    }
  }
}
