@use "sass:color";

$modern-transparent-colors-layout: #fff;

// Accent
$modern-grey: color.mix($modern-transparent-colors-layout, #696974, 85%);
$modern-grey-soft: #F1F1F5;
$modern-orange: #FF974A;
$modern-zircon-white: #E5EFFF;

// Elements
$modern-text-black: #44444F;
$modern-text-white: #FFFFFF;
$modern-subtext: #696974;
$modern-line: #C4C4D4;
$modern-disable: #E4E4E7;
$modern-placeholder: #B5B5BE;
$modern-outline-hover: #5A5A72;
$modern-select-gray-soft: #F7F7F8;

// Primary
$modern-primary: #0062FF;
$modern-primary-hover: color.mix($modern-transparent-colors-layout, #0062FF, 25%);
$modern-primary-pressed: #004ECC;
$modern-primary-disable: color.mix($modern-transparent-colors-layout, #0062FF, 60%);
$modern-primary-background: color.mix($modern-transparent-colors-layout, #0062FF, 85%);
$modern-primary-outline: $modern-primary;

// Success
$modern-success: #82C43C;
$modern-success-hover: color.mix($modern-transparent-colors-layout, #82C43C, 25%);
$modern-success-pressed: #689D2F;
$modern-success-disable: color.mix($modern-transparent-colors-layout, #82C43C, 60%);
$modern-success-background: color.mix($modern-transparent-colors-layout, #82C43C, 85%);

// Error
$modern-error: #FB3737;
$modern-error-hover: color.mix($modern-transparent-colors-layout, #FB3737, 25%);
$modern-error-pressed: #E10404;
$modern-error-disable: color.mix($modern-transparent-colors-layout, #FB3737, 60%);
$modern-error-background: color.mix($modern-transparent-colors-layout, #FB3737, 85%);

// Warning
$modern-warning: #FFC133;
$modern-warning-hover: color.mix($modern-transparent-colors-layout, #FFC133, 25%);
$modern-warning-pressed: #FFB100;
$modern-warning-disable: color.mix($modern-transparent-colors-layout, #FFC133, 60%);
$modern-warning-background: color.mix($modern-transparent-colors-layout, #FFC133, 85%);

// Amazing
$modern-amazing: #A15BD7;
$modern-amazing-hover: color.mix($modern-transparent-colors-layout, #A15BD7, 25%);
$modern-amazing-pressed: #8932CD;
$modern-amazing-disable: color.mix($modern-transparent-colors-layout, #A15BD7, 60%);
$modern-amazing-background: color.mix($modern-transparent-colors-layout, #A15BD7, 85%);

// Backgrounds
$modern-layer: #FFFFFF;
$modern-background: #F7F7F7;
$modern-black-background: $modern-text-black;
$modern-grey-background: $modern-line;
$modern-grey-soft-background: $modern-grey-soft;
$modern-primary-dark-background: $modern-primary-pressed;
$modern-primary-soft-background: #3381FF;
$modern-primary-subpanel-dark-background: color.mix($modern-transparent-colors-layout, #0062FF, 75%);
$modern-primary-subpanel-background: $modern-primary-background;
$modern-primary-subpanel-soft-background: color.mix($modern-transparent-colors-layout, #0062FF, 95%);
$modern-primary-select-soft-background:#F0F6FF;
$modern-error-soft-background: $modern-error-background;
$modern-success-soft-background: $modern-success-background;
$modern-amazing-soft-background: $modern-amazing-background;
$modern-warning-soft-background: $modern-warning-background;
$modern-yellow-background: color.mix($modern-transparent-colors-layout, #FFB100, 85%);
$modern-orange-background: color.mix($modern-transparent-colors-layout, #FF974A, 85%);

// Scrollbar
$modern-scrollbar-thumb-color: $modern-subtext;

// Figures (Lobby)
$modern-figure-black: #191919;
$modern-figure-blue: #0000FF;
$modern-figure-green: #008000;
$modern-figure-yellow: #FFCF40;
$modern-figure-red: #FF0000;
$modern-figure-violet: #9F00FF;
$modern-figure-orange: #FF6600;
$modern-figure-grey: #E4E4E7;

// Gradients
$modern-gradient-rgb: linear-gradient(270deg, #12AE45 0%, #FFC53A 28.65%, #FF7006 67.19%, #FF3532 100%);

// Tags

$modern-tag-grey: #E2E2E9;
$modern-tag-blue: #CCE0FF;
$modern-tag-green: #E6F3D8;
$modern-tag-yellow: #FFEFCC;
$modern-tag-red: #FECDCD;
$modern-tag-orange: #FFE2CC;
$modern-tag-violet: #E7D6F5;

$modern-tag-grey-hover: #C6C6D2;
$modern-tag-blue-hover: #99C0FF;
$modern-tag-green-hover: #CDE7B1;
$modern-tag-yellow-hover: #FFE099;
$modern-tag-red-hover: #FD9B9B;
$modern-tag-orange-hover: #FFC599;
$modern-tag-violet-hover: #D0ADEB;

$modern-tag-grey-dark: #434356;
$modern-tag-blue-dark: #003B99;
$modern-tag-green-dark: #689D2F;
$modern-tag-yellow-dark: #FFB100;
$modern-tag-red-dark: #960303;
$modern-tag-orange-dark: #FF6D00;
$modern-tag-violet-dark: #6E28A4;
