$first-level-menu-item-width: 90px;
$navigation-user-name-max-width: 120px;
$navigation-mobile-menu-height: 52px;
$mobile-menu-font-size: 9px;
$admin-navigation-sidebar-width: $modern-gap * 14;
$navigation-second-level-content-width: 872px;

$navigation-menu-height: 56px;
$mobile-menu-active-marker-height: 3px;
$first-level-menu-item-height: 56px;
$mobile-navigation-header-height: 90px;
$mobile-navigation-close-height: 41px;
$mobile-navigation-height: calc(var(--window-height) - #{$modern-gap + $mobile-navigation-header-height + $mobile-navigation-close-height});

$modern-navigation-background: $modern-layer;
$modern-navigation-border-color: $modern-grey-soft;
$modern-navigation-header-separate-line-color: $modern-background;

$modern-navigation-item-color: $modern-subtext;
$modern-navigation-item-active-color: $modern-primary;
$modern-navigation-item-hover-color: $modern-primary-hover;

$top-nav-bar-content-border-width: 0.5px;
$modern-nav-bar-height: 40px + $top-nav-bar-content-border-width;
$modern-nav-bar-height-mobile: $modern-gap-xl;
$modern-navigation-border-bottom: 1px;

$modern-navigation-desktop-content-height: $modern-nav-bar-height + $navigation-menu-height + $modern-navigation-border-bottom;
$modern-navigation-desktop-content-collapsed-height: $navigation-menu-height + $modern-navigation-border-bottom;
$modern-navigation-mobile-content-height: $modern-nav-bar-height-mobile + $modern-navigation-border-bottom + $navigation-mobile-menu-height;

@import './shp/modern_navigation_menu';
@import './shp/modern_classes_navigation';

.full-screen-loader {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $modern-primary-background;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: absolute;
  z-index: 10000;
  transition: opacity .4s ease-out, z-index 50ms linear .4s;

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &.hide-loader {
    min-height: auto;
    z-index: -1;
    opacity: 0;
  }
}

.mobile-navigation-modal {
  .modal-dialog {
    position: absolute;
    margin: 0;
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  .modal-body {
    height: $mobile-navigation-height;
    overflow-x: auto;

    @include modern-scrollbar();
  }

  .modal-footer {
    padding: $modern-gap-sm $modern-gap;
    border-top: 1px solid $modern-grey-background;
    @media (max-width: $screen-tablet-max) {
      max-height: $mobile-navigation-close-height;
    }
  }

  .modal-content {
    border: none;
  }

  &.full-height {
    .modal-dialog {
      top: 0;
    }

    .modal-body {
      height: unset;
    }

    .modal-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .modal-footer {
      margin-top: auto;
    }
  }

  .menu-items {
    > .menu-item:not(:last-child) {
      margin-bottom: $modern-gap;
    }
  }

  .menu-item {
    border-bottom: 1px solid $modern-navigation-header-separate-line-color;
    padding: $modern-gap-sm 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__main {
      font-weight: $font-bold;
      font-size: $modern-font-h4;
      line-height: $modern-line-height-h4;
      color: $modern-navigation-item-color;
    }

    &.active {
      &:before {
        content: '';
        border-radius: $modern-gap-sm;
        background-color: $modern-primary;
        height: $modern-gap-sm;
        width: $modern-gap-sm;
        margin-right: $modern-gap-sm;
      }
    }
  }
}

@mixin nav-footer-offset($class_name, $min_height) {
  #{$class_name} {
    .modern-navigation {
      .navigation-footer-offset {
        min-height: $min_height;
      }
    }
  }
}

@include nav-footer-offset('.admin-navigation', $modern-nav-bar-height);
@include nav-footer-offset('.client-navigation', $navigation-mobile-menu-height);
@include nav-footer-offset('.classes-navigation', calc(2*#{$modern-gap-condensed} + #{$modern-gap-md}));

.modern-navigation {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &.navigation-open .navigation-header {
    z-index: 10000;
  }

  .navigation-header {
    background-color: $modern-navigation-background;
    padding: 0 $modern-gap-md;
    border-bottom: 1px solid $modern-grey-background;
    position: sticky;
    top: 0;
    z-index: 1000;

    > :last-child {
      border-radius: 0 0 $modern-radius $modern-radius;
    }

    .nav-bar {
      height: $modern-nav-bar-height;
      transition: height 350ms ease;

      &.collapsed {
        height: 0;
        overflow: hidden;
      }
    }

    .navigation-header__button {
      line-height: $modern-line-height-condensed;
      font-size: $modern-font-xs;
      font-weight: $font-regular;
      font-style: normal;
      color: $modern-subtext;
      cursor: pointer;

      &:hover {
        color: $modern-text-black;
      }

      &.navigation-header__help-button {
        padding-right: 0;
      }
    }

    @media (max-width: $screen-tablet-max) {
      .nav-bar.collapsed {
        overflow: unset;
      }
    }
  }

  .navigation-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: $modern-navigation-background;
    border-top: 1px solid $modern-grey-background;

    .menu-item {
      cursor: pointer;
    }

    > :first-child {
      border-radius: $modern-radius $modern-radius 0 0;
    }
  }

  .navigation-header, .navigation-footer {
    flex-shrink: 0;
  }

  .navigation-footer-offset {
    width: 100%;
    background-color: $modern-layer;
  }

  .top-nav-bar {
    &__content {
      height: $modern-nav-bar-height;
      border-bottom: $top-nav-bar-content-border-width solid $modern-navigation-header-separate-line-color;
    }
  }

  .navigation-counter-wrapper {
    position: relative;

    .modern-badge {
      position: absolute;
      z-index: 10;
      top: -$modern-gap-xs - 1;
      left: calc(100% - #{$modern-gap - $modern-gap-xs + 1});
      border: 1px solid $modern-navigation-background;
    }
  }

  .navigation-username {
    font-size: $modern-line-height-small;
    line-height: $modern-line-height-navigation_small;
    text-align: right;
    max-width: $navigation-user-name-max-width;
    max-height: $modern-line-height-navigation_small * 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .third_level_menu__title {
    background-color: unset;
  }

  .content-wrapper {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    overflow-x: auto;
    position: relative;
    @include modern-scrollbar;

    .main-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }

    .static_content_footer {
      padding: $modern-gap-sm $modern-gap;
      border-top: 1px solid $modern-navigation-header-separate-line-color;
      margin-top: auto;
    }

    .admin-navigation-sidebar + .main-content {
      position: relative;
      left: $admin-navigation-sidebar-width;
      max-width: calc(100% - #{$admin-navigation-sidebar-width});
      transition: left 0.3s ease-in-out, max-width 0.3s ease-in-out;

      @media (max-width: $screen-tablet-max) {
        left: 0;
        max-width: 100%;
      }
    }

    .admin-navigation-sidebar {
      width: $admin-navigation-sidebar-width;
      flex-shrink: 0;
      padding: $modern-gap-lg 0 0 $modern-gap-md;
      position: absolute;
      top: 0;
      left: 0;
      transition: left 0.3s ease-in-out, opacity .2s ease-in-out, max-width 0.3s ease-in-out;

      &.hide-to-left-side {
        left: -$admin-navigation-sidebar-width;
        opacity: 0;

        & + .main-content {
          left: 0;
          max-width: 100%;
        }
      }

      hr {
        margin: $modern-gap-lg 0;
        border-top: 1px solid $modern-placeholder;
      }
    }
  }

  &.admin-navigation {
    .navigation-header {
      .navigation-header__button {
        padding: 0;
      }
    }
  }

  .admin-navigation-sidebar-item {
    .menu-item {
      font-size: $modern-font-md;
      line-height: $modern-line-height;
      font-weight: $font-demi;
      color: $modern-navigation-item-color;
      display: flex;

      &__counter {
        margin-left: auto;
      }

      i {
        font-size: $modern-font-xxxs;
        margin-right: $modern-gap-xs;
        color: currentColor;
      }

      &__children {
        padding-left: $modern-gap;
        padding-bottom: $modern-gap-xs;
      }

      &__default {
        padding-top: $modern-gap-xs;
        padding-bottom: $modern-gap-xs;

        i {
          margin-right: $modern-gap-sm;
        }

        &.active {
          color: $modern-navigation-item-active-color;
        }

        &:hover, &.hover {
          color: $modern-navigation-item-hover-color;
        }
      }

      &__main {
        font-size: $modern-font-h4;
        line-height: $modern-line-height;
        font-weight: $font-demi;
        margin-bottom: $modern-gap-sm;

        &:hover, &.hover {
          color: $modern-text-black;
        }
      }

      &__sub_item {
        border-left: 1px solid;
        border-color: $modern-disable;
        padding: $modern-gap-xs 0 $modern-gap-xs $modern-gap-xs;

        i {
          font-size: $modern-font-xxs;
          color: $modern-disable;
        }

        &.active {
          color: $modern-navigation-item-active-color;
          border-color: $modern-navigation-item-active-color;

          i {
            color: $modern-navigation-item-active-color;
          }
        }

        &:hover, &.hover {
          color: $modern-navigation-item-hover-color;
          border-color: $modern-navigation-item-hover-color;

          i {
            color: $modern-navigation-item-hover-color;
          }
        }

        & + .menu-item__children {
          padding-left: 0;
        }
      }

      &__group_title {
        font-size: $modern-font-sm;
        line-height: $modern-line-height-condensed;
        font-weight: $font-bold;
        padding: $modern-gap-sm 0;
        text-transform: uppercase;
        cursor: default;
        pointer-events: none;

        & + .menu-item__children {
          padding-left: 0;
        }
      }

      &__temporary {
        color: $modern-primary;
        border-left: 1px solid $modern-primary;
        padding-left: $modern-gap;
        font-weight: $font-regular;

        i {
          font-weight: $font-demi;
          color: currentColor;
        }
      }
    }

    .menu-item__main ~ .menu-item__children {
      padding-left: 0;
    }
  }

  .admin-navigation-sidebar-sub-menu-item {
    display: block;
    margin-bottom: $modern-gap;
    font-size: $modern-font-md;
    line-height: $modern-line-height;
    color: $modern-navigation-item-color;
  }

  .additional-actions-representative {
    display: flex;
    justify-content: center;
    margin: 0 (-$modern-gap-md);
    padding: $modern-gap-sm $modern-gap-md;
    border-top: 1px solid $modern-grey-background;

    @media (max-width: $screen-tablet-max) {
      margin: 0 (-$modern-gap);
      padding: $modern-gap-sm $modern-gap;
    }
  }

  .additional-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $modern-navigation-background;
    border-top: 1px solid $modern-navigation-border-color;
    line-height: $modern-line-height-condensed;

    .additional-actions__selectors {
      display: flex;
      align-items: center;
      gap: $modern-gap-lg;

      .navigation-header__button {
        padding: 0;
      }
    }
  }

  .admin-privacy-content-footer {
    display: flex;
    justify-content: flex-end;
    color: $yellow;
    font-size: $font-sm;
  }

  #main-page-title {
    font-size: $modern-font-h3;
    line-height: $modern-line-height-h3;
    font-weight: $font-bold;
    margin-top: 0;
    margin-bottom: 0;
    font-family: $modern-font-family;
  }

  @media (max-width: $screen-tablet-max) {
    .navigation-header {
      padding: 0 $modern-gap;

      &.collapsed {
        top: 0;
      }

      .top-nav-bar {
        height: $modern-nav-bar-height-mobile;

        &__content {
          height: $modern-nav-bar-height-mobile;
          border-bottom: none;

          .navigation-header__button {
            padding: 0;
          }
        }
      }
    }
  }
}

.admin-navigation-mobile-footer {
  padding: $modern-gap-sm $modern-gap;
}

.admin-additional-actions {
  padding: $modern-gap-sm 0;
}
