.error-page {
  padding: $gap-md;
}

.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding-top: $modern-gap-lg;

  h4 {
    margin-bottom: $modern-gap-sm;
  }

  img {
    height: 191px;
    margin-top: $modern-gap-xl;
  }

  @media (min-width: $screen-desktop-min) {
    img {
      height: 300px;
    }
  }
}