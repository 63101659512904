.enrollment-container {
  display: flex;
  gap: $modern-gap;

  .enrollment-main {
    flex-basis: 66.66%;
  }

  .enrollment-extra {
    flex-basis: 33.33%;
  }

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;

    .enrollment-main, .enrollment-extra {
      flex-basis: 100%;
    }
  }
}

.enrollment-group-registration {
  display: flex;
  gap: $modern-gap-xl;

  .group-registration__banner {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: $modern-gap-md;
    background-color: $modern-primary-background;
    border-radius: $modern-radius;
    flex-basis: 50%;
  }

  .group-registration__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    flex-basis: 50%;
  }

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;

    .group-registration__banner, .group-registration__info {
      flex-basis: 100%;
    }
  }
}

.examinations-list {
  display: flex;
  flex-direction: column;
  gap: $modern-gap;
}

.examination {
  padding-right: $line-height;
  padding-bottom: $line-height;

  .disabled {
    opacity: 0.5;
  }
}

@media (max-width: map_get($grid-breakpoints, 'md')) {
  .examinations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.examination_banner {
  display: flex;
  flex-direction: column;
  background: $banner-bg-color;
  padding: $line-padding;
  border-radius: $border-radius-base;
}

.enrollment-cone.cone-no-exams {
  margin-bottom: -$modern-gap-md;

  @media (max-width: $screen-mobile-max) {
    display: none;
  }

  .cloud {
    height: 127px;
    width: 178px;
    right: 35px;
  }

  .talking-text {
    font-size: $modern-font-md;
    line-height: $modern-line-height;
    right: 46px;
    top: 30px;
  }
}

$enrollment-card-height: 352px;
$enrollment-content-width: $screen-mobile-min - $modern-gap-xl * 2;

.enrollment-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $modern-gap-md;
  min-height: $enrollment-card-height;
  border-radius: $modern-radius;
  background-color: $modern-primary-subpanel-soft-background;

  &.warning {
    background-color: $modern-warning-background;
  }

  @media (max-width: $screen-mobile-max) {
    min-height: auto;
    padding: $modern-gap;
  }

  .enrollment-layout__content {
    max-width: $enrollment-content-width;
  }

  .talking-cone {
    flex-grow: 1;
    min-width: 400px;
    min-height: 340px;
    margin-bottom: -$modern-gap-md;
    margin-right: -$modern-gap-md;

    @media (max-width: $screen-mobile-max) {
      display: none;
    }

    .talking-text {
      font-size: $modern-font-md;
      font-weight: $font-demi;
      line-height: $modern-line-height;
      letter-spacing: 0;
      text-align: center;
    }

    &.cone-cool {
      .cone {
        bottom: 0;
        right: 0;
      }

      .cloud {
        bottom: 170px;
        right: 125px;
      }

      .talking-text {
        bottom: 240px;
        right: 180px;
      }
    }

    &.cone-bye {
      .cone {
        bottom: 0;
        right: 40px;
      }

      .cloud {
        bottom: 135px;
        right: 35px;
      }

      .talking-text {
        bottom: 205px;
        right: 95px;
      }
    }

    &.cone-missed {
      .cone {
        bottom: 0;
        right: 35px;
      }

      .cloud, .big_cloud {
        bottom: 110px;
        right: 205px;
      }

      .big_cloud + .talking-text {
        bottom: 170px;
      }

      .talking-text {
        bottom: 159px;
        right: 232px;
      }
    }

    &.cone-waiting {
      .cone {
        bottom: 0;
        right: 0;
      }

      .cloud {
        bottom: 170px;
        right: 125px;
      }

      .talking-text {
        bottom: 228px;
        right: 175px;
      }
    }

    &.cone-waiting.cone-interview-waiting {
      .talking-text {
        right: 183px;
      }
    }

    &.cone-waiting.cone-interview-reroll {
      .talking-text {
        bottom: 237px;
        right: 160px;
      }
    }

    &.cone-missed.cone-interview-rejected {
      .talking-text {
        bottom: 177px;
        right: 245px;
      }
    }
  }
}

.enrollee-steps {
  display: flex;
  flex-direction: column;
  gap: $modern-gap-sm;
  counter-reset: step 0;

  &__item {
    counter-increment: step 1;

    &-counter:before {
      content: counter(step);
    }
  }
}
