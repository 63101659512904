@mixin file-uploader-border-dashed($color) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23#{$color}' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

@mixin file-input-variant () {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  z-index: 1;

  input[type=file], input[type=file]:focus, input[type=file]:hover {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 99;
    outline: 0;
  }

  ~ .file-input-name {
      display: inline-block;
      margin-left: 8px;
  }

  input {
      height: 32px;
  }
}

.file-input-wrapper {
  @include file-input-variant()
}

.file-input-button {
  @include file-input-variant()
}

.shp-file-uploader {
  .shp-file-uploader__dropzone {
    width: 100%;
    border-radius: $modern-radius-lg;
    @include file-uploader-border-dashed('0062FF');

    &.disabled {
      @include file-uploader-border-dashed('B5B5BE');

      .shp-file-uploader__label {
        cursor: auto;
      }
    }

    &.active {
      background-color: $modern-primary-background;
    }
  }

  .shp-file-uploader__select-button {
    margin-top: $modern-gap-sm;

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      padding: 1px $modern-gap-sm;
      border: 1px solid $modern-line;
      border-radius: $modern-radius;
      color: $text-color;
      font-weight: $font-demi;
      text-align: center;
      font-size: $font-lg;
      line-height: $modern-line-height;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .shp-file-uploader__input {
    display: none;
  }

  .shp-file-uploader__label {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: $modern-gap-md;
    cursor: pointer;
  }

  .shp-file-uploader__description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .shp-file-uploader__filelist {
    display: flex;
    flex-direction: column;
    gap: $modern-gap-sm;
    margin-top: $modern-gap;
  }

  .shp-file-uploader__filelist-subtitle {
    font-weight: $font-demi;
    font-size: $modern-font-sm;
    line-height: $modern-line-height-condensed;
    letter-spacing: 0.5px;
  }

  .shp-file-uploader__link {
    color: $modern-primary;
    font-weight: $font-demi;
  }

  .shp-file-uploader__caption {
    font-weight: $font-regular;
    font-size: $modern-font-xs;
    line-height: $modern-line-height-condensed;
    letter-spacing: 0;
    color: $modern-placeholder;

    &.shp-file-uploader__caption_error {
      color: $modern-error;
    }
  }

  .shp-file-uploader_hidden-xs {
    @media (max-width: $screen-mobile-max) {
      display: none;
    }
  }

  .shp-file-uploader__icon {
    color: $modern-subtext;
    margin-bottom: $modern-gap-sm;
    font-size: $font-3xl;
    line-height: $font-3xl;
  }

  .shp-file-uploader__main-description {
    color: $modern-subtext;
    margin-bottom: $modern-gap-xs;
  }

  .shp-file-uploader__img-preview {
    max-width: 100%;
    max-height: 400px;
    overflow: hidden;

    @media (max-width: $screen-mobile-max) {
      max-height: 250px;
    }

    img {
      max-height: inherit;
      border-radius: $modern-radius-lg;
      object-fit: contain;
    }
  }
}

.file-card {
  display: flex;
  justify-content: space-between;
  gap: $modern-gap-sm;
  padding: $modern-gap;
  border-radius: $modern-radius-lg;
  border: 1px solid $modern-grey-soft-background;
  background-color: $modern-layer;

  &.file-card_filled {
    background-color: $modern-background;
    border: none;
  }

  .file-card__icon {
    align-self: flex-start;
    margin-right: $modern-gap-sm;
  }

  .file-card__filename {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;

    .file-card__filename-layout {
      display: flex;
      flex-direction: column;
    }

    .file-card__filename-layout, a, span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .file-card__action_icon {
    cursor: pointer;
    color: $modern-placeholder;
  }

  .file-card__link {
    color: $modern-primary;
  }
}
