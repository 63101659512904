
.filter-holder {
    margin-top: 5px;
}

.filter-holder .filter {
    margin-right: 4px;
    margin-bottom: 5px;
    display: inline-block;
}

.filter-holder .filter.no-bottom {
    margin-bottom: 0;
}

.filter-holder.right-aligned .filter
{
    float: right;
}

.row-fluid table [class*="span"].filter-holder {
    float: left;
}

.row-fluid table [class*="span"].filter-holder.right-aligned {
    display: block;
    float: right;
}

.row-fluid table [class*="span"].filter-holder.right-aligned .filter
{
    float: right;
}

.filter-holder.right-aligned .filter.pull-left,
.row-fluid table [class*="span"].filter-holder.right-aligned .filter.pull-left
{
    float: left;
}


.filter input {
    margin-bottom: 0;
}

.filter > .checkbox {
    padding-top: 4px;
}

.filter .btn-sign-sorter {
    margin-bottom: 7px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
    float: left;
}

.filter .btn-sign-sorter ~ .search_form_dropdown{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.has-filter:hover {
    text-decoration: underline;
}

.has-filter.filtered {
    text-decoration: underline;
}

.filter > span {
    vertical-align: middle;
    margin-bottom: 0;
}
