$modern-registration-panel-shadow: 0px 1px 2px rgba(0, 99, 153, 0.15), 0px 0px 1px rgba(0, 99, 153, 0.15);

.registration-cone {
  height: 110px;
  width: 110px;
  transition: .3s;
}

.registration-cone-parent {
  background: url('/static/img/registration/parent-cone-colored.png') 50%/contain no-repeat;
  filter: grayscale(100%);
}

.registration-cone-pupil {
  background: url('/static/img/registration/super-cone-colored.png') 50%/contain no-repeat;
  filter: grayscale(100%);
}

.registration-cone-container {
  cursor:pointer
}

.registration-cone-container:hover .registration-cone-parent, .registration-cone_active {
  filter: grayscale(0%);
  transform: translateY(-2px);
}

.registration-cone-container:hover .registration-cone-pupil, .registration-cone_active {
  filter: grayscale(0%);
  transform: translateY(-2px);
}

.registration_representative_contacts__sms_button_resend:active, .registration_representative_contacts__sms_button_resend:focus {
  outline: none;
}

@mixin registration-base-layout {
  &__logo {
    margin-bottom: $gap-md;
  }

  &__description {
    margin-bottom: $gap-md;
  }

  &__content {
    margin-bottom: $gap-md;
    padding: $gap-md;
    background-color: $modern-layer;
    box-shadow: $modern-registration-panel-shadow;
    border-radius: $modern-radius;
  }

  @media (min-width: $screen-tablet-min) {
    display: flex;
    box-shadow: $modern-registration-panel-shadow;
    border-radius: $modern-radius;

    &__logo {
      height: 24px;
      margin-bottom: $line-height * 2;

      img {
        height: 100%;
      }
    }

    &__navigation {
      width: 296px;
      display: flex;
      flex-shrink: 0;
      border-radius: $modern-radius 0 0 $modern-radius;
      flex-direction: column;
      padding: $gap-md;
      background: linear-gradient(92.84deg, rgba(255, 255, 255, 0) 0.42%, rgba(255, 255, 255, 0.7) 50.82%,
        rgba(255, 255, 255, 0) 101.35%), rgba(255, 255, 255, 0.4);
    }

    &__content {
      min-height: 640px;
      margin-bottom: 0;
      width: 100%;
      height: 100%;
      padding: $gap-xl * 2 $gap-lg $gap-lg $gap-lg;
      border-radius: 0 $modern-radius $modern-radius 0;
    }

    &__login {
      margin-top: auto;
    }
  }
}

.registration-layout {
  @include registration-base-layout;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: $modern-radius;
    background-color: inherit;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: $modern-radius;
    background-color: $modern-scrollbar-thumb-color;
  }
}

.stepper-layout {
  .registration-layout {
    &__description {
      margin-bottom: unset;
      padding: unset;
      background-color: $modern-layer;
      box-shadow: $modern-registration-panel-shadow;
      border-radius: $modern-radius $modern-radius 0 0;
    }

    &__content {
      border-radius: 0 0 $modern-radius $modern-radius;
    }

    &__login {
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: $screen-tablet-min) {
      &__navigation {
        width: 240px;
        position: relative;
        overflow: hidden;
      }

      &__logo {
        margin-bottom: 40px;
      }

      &__description {
        background-color: unset;
        border-radius: unset;
        box-shadow: unset;
        height: 300px;
      }

      &__content {
        padding: $gap-md $gap-lg;
        position: relative;
        border-radius: 0 $modern-radius $modern-radius 0;

        .stepper-controls {
          position: absolute;
          left: $gap-md;
          right: $gap-md;
          bottom: $gap-md;
        }
      }

      &__login {
        position: absolute;
        bottom: -22px;
        left: 0;
        right: 0;
      }
    }
  }

  &.course-stepper-layout {
    .registration-layout__login {
      position: static;
      margin-top: auto;
      margin-left: 0;
    }
  }

  .registration-region-selection {
    margin-top: 56px;
  }
}

.stepper-layout {
  .registration-layout__content .channel-enter-form {
    margin-top: $modern-gap-2xl;

    @media (max-width: $screen-mobile-max) {
      margin-top: 0;
    }
  }

  .registration-accounts-layout {
    margin-top: 0;
  }
}

.registration-examination-list {
  .exam-option {
    margin: 1px;
  }

  @media (min-width: $screen-tablet-min) {
    max-height: 350px;
    overflow-y: auto;
  }
}

.registration-finish-layout {
  background-color: $modern-layer;
  border-radius: $modern-radius;
  padding: $gap-md;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: $modern-registration-panel-shadow;

  &__logo {
    margin-left: auto;
    margin-right: auto;
    height: $gap-md;
    margin-bottom: $gap-md;
  }

  &__content {
    max-width: 410px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 280px;
  }

  &__cone {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 277px;
  }

  @media (min-width: $screen-tablet-min) {
    &__logo {
      margin-left: unset;
    }
  }
}

.channel-enter-form {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $screen-mobile-max) {
    max-width: none;
  }

  &.fix-registration-layout {
    @media (min-width: $screen-tablet-min) {
      margin-top: -35px;
    }
  }
}

.registration-confirmation-form {
  width: 204px;
}

.registration-reset-form {
  @media (min-width: $screen-tablet-min) {
    width: 304px;
  }
}

.registration-accounts-layout {
  margin-top: -$gap-lg * 3;

  @media (max-width: $screen-mobile-max) {
    margin-top: 0;
  }

  .registration-accounts-container {
    height: 640px - $gap-xl * 3;
    overflow-y: auto;

    @media (max-width: $screen-mobile-max) {
      height: auto;
    }
  }

  .registration-accounts-list {
    display: flex;
    flex-wrap: wrap;
    gap: $modern-gap-md;
    margin-bottom: $modern-gap-lg;
  }

  .registration-accounts-item {
    border: 1px solid $modern-line;
    border-radius: $modern-radius;
    padding: $modern-gap-sm $modern-gap;
    width: calc(50% - #{$modern-gap-md} / 2);

    @media (max-width: $screen-mobile-max) {
      width: 100%;
    }

    .h4 {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: $modern-radius;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: $modern-radius;
    background-color: $modern-subtext;
  }
}

.modern-page {
  .registration, .course-registration {
    padding: $line-padding * 2 $line-padding;
    min-height: 100vh;

    background: $modern-gradient-pinkman;

    @media (min-width: $screen-tablet-min) {
      padding-top: $gap-lg;
      padding-bottom: $gap-lg;
      display: flex;
      justify-content: center;
      align-items: center;

      &__content {
        min-height: 640px;
        width: 945px;
      }
    }
  }

  .event-registration {
    @media (min-width: $screen-tablet-min) {
      align-items: flex-start;
    }
  }
}

.registration-region-selection {
  max-width: $screen-mobile-min - 2 * $modern-gap;
  margin: 0 auto;
}

.modern-registration-tabs {
  display: flex;
  gap: $gap-md;
  width: fit-content;
  border-bottom: 1px solid $modern-layer;

  .modern-registration-tab {
    display: flex;
    align-items: center;
    font-size: $modern-font-md;
    line-height: $modern-line-height;
    font-weight: $font-demi;
    padding-bottom: $gap-sm;
    color: $modern-subtext;
    position: relative;
    cursor: pointer;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: ' ';
      height: 2px;
      width: 100%;
      border-radius: 4px 4px 0 0;
    }

    &.active {
      color: $modern-primary;
      &:after {
        background-color: $modern-primary;
      }
    }

    i {
      font-size: $icon-font;
      font-weight: $font-regular;
      margin-right: $gap-xs;
    }


  }
  @media (max-width: $screen-mobile-max) {
    width: 100%;

    .modern-registration-tab {
      flex-grow: 1;
      flex-direction: column;
      text-align: center;
      font-size: $modern-font-xxs;
      line-height: $modern-line-height-condensed;

      i {
        margin-right: unset;
        margin-bottom: $gap-xs;
        line-height: $modern-line-height;
      }
    }
  }
}

.modern-registration_schools-list {
  display: flex;
  flex-direction: column;
  gap: $gap-md;

  @media (min-width: $screen-tablet-min) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &.one-column {
      grid-template-columns: 1fr;
    }
  }
}

.modern-registration-no-exams-layout {
  .description-text {
    text-align: center;
  }

  .cone {
    margin-left: auto;
    margin-right: auto;
  }
}
