@use "sass:math";

@mixin shp-dairy-mark-variant($text-color, $color) {
  //height: 20px;
  //background: $color;
  color: $text-color;
}


.shp-dairy {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  /* These are just initial values which are overriden using JavaScript when a column is resized */
  grid-template-columns: minmax(100px, 0.5fr) minmax(300px, 1.5fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(50px, 100px);
  align-content: center;
  overflow: auto;

  .attendance {
    display: flex;
    justify-content: center;
    align-items: center;
    width: math.div($line-height, 1.5);
    height: 100%;
    margin: 0 5px;
    position: relative;
    font-size: 18px;
  }

  .fullrow-icon {
    padding: 6px
  }
  .attendance__container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .attendance-time-hover {
    position: absolute;
    left: -7px;
    top: -16px;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 128px;
    background: inherit;
    height: 32px;
    padding: 10px;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  td, th {
    min-height: $line-height;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //padding: 2px;
    box-sizing: border-box;
    background: white;
    height: 100%;
  }

  .table-header__title {
    margin-right: 0.5 * $spacer;
  }

  th.info > div {
    color: $brand-primary;
    background: lighten($brand-info, 40%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px;
  }

  th {
    padding: 2px;
  }

  td.text-right, th.text-right {
    justify-content: flex-end;
  }

  td.text-center, th.text-center {
    justify-content: center;
  }

  tr:nth-child(2n - 1) td {
    //background: darken(white, 5%);
  }

  tr:hover td {
    background: rgb(240, 240, 240);
  }

  .column_sticky {
    position: sticky;
    left: 0;
    background: white;
    z-index: 10;
  }

  &__number {
    text-align: right;
    display: inline-block;
    width: 24px;
  }

  &__mark {
    width: 20px;
    text-align: left;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &__default_cursor {
      cursor: default;
    }
  }

  &__cell {
    padding: math.div($line-height, 4);
  }

  &__mark-creator {
    width: 20px;
    border: none;
    background: transparent;
    text-align: left;
    box-shadow: none;
    cursor: pointer;
    transition: .3s;

    &:focus {
      background: white;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    }
  }

  .unit-name {
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 5px);
    display: inline-block;
    margin-right: math.div($line-height, 6);
    text-overflow: ellipsis;

    &_opened {
      background: $btn-primary-bg;
    }
  }

  .consultation {
    width: 24px;
    display: inline-block;
    text-align: center;
  }

  .mark_commented::after {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    bottom: 0;
    left: 10%;
    border-bottom: 2px dotted black;
  }

  .mark_published::after {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    bottom: 0;
    left: 10%;
    border-bottom: 2px solid black;
  }

  .unit-result__cell {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: $font-size-small;
    padding: 0 2px;
    font-family: $fonts-monospace;
    line-height: 1;
    border-radius: $border-radius-base;
  }

  .unit_result__cell_top {
    font-size: $font-size-small;
    width: 100%;
  }
  .unit-result__cell:hover {
    box-shadow: 0 0 3px 0 rgba(30, 30, 30, 0.5);
    position: relative;
    z-index: 10;
  }

}
