.modal-open .modal {
  overflow: auto;
}

.modal-open .modal.right{
  overflow: auto;
}

.modal .popover {
    z-index: 1051;
}

.tooltip {
    z-index: 2050;
}

.shp-tooltip-scroll-fix {
  transform: translateY(calc(0px - var(--navigation-height)));
}

.modal-backdrop.fade.in {
    opacity: 0.2;
}

.dropdown {
    list-style: none
}

.list-group-item {
  padding: 12px 16px;
}
