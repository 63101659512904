@use "sass:math";
$step-element-question-button-size: 30px;

.step-element, .panel.step-element {
  margin-bottom: 32px;
}

.unit-menu-item {
  margin-bottom: 15px;
}

.unit-menu-item__heading > div {
  vertical-align: middle;
}

.problem-element__footer {
  text-align: center;

}

.unit-content {
  width: 100%
}

.client-unit-content-root__panel {
  padding: 64px 48px;
}

.unit-menu-client-timer__timer {
  margin: $line-padding*2;
}

.step_element__title {
  font-size: $font-size-h3;
  font-weight: bold;
  margin-bottom: math.div($line-height-computed, 2);
}


.units__deadline {
  table-layout: fixed;
  tr :nth-child(2), tr :nth-child(3) {
    max-width: 5 * $line-height;
  }
  min-width: 100%;
  grid-template-columns: [lesson] auto max(160px, 1fr) max(160px, 1fr);
  overflow: auto;
}

.step-element__question-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $step-element-question-button-size;
  height: $step-element-question-button-size;
}
