$modern-switch-control-size: 16px;
$modern-switch-width: 36px;
$modern-switch-height: 20px;

$modern-switch-point-color: $modern-text-white;
$modern-switch-unselect-background: $modern-subtext;
$modern-switch-control-background-hover: $modern-text-black;

.switch-container {
  .switch {
    position: relative;
    width: $modern-switch-width;
    user-select: none;

    input {
      display: none;
    }

    label {
      display: block;
      height: $modern-switch-height;
      padding: 0;
      border: 1px solid $modern-line;
      border-radius: 100px;
      background-color: $modern-switch-unselect-background;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.2s ease-in;
    }

    label:before {
      content: "";
      position: absolute;
      top: 2px;
      right: 18px;
      display: block;
      width: $modern-switch-control-size;
      height: $modern-switch-control-size;
      margin: 0;
      background-color: $modern-switch-point-color;
      border-radius: 100px;
      transition: all 0.2s ease-in;
    }

    input:checked + label {
      background: $modern-primary;
      border: 1px solid $modern-primary-pressed;
    }

    input:checked + label {
      border-color: $modern-primary-pressed;
    }

    input:checked + label:before {
      right: 2px;
    }
  }

  &:hover, &.hover {
    label {
      background-color: $modern-switch-control-background-hover;
    }

    input:checked + label {
      background: $modern-primary-hover;
    }
  }

  &:focus-within, &.focus {
    label {
      outline: 1px solid $modern-primary-outline;
      outline-offset: 1px;
    }
  }

  &:disabled, &[disabled], &.disabled {
    label {
      background-color: $modern-disable;
      border-color: $modern-disable;
      cursor: not-allowed;
    }

    input:checked + label {
      background-color: $modern-primary-disable;
      border-color: $modern-primary-disable;
    }
  }
}

