.btn-fixed-width {
  width: 100%;
  max-width: 150px;
  display: block;
  float: none;
}

.btn-fixed-width-lg {
  width: 100%;
  max-width: 250px;
  display: block;
  float: none;
}

.btn-fixed-width-sm {
  width: 100%;
  max-width: 70px;
  display: block;
  float: none;
}

.btn-group.btn-fixed-width-sm {
  display: inline-block;
}


@media (max-width: 768px) {
  .full-width-xs {
    &.btn-fixed-width, &.btn-fixed-width-lg {
      max-width: none;
      width: 100%;
      display: block;
      float: none;
    }
  }
}