@use "sass:math";

.classes-info__functional {
  margin: 0 - $line-padding;
  padding: $line-padding;
  transition: .2s;
}

.classes-info__functional:hover {
  background: $table-bg-hover;
}

.classes_rate__card {
  min-width: 100px;
  cursor: pointer;
  padding: math.div($line-padding, 4);

  &_inactive {
    opacity: 50%
  }
}

.rate_modal__comment {
  min-height: 80px;
  width: 450px;
  padding: math.div($line-padding, 2) $line-padding * 0.75;
  border: 1px solid #ccc;
  border-radius: $border-radius-base;
  resize: none;
}

.abstract-classes__list-item {
  padding: math.div($line-padding, 2) $line-padding;
}

.classes_rate__star i {
  color: #c1c1c1;
}
.classes_rate__star .classes_rate__hovered_star {
  color: #ffce2b;
  opacity: 0.8;
}
.classes_rate__star .classes_rate__highlight_star {
  color: #ffce2b;
}
.teacher_rate_modal {
  min-height: 400px;
  position: relative;
}
.teacher_rate_modal__content {
  max-width: 320px;
  margin: auto;
  margin-top: 120px;
}

.classes__navigation {
  padding: 10px math.div($line-padding, 2);
  background: $ibox-content-bg;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;

  & :first-child {
    flex: 1 0 auto;
  }
}

.unit__menu__collapsed {
  .next_classes_link, .prev_classes_link {
    .caption {
      display: none;
    }
  }

  .classes__navigation {
      flex-direction: column-reverse;
      align-items: center;
  }
}
