.table > tbody > tr > .unit_result__cell {
  width: 40px;
  height: 30px;
  padding: 0;
  vertical-align: middle;
}

.unit_result__cell_top {
   font-size: 14px
 }

.unit_result_cell_bottom {
   font-size: 10px
 }

.unit_result_cell__border-color {
  border-color: #CCCCCC
}

.unit-cog-icon {
  font-size: 22px;
  margin-right: 4px;
  display: inline-block;
  animation:spin 4s infinite linear;
}

.unit_results__info_block {
  width: 20px;
  height: 12px;
  border: 1px solid #e7e7e7
}

table.thin_table tbody tr td,
table.thin_table tbody tr th{
  line-height: 14px;
}

.table.table-hover.table-bordered.thin_table.table-compact>thead>tr>th {
  border-color: #CCCCCC
}
.table.table-hover.table-bordered.thin_table.table-compact {
  border-color: #CCCCCC;
  border-radius: 10px
}

.table.table-hover.table-bordered.thin_table.table-compact>tbody>tr>td {
  border-color: #CCCCCC;
}
.unit_results__text_dark {
  color: #000 !important;
  text-decoration: none
}
.fixed_cell {
  min-width: 32px;
  width: 32px;
  max-width: 32px;
}

.unit-results-table {
  width: auto;
}

.unit-results_testing-triangle {
  display: inline-block;
  position: absolute;
  top: 20%;
  right: -10%;
  border: 5px solid transparent;
  border-left: 5px solid #2980b9;

  &--active {
    border-right: 5px solid #2980b9;
    border-left: transparent;
  }
}

.unit_results > .unit-result_question-cell {
  padding: 0;
  min-width: 44px;
  max-width: 44px;
  width: 44px;
  text-align: center;
  vertical-align: middle;
}

.unit-results_header .unit-result_header-question-cell {
  border-right: none;
  border-left: none;
  text-align: center;
}

.unit-results_footer .unit-results_footer-total-cell {
  border-right: none;
  border-left: none;
}

.unit-result_header-testing-cell {
  padding: 8px;
  min-width: 48px;
  max-width: 48px;
  width: 48px;
  text-align: center;
}

.unit-results_pupil-cell {
  width: fit-content;
}

.unit-results_invisible-cell {
  visibility: hidden;
}
