@mixin row_condensed($class) {
  .col-#{$class}-condensed {
    padding-left: 2 * $grid-size;
    padding-right: 2 * $grid-size;
  }

  .col-#{$class}-condensed-left {
    padding-left: 2 * $grid-size;
  }

  .col-#{$class}-condensed-right {
    padding-right: 2 * $grid-size;
  }
}

@include row_condensed(xs);

@media (min-width: $screen-sm-min) {
  @include row_condensed(sm);
}

@media (min-width: $screen-md-min) {
  @include row_condensed(md);
}

@media (min-width: $screen-lg-min) {
  @include row_condensed(lg);
}
