@use "sass:meta";
@use "sass:math";

$modern-tag-primary-dark: $modern-primary-pressed;
$modern-tag-primary-light: $modern-zircon-white;
$modern-tag-hover: #99C0FF;
$modern-tag-max-width: 280px;

@mixin tag-icon-colors($args...) {
  @each $name, $color in meta.keywords($args) {
    .modern-tag-icon-#{$name} {
      color: $color;
    }
  }
}

@mixin modern-tag-base() {
  display: inline-flex;
  align-items: stretch;
  line-height: $modern-line-height-condensed;
  font-weight: $font-regular;
  letter-spacing: 1px;
  font-size: $modern-font-sm;
  vertical-align: middle;
  border-radius: $modern-radius-sm;

  .modern-tag-title {
    padding: $modern-gap-xs $modern-gap-xs * 3;
    border-radius: $modern-radius-sm;
    max-width: $modern-tag-max-width;
  }

  &.modern-tag--full {
    overflow: hidden;

    .modern-tag-title {
      max-width: initial;
    }
  }

  > button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: $font-regular;
  }

  &.modern-tag-cross {
    padding-right: 0;

    .modern-tag-title {
      border-radius: $modern-radius-sm 0 0 $modern-radius-sm;
      padding-right: $modern-gap-xs;
    }

    > button {
      border-radius: 0 $modern-radius-sm $modern-radius-sm 0;
      padding: 0 $modern-gap-sm 0 $modern-gap-xs;

      &.modern-tag__action-btn {
        padding: 0;
      }

      &:hover, &:focus, {
        background-color: $modern-tag-hover;
        color: $modern-tag-primary-dark;
      }
    }
  }

  &.modern-tag-sm {
    .modern-tag-title {
      padding: $modern-gap-xs $modern-gap-sm;
    }
  }

  &.modern-tag-xs {
    .modern-tag-title {
      padding: 0 $modern-gap-xs;
    }
  }
}

@mixin modern-tag-variant($text-color, $bg-color, $button-hover-color) {
  .modern-tag-title {
    color: $text-color;
    background-color: $bg-color;
  }

  > button {
    color: $text-color;
    background: $bg-color;

    .modern-tag__icon {
      color: $text-color;
    }

    &:hover, &:focus {
      background: $button-hover-color;
    }
    &:focus-visible {
      outline: 1px solid $modern-primary;
    }
  }

  &.hover, &.focus {
    > button {
      background: $button-hover-color;
    }
  }

  &.focus > button {
    outline: 1px solid $modern-primary;
  }
}

.modern-tag {
  @include modern-tag-base();
  @include modern-tag-variant($modern-tag-primary-dark, $modern-tag-primary-light, $modern-tag-hover);

  &.modern-tag-darkblue {
    @include modern-tag-variant($modern-tag-primary-light, $modern-tag-primary-dark, $modern-tag-hover);
  }

  &.modern-tag-grey {
    @include modern-tag-variant($modern-tag-grey-dark, $modern-tag-grey, $modern-tag-grey-hover);
  }

  &.modern-tag-blue {
    @include modern-tag-variant($modern-tag-blue-dark, $modern-tag-blue, $modern-tag-blue-hover);
  }

  &.modern-tag-success, &.modern-tag-green {
    @include modern-tag-variant($modern-tag-green-dark, $modern-tag-green, $modern-tag-green-hover);
  }

  &.modern-tag-yellow {
    @include modern-tag-variant($modern-tag-yellow-dark, $modern-tag-yellow, $modern-tag-yellow-hover);
  }

  &.modern-tag-error, &.modern-tag-red {
    @include modern-tag-variant($modern-tag-red-dark, $modern-tag-red, $modern-tag-red-hover);
  }

  &.modern-tag-orange {
    @include modern-tag-variant($modern-tag-orange-dark, $modern-tag-orange, $modern-tag-orange-hover);
  }

  &.modern-tag-violet {
    @include modern-tag-variant($modern-tag-violet-dark, $modern-tag-violet, $modern-tag-violet-hover);
  }
}

@include tag-icon-colors(
  $blue: $modern-tag-blue-dark,
  $grey: $modern-tag-grey-dark,
  $green: $modern-tag-green-dark,
  $yellow: $modern-tag-yellow-dark,
  $red: $modern-tag-red-dark,
  $orange: $modern-tag-orange-dark,
  $violet: $modern-tag-violet-dark,
  $disabled: $modern-placeholder,
);
