.school-select-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $modern-gap;
  background: $modern-layer;
  border: 1px solid $modern-line;
  border-radius: $modern-radius;
  padding: $gap;
  position: relative;
  cursor: pointer;

  &__image {
    height: 32px;
    flex-shrink: 0;

    img {
      height: 100%;
    }
  }

  &__header {
    flex-grow: 1;
    display: flex;
    gap: $modern-gap-sm;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
  }

  &__address {
    color: $modern-subtext;
  }

  &__radio {
    align-self: end;
  }

  &.disabled, &[disabled] {
    background-color: $modern-grey-background;
    border: unset;
    cursor: not-allowed;
  }

  &.active {
    background: $modern-primary-subpanel-background;
    border-color: $modern-primary;
  }
}

.admission-selection {
  border-radius: $modern-radius;
  background-color: $modern-layer;
  padding: $modern-gap-md;
  border: 1px solid $modern-line;

  &.admission-selection__wave-bg {
    border: none;
    background-image: url('/static/inspinia/img/admission_selector_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .admission-selection-card {
      .admission-selection__column-header,
      .admission-selection__column-content {
        color: $modern-text-white;
      }
    }
  }

  .admission-selection-card {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: $modern-gap-md;

    .admission-selection-card-info {
      display: flex;
      gap: $modern-gap-md;
    }

    .admission-selection__button-text {
      @media (max-width: $screen-mobile-max) {
        display: none;
      }
    }

    .admission-selection__column-header {
      font-weight: $font-bold;
      font-size: $modern-font-sm;
      line-height: $modern-line-height-condensed;
      letter-spacing: 1px;
      color: $modern-placeholder;
      margin-bottom: $modern-gap-sm;
      text-transform: uppercase;
    }
  }
}

.school-tabs {
  display: flex;
  gap: $gap-md;
  width: fit-content;
  border-bottom: 1px solid $modern-layer;

  .school-tab {
    display: flex;
    align-items: center;
    font-size: $modern-font-md;
    line-height: $modern-line-height;
    font-weight: $font-demi;
    padding-bottom: $gap-sm;
    color: $modern-grey;
    position: relative;
    cursor: pointer;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: ' ';
      height: 2px;
      width: 100%;
      border-radius: 4px 4px 0 0;
    }

    &.active {
      color: $modern-primary;
      &:after {
        background-color: $modern-primary;
      }
    }

    i {
      font-size: $icon-font;
      font-weight: $font-regular;
      margin-right: $gap-xs;
    }


  }
  @media (max-width: $screen-mobile-max) {
    width: 100%;

    .school-tab {
      flex-grow: 1;
      flex-direction: column;
      text-align: center;
      font-size: $modern-font-xxs;
      line-height: $modern-line-height-condensed;

      i {
        margin-right: unset;
        margin-bottom: $gap-xs;
        line-height: $modern-line-height;
      }
    }
  }
}

.schools-list {
  display: flex;
  flex-direction: column;
  gap: $gap-md;

  @media (min-width: $screen-tablet-min) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &.one-column {
      grid-template-columns: 1fr;
    }
  }
}
