
table tbody tr:nth-child(n) td.error-background,
table tbody tr:nth-child(n) th.error-background,
.error-background, .error-background{
    background-color: $state-danger-bg;
}


table tbody tr:nth-child(n) td.danger-background,
table tbody tr:nth-child(n) th.danger-background,
.danger-background, .danger-background{
    background-color: $state-danger-bg;
}

.video-preview {
    padding: 10px 30px 10px 30px;
    background-color: #333333;
    opacity: 0.7;
    border-radius: 8px;
    cursor: pointer;
}

table tbody tr:nth-child(n) td.warning-background,
table tbody tr:nth-child(n) th.warning-background,
.warning-background, .warning-background{
    background-color: $state-warning-bg;
}


table tbody tr:nth-child(n) td.info-background,
table tbody tr:nth-child(n) th.info-background,
.info-background, .info-background{
    background-color: $state-info-bg;
}

table tbody tr:nth-child(n) td.success-background,
table tbody tr:nth-child(n) th.success-background,
.success-background{
    background-color: $state-success-bg;
}

table tbody tr:nth-child(n) td.inactive-background,
table tbody tr:nth-child(n) th.inactive-background,
.inactive-background{
    background-color: #F0F0F0;
}


table tbody tr:nth-child(n) td.gray-background,
table tbody tr:nth-child(n) th.gray-background,
.gray-background{
    background-color: #f7f7f7;
}


table tbody tr:nth-child(n) td.white-background,
table tbody tr:nth-child(n) th.white-background,
.white-background{
    background-color: #ffffff;
}

.table tbody tr.gray td,
.table-hover tbody tr.gray:hover td {
    background-color: #f7f7f7;
}



.table-bordered tbody tr th, .table-bordered tbody tr td {
    /*border-left: inherit;*/
}

.table-bordered>tbody>tr>th, .table-bordered>tbody>tr>td {
    /*border-left: 1px solid #dddddd;*/
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
    background-color: #ffffff;
}

.table-hover>tbody>tr > td,
.table-hover>tbody>tr > th {
    background-color: #ffffff;
}

.table-hover>tbody>tr:hover > td,
.table-hover>tbody>tr:hover > th {
    background-color: #f5f5f5;
}

.table tbody>tr.hover > td {
    background-color: #f5f5f5;
}

.table.table-no-border>tbody>tr>td, .table.table-no-border>tbody>tr>th {
    border-top: 0;
}

table tbody tr.table-header:nth-child(n) td,
table tbody tr.table-header:nth-child(n) th
{
    background-color: #f3f3f3;
}

.ui-selected,
.table-striped tbody tr:nth-child(2n+1).ui-selected > td,
.table-striped tbody tr:nth-child(2n+1).ui-selected > th {
    background-color: #3A87AD;
    color: #FFFFFF;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.stripped .strip:nth-child(2n + 1) {
    background-color: #ffffff;
}

.stripped .strip:nth-child(2n) {
    background-color: #f7f7f7;
}

.short_cell {
    width: 40px;
    white-space: nowrap;
}

.table-condensed .short_cell_with_icon {
    width: 60px;
    padding-left: 11px;
}

.noborder tr, .noborder td {
    border: none;
}

.table tr th div .title {
    display: table-cell;
    white-space: normal;
    padding-right: 2px;
}

.table-row ul input {
    display: table-cell;
}

.table-row label {
    display: table-cell;
}

@media (max-width: 767px) {
    .table-row input[type="radio"] {
        margin-left: 0;
    }
}

tr .td-compact {
    margin-bottom: -3px;
    margin-top: -3px;
}

.table .table-row {
    display: table-row;
}

.table .table-row .table-cell {
    display: table-cell;
}

td.btn-cell, .table-condensed > tbody > tr > td.btn-cell {
    padding-top: 2px;
    padding-bottom: 1px;
}

.table-hover tbody tr.preview:hover > td, .table-hover tbody tr.preview:hover > th {
    background-color: #F7F7F7;
}

.table tr td.cell-with-icon, .table th.cell-with-icon  {
    padding-bottom: 0;
    padding-top: 6px;
    text-align: right;
    white-space: nowrap;
}

.table td.cell-with-icon i, .table th.cell-with-icon i, .large-icons i, .cell-with-icon> a > i{
    font-size: 18px;
    padding-top: 0;
}


.table.table-striped>tbody:last-child>tr:last-child>td, .table.table-striped>tbody:last-child>tr:last-child>th{
    border-bottom: 1px solid #DDDDDD;
}

table tr td.action_td {
    padding-top: 2px;
    padding-bottom: 2px;
    max-width: 100px;
    text-align: center;
}

.table-scroll table{
    margin-bottom: 0;
}

.table.table-striped>tbody>tr.alert-row>td {
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
}

.table.table-striped>tbody>tr:last-child.alert-row>td {
    border-bottom: 1px solid #DDDDDD;
}
