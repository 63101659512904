@use "sass:math";

$chat-root-height: calc(var(--main-content-height) - 2 * #{$modern-gap-md});
$chat-root-mobile-height: var(--main-content-height);

$chat-desktop-discussions-list-width: 380px;
$chat-tablet-discussions-list-width: 344px;

$chat-manager-width: 400px;
$chat-manager-header-height: 65px;

$chat-edit-message-panel-size: 57px;

.shp-chat {
  display: flex;
  gap: $modern-gap-lg;
  align-items: center;
  padding: $modern-gap-md;

  @media (max-width: $screen-mobile-max) {
    padding: $modern-gap;
  }

  &.shp-chat_mobile {
    padding: 0;

    .shp-chat__discussion-back-icon {
      display: block;
    }

    .shp-chat__mark-read-text,
    .shp-chat__content-type-icon {
      display: none;
    }
  }

  .shp-chat__discussion-back-icon {
    cursor: pointer;
  }

  .shp-chat__create-discussion-button {
    padding: $modern-gap;
    margin: $modern-gap $modern-gap $modern-gap-sm $modern-gap;
    border: 1px solid $modern-primary-background;
    border-radius: $modern-radius-lg;
    cursor: pointer;
  }

  &.shp-chat_modal {
    width: 400px;
    height: var(--window-height);

    @media (max-width: $screen-tablet-max) {
      padding: 0;
      margin: 0;
    }

    @media (max-width: $screen-mobile-max) {
      width: 100vw;
      margin: 0;
    }

    .shp-chat__discussions-list {
      width: 100%;
    }

    .shp-chat__discussions-list,
    .shp-chat__discussion-detail {
      height: 100%;
    }
  }

  .shp-chat__discussions-list-title {
    margin-top: 0;
    margin-bottom: $modern-gap;
    color: $modern-subtext;
  }

  .shp-chat__discussions-list,
  .shp-chat__discussion-detail {
    height: $chat-root-height;
    border-radius: $modern-radius-lg;
    background-color: $modern-layer;
    overflow: hidden;

    @media (max-width: $screen-mobile-max) {
      height: $chat-root-mobile-height;
      border-radius: 0;
    }
  }

  .shp-chat__discussions-list {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: $chat-desktop-discussions-list-width;

    @media (max-width: $screen-tablet-max) {
      width: $chat-tablet-discussions-list-width;
    }

    @media (max-width: $screen-mobile-max) {
      width: 100%;
    }

    .shp-chat__discussions-list-header {
      flex: auto;
      padding: $modern-gap;
      border-bottom: 1px solid $modern-grey-soft-background;
    }

    .shp-chat__discussions-list-content {
      height: 100%;
      overflow-y: scroll;
      @include modern-invisible-scrollbar();
    }

    .shp-chat__discussions-list-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $modern-gap-sm;
      flex: 0 0 auto;
      padding: $modern-gap;
      border-top: 1px solid $modern-grey-soft-background;
      cursor: pointer;

      &:hover {
        background-color: $modern-background;
      }
    }
  }

  .shp-chat__discussion-detail {
    flex-grow: 1;

    .shp-chat__discussion-detail-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      &.is_mobile {
        .shp-chat__discussion-comment {
          max-width: 100%;
        }
      }
    }

    .shp-chat__discussion-notifications-list {
      height: 100%;
      overflow-y: scroll;
      @include modern-invisible-scrollbar();
    }

    .shp-chat__discussion-detail-content {
      overflow-y: scroll;
      @include modern-invisible-scrollbar();
    }

    .shp-chat__discussion-detail-scroll-wrapper {
      position: relative;
      overflow: auto;
      height: 100%;

      .shp-chat__scroll-button-container {
        position: absolute;
        right: $modern-gap;
        bottom: $modern-gap;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $modern-gap-sm;

        .shp-chat__scroll-button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          padding: $modern-gap-sm;
          box-shadow: $modern-shadow-8dp;
          background-color: $modern-layer;
          border-radius: 50%;
          cursor: pointer;

          .shp-chat__counter-label {
            position: absolute;
            top: -10px;
            right: -8px;
          }
        }
      }
    }

    .shp-chat__discussion-detail-content {
      height: 100%;
      padding: $modern-gap;
    }

    .shp-chat__discussion-detail-footer {
      position: relative;
      flex: none;
    }

    .shp-chat__message-edit-panel {
      position: absolute;
      top: -$chat-edit-message-panel-size;
      left: 0;
      display: flex;
      align-items: flex-start;
      gap: $modern-gap;
      padding: $modern-gap-sm $modern-gap;
      height: $chat-edit-message-panel-size;
      width: 100%;
      border-top: 1px solid $modern-disable;
      background-color: $modern-layer;
    }

    .shp-chat__message-edit-panel-content {
      flex-grow: 1;
      min-width: 0;
      padding: 0 $modern-gap-sm;
      border-left: 2px solid blue;
    }

    .shp-chat__discussion-notifications {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      .feed-event:not(:last-child) {
        width: 100%;
        border-bottom: 1px solid $modern-grey;
      }
    }

    .shp-chat__discussion-detail-header {
      flex: auto;
      border-bottom: 1px solid $modern-grey-soft-background;
    }

    .shp-chat__discussion-detail-header_main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $modern-gap-sm;
      flex-grow: 1;
      min-width: 0;
      padding: $modern-gap;
    }

    .shp-chat__discussion-settings-button {
      padding: $modern-gap;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid $modern-grey-soft-background;
    }

    .shp-chat__discussion-comment-wrapper {
      display: flex;

      .shp-chat__discussion-comment-column, .shp-chat__discussion-comment {
        display: flex;
        flex-direction: column;
        gap: $modern-gap;
        width: 100%;
      }

      .shp-chat__discussion-message-wrapper {
        display: flex;

        .shp-chat__discussion-message {
          max-width: 100%;
          background-color: $modern-background;
          padding: $modern-gap-sm $modern-gap;
          border-radius: $modern-radius-xl $modern-radius-xl $modern-radius-xl 0;
          word-break: break-word;

          &.shp-chat__discussion-message_by-user {
            background-color: $modern-primary-subpanel-dark-background;
          }

          td, th {
            border: 1px solid $modern-line;
            padding: $modern-gap-sm;
          }
        }

        .shp-chat__discussion-comment-markdown {
          img {
            object-fit: contain;
            width: 100%;
          }
        }
      }

      .shp-chat__discussion-message_service {
        display: flex;
        justify-content: center;
        flex-basis: 100%;
        font-weight: $font-regular;
        font-size: $modern-font-sm;
        line-height: $modern-line-height-condensed;
        letter-spacing: 0;
        color: $modern-placeholder;
        text-align: center;
      }

      .shp-chat__discussion-message_message {
        display: flex;
        gap: $modern-gap-xs;
        align-items: flex-end;
        max-width: 66.66%;

        @media (max-width: $screen-mobile-max) {
          max-width: 100%;
        }
      }

      .shp-chat__discussion-message-wrapper_by-user {
        flex-direction: row-reverse;

        .shp-chat__discussion-message {
          background-color: $modern-primary-subpanel-soft-background;
          border-radius: $modern-radius-xl $modern-radius-xl 0 $modern-radius-xl;
        }

        .shp-chat__discussion-message_message {
          flex-direction: row-reverse;
        }
      }
    }
  }

  &.shp-chat_teacher {
    .shp-chat__discussion-detail {
      display: flex;
    }

    .shp-chat__discussion-detail-container {
      flex-grow: 1;
      min-width: 0;
    }

    .shp-chat__discussion-manager {
      flex-shrink: 0;
      width: $chat-manager-width;
      border-left: 1px solid $modern-grey-soft-background;

      @media (max-width: $screen-tablet-max) {
        width: 100%;
        height: $chat-root-mobile-height;
      }
    }

    .shp-chat__discussion-manager__main {
      padding: $modern-gap;
      max-height: calc(#{$chat-root-height} - #{$chat-manager-header-height});
      overflow-y: auto;
      @include modern-invisible-scrollbar();

      @media (max-width: $screen-mobile-max) {
        max-height: calc(var(--main-content-height) - #{$chat-manager-header-height});
      }
    }

    .shp-chat__discussion-manager__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $modern-gap + math.div($modern-gap-sm, 2) $modern-gap;
      border-bottom: 1px solid $modern-grey-soft-background;
    }

    .shp-chat__discussion-manager__pupil-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $modern-gap-sm;
    }

    .shp-chat__discussion-manager__main-info,
    .shp-chat__discussion-manager__respondents {
      background-color: $modern-background;
      padding: $modern-gap;
      border-radius: $modern-radius-lg;
    }
  }
}

.shp_chat__question-modal {
  &.shp_chat__question-modal_single-classes .shp_chat__discussion-content-button {
    display: none;
  }

  .modal-content {
    position: relative;

    .shp_chat__modal-close {
      position: absolute;
      top: 12px;
      left: -(2*$modern-gap-sm + $modern-font-icon);
      display: inline-flex;
      padding: $modern-gap-sm;
      border-bottom-left-radius: $modern-radius-lg;
      border-top-left-radius: $modern-radius-lg;
      background-color: $modern-primary;
      cursor: pointer;

      @media (max-width: $screen-mobile-max) {
        display: none;
      }
    }

    .shp_chat__modal-close_mobile {
      display: none;

      @media (max-width: $screen-mobile-max) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $modern-gap-sm;
        background-color: $modern-grey-soft;
        color: $modern-subtext;
      }
    }

    .shp_chat__modal-close_mobile, .shp_chat__modal-close {
      i {
        font-size: $modern-font-icon;
        line-height: $modern-font-icon;
      }
    }
  }
}


.shp-chat__discussion-card {
  display: flex;
  align-items: center;
  gap: $modern-gap-sm;
  padding: $modern-gap;
  cursor: pointer;

  &.active {
    background-color: $modern-primary-background;
  }

  &:hover:not(.active) {
    background-color: $modern-background;
  }

  .shp-chat__discussion-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  .shp-chat__discussion-card-counter {
    display: flex;
    align-items: flex-end;
  }
}

.shp-chat__counter-label {
  padding: $modern-gap-xs $modern-gap-sm;
  line-height: $modern-line-height-condensed;
  font-weight: $font-regular;
  letter-spacing: 1px;
  color: $modern-primary;
  background-color: $modern-primary-subpanel-dark-background;
  font-size: $modern-font-sm;
  vertical-align: middle;
  border-radius: $modern-radius-xxl;
  white-space: nowrap;

  &.shp-chat__counter-label--dark {
    color: $modern-layer;
    background-color: $modern-primary;
  }
}
