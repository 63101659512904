@mixin shp-social-variant ($prefix, $selector) {
  #{$selector}:hover  {
    color: white;
    opacity: 0.8;
  }

  #{$selector}.disabled {
    color: white;
    opacity: 0.5;
  }

  #{$selector} {
      display: inline-block;
      font-size: 18px;
      margin: 5px 2px;
      padding: 10px 0 10px 0;
      text-align: center;
      text-decoration: none;
      width: 40px;
      height: 40px;
  }

  &.shp-social-circle #{$selector} {
      border-radius: 50%;
  }

  &.shp-social-sm #{$selector} {
      font-size: 18px;
      margin: 5px 2px;
      padding-top: 6px;
      padding-bottom: 8px;
      text-align: center;
      text-decoration: none;
      width: 30px;
      height: 30px;
  }
  &.shp-social-xs #{$selector} {
      font-size: 14px;
      margin: 0px 2px;
      padding-top: 3px;
      padding-bottom: 4px;
      text-align: center;
      text-decoration: none;
      width: 20px;
      height: 20px;
  }

  #{$prefix}-vk {
      background: #45668e none repeat scroll 0 0;
      color: white;
      padding-right: 2px;
  }

  #{$prefix}-facebook {
      background: #3b5998 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-twitter {
      background: #55acee none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-google {
      background: #dd4b39 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-linkedin {
      background: #007bb5 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-youtube {
      background: #bb0000 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-instagram {
      background: #125688 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-pinterest {
      background: #cb2027 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-snapchat-ghost {
      background: #fffc00 none repeat scroll 0 0;
      color: white;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  #{$prefix}-skype {
      background: #00aff0 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-android {
      background: #a4c639 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-dribbble {
      background: #ea4c89 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-vimeo {
      background: #45bbff none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-tumblr {
      background: #2c4762 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-vine {
      background: #00b489 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-foursquare {
      background: #45bbff none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-stumbleupon {
      background: #eb4924 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-flickr {
      background: #f40083 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-yahoo {
      background: #430297 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-soundcloud {
      background: #ff5500 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-reddit {
      background: #ff5700 none repeat scroll 0 0;
      color: white;
  }
  #{$prefix}-rss {
      background: #ff6600 none repeat scroll 0 0;
      color: white;
  }
}


.social-auth-icon {
  width: 40px;
  height: 40px;
  background: transparent 50%/cover;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin: 5px 5px;
  padding: 5px;
  position: relative;
  box-sizing: border-box;
  &_small {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 3px;
  }
}

.unti__auth__btn {
  display: inline-block;
  cursor: pointer;
  position: relative;
  border-radius: $modern-radius-lg;
  overflow: hidden;
  .unti__auth__img {
    height: $modern-line-height-h2;
  }
}

.social-auth-icon::before {
  content: "";
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 80%;
  background: rgba(0,0,0,0);
  border-radius: 10px;
  transition: .2s ease-in-out;
}


.social-auth-icon:hover::before {
  background: rgba(20,20,20,0.1);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.social-auth-icon:active::before {
  background: rgba(220,220,220,0.3);
}

.social-auth-icon > img {
  width: 100%;
  height: 100%;
  position: relative;
}

.social-auth-container {
  display: inline-block;
}

.social_auth_connect > div.social-auth-container {
  display: flex;
  align-items: center;
}

.shp-social {
  @include shp-social-variant('.fa', '.fa');
  @include shp-social-variant('.icon', '.icon')
}


.social_auth__disabled {
  opacity: 0.5;
}