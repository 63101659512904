@import "bootstrap-sass-master/bootstrap/variables";
@import "bootstrap-sass-master/bootstrap/mixins";

// Reset and dependencies
@import "bootstrap-sass-master/bootstrap/normalize";
@import "bootstrap-sass-master/bootstrap/print";
@import "bootstrap-sass-master/bootstrap/glyphicons";

// Core CSS
@import "bootstrap-sass-master/bootstrap/scaffolding";
@import "bootstrap-sass-master/bootstrap/type";
@import "bootstrap-sass-master/bootstrap/code";
@import "bootstrap-sass-master/bootstrap/grid";
@import "bootstrap-sass-master/bootstrap/tables";
@import "bootstrap-sass-master/bootstrap/forms";
@import "bootstrap-sass-master/bootstrap/buttons";

// Components
@import "bootstrap-sass-master/bootstrap/component-animations";
@import "bootstrap-sass-master/bootstrap/dropdowns";
@import "bootstrap-sass-master/bootstrap/button-groups";
@import "bootstrap-sass-master/bootstrap/input-groups";
@import "bootstrap-sass-master/bootstrap/navs";
@import "bootstrap-sass-master/bootstrap/navbar";
@import "bootstrap-sass-master/bootstrap/breadcrumbs";
@import "bootstrap-sass-master/bootstrap/pagination";
@import "bootstrap-sass-master/bootstrap/pager";
@import "bootstrap-sass-master/bootstrap/labels";
@import "bootstrap-sass-master/bootstrap/badges";
@import "bootstrap-sass-master/bootstrap/jumbotron";
@import "bootstrap-sass-master/bootstrap/thumbnails";
@import "bootstrap-sass-master/bootstrap/alerts";
@import "bootstrap-sass-master/bootstrap/progress-bars";
@import "bootstrap-sass-master/bootstrap/media";
@import "bootstrap-sass-master/bootstrap/list-group";
@import "bootstrap-sass-master/bootstrap/panels";
@import "bootstrap-sass-master/bootstrap/responsive-embed";
@import "bootstrap-sass-master/bootstrap/wells";
@import "bootstrap-sass-master/bootstrap/close";

// Components w/ JavaScript
@import "bootstrap-sass-master/bootstrap/modals";
@import "bootstrap-sass-master/bootstrap/tooltip";
@import "bootstrap-sass-master/bootstrap/popovers";
@import "bootstrap-sass-master/bootstrap/carousel";

// Utility classes
@import "bootstrap-sass-master/bootstrap/utilities";
@import "bootstrap-sass-master/bootstrap/responsive-utilities";


//@import "bootstrap-sass-master/assets/stylesheets/bootstrap";
@import "bootstrap-extensions/_extensions";


@font-face {
  font-family: 'Glyphicons Halflings';

  src: url('#{$build_path}../fonts/glyphicons-halflings-regular.eot');
  src: url('#{$build_path}../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('#{$build_path}../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('#{$build_path}../fonts/glyphicons-halflings-regular.woff') format('woff'), url('#{$build_path}../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('#{$build_path}../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
