.shp-sup-status {
  position: absolute;
  //right: 10px;
  font-size: 12px;
  //top: -10px;
  right: 4px;
  top:2px;
}

.medium-icons .shp-sup-status i {
  font-size: 14px;
}