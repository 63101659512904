@use "sass:math";

.main-page .main-page-header h1 {
    font-size: 32px;
}

.main-page .main-page-header p {
    font-size: 21px;
    line-height: 1.4;
    margin-bottom: 20px;
}

.main-page h2, .main-page h1 {
    font-weight: 500;
}

.main-page .tab-content {
    padding-top: 0;
}

.light-container {
    background-color: #fafafa;
}

.dark-container {
    background-color: #f4f4f4;
}

.container-shadow {
    box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.05), inset 0px 2px 3px 0px rgba(0,0,0,0.1);
}

.page-container {
    padding: 2 * $line-height 0 2 * $line-height 0;
}

@media (min-width: $screen-sm-min) {
  .page-container.small-container {
      padding: 40px 0 40px 0;
  }

  .page-container.tiny-container {
      padding: 20px 0 20px 0;
  }

  .page-container.footer-container {
      padding: 40px 0 30px 0;
  }
}

@media (max-width: $screen-xs-max) {
  .page-container {
    padding: $line-padding math.div($line-padding, 2);
  }
}
.edueng-footer {
    width: 100%;
    bottom: 0;
    position: absolute;
}

.tab-pane .tab-content-margin {
    margin-top: 10px;
}

.tab-pane .well .btn-toolbar {
    margin-bottom: 0;
}

.tab-pane .with-empty-row  {
    margin-top: 20px;
    display: block;
}
