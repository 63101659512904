@use "sass:math";

.panel-heading {
  padding-top: 12px;
  padding-bottom: 12px;
  .panel-title {
    font-size: 18px;
  }
}


.panel-heading.panel-heading-sm {
  padding-top: 6px;
  padding-bottom: 6px;

  .panel-title {
    font-size: 16px;
  }
}

.panel-body.panel-body-sm {
    padding-bottom: 5px;
}

.panel-body.panel-body-xs {
    padding-bottom: 5px;
    padding-top: 5px;
}

.panel-body.panel-body-no-p {
    padding-bottom: 0;
    padding-top: 0;
}

.panel .panel-heading.panel-heading-filtered {
  padding-top: 10px;
  padding-bottom: 10px;

}

@mixin  panel-top-variant($color) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 4px solid $color;

}

@mixin  panel-left-variant($color) {
  //border-top-left-radius: 0;
  //border-bottom-left-radius: 0;
  border-left: 4px solid $color;

}

.panel-top-success {
  @include panel-top-variant($blue)
}

.panel-top-danger {
  @include panel-top-variant($red)
}

.panel-top-amazing {
  @include panel-top-variant($amazing)
}

.panel-top-yellow {
  @include panel-top-variant($yellow)
}

.panel-top-primary {
  @include panel-top-variant($navy)
}

.panel-top-info {
  @include panel-top-variant($lazur)
}

.panel-left-primary {
  @include panel-left-variant($navy)
}

.panel-left-danger {
  @include panel-left-variant($red)
}

.panel-left-success {
  @include panel-left-variant($blue)
}

@mixin panel-light-variant($color, $text-color) {
  border-color: #ddd;
  > .panel-heading {
    background-color: lighten($color, 40%);
    border-color: #ddd;
    //border-color: $color;
    color: $text-color;
  }
  &.stripped > .panel-heading {
        background: repeating-linear-gradient(
            45deg,
            lighten($color, 35%),
            lighten($color, 35%) 10px,
            lighten($color, 40%) 10px,
            lighten($color, 40%) 20px
    )
  }
}

.panel-danger-light {
  @include  panel-light-variant($red, $red-text)
}

.panel-warning-light {
  @include  panel-light-variant($yellow, $yellow-text)
}

.panel-info-light {
  @include  panel-light-variant($lazur, $lazur-text)
}

.panel-primary-light {
  @include  panel-light-variant($navy, $navy-text)
}

.panel-success-light {
  @include  panel-light-variant($blue, $blue-text)
}

.panel-amazing-light {
  @include  panel-light-variant($amazing, $amazing-text)
}

.panel-heading-dark {
  background: $inverse;
  color: $text-color-white;

  .text-success {
    color: $text-color-white;
  }
}

.panel-background, table .panel-background {
  background: #f4f6fa;
  padding-top: $modern-gap;
  padding-bottom: $modern-gap;
}

.panel-heading ~ .panel-background, .panel-footer ~ .panel-background {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-background ~ .panel-heading, .panel-background ~ .panel-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.panel-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.no-border-left {
  border-left-width: 0;
}

.no-border-right {
  border-right-width: 0;
}

.shp-panel {
  border-radius: 4px;
  background: #fff;
  border: 1px solid $border-color;
}

.panel > .panel-heading ~ textarea {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.panel.panel-primary .panel-heading .panel-title > a{
    color: white;
    text-decoration: underline;
}


.panel  .panel-heading-filtered .form-group {
    margin-bottom: 0;
}

.panel  .panel-heading-filtered .panel-title {
    padding-top: 5px;
}

@mixin sticky-panel-variant () {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

.panel-body-stripped {
  padding-top: 0;
  padding-bottom: 0;
  > * {
    border-top: 1px solid $border-color;
    padding-top: math.div($line-padding, 2);
    padding-bottom: math.div($line-padding, 2);
  }

  :first-child {
    border-top: none;
  }
}

.panel-footer.panel-footer-sm {
  padding-left: math.div($line-padding, 2);
  padding-right: math.div($line-padding, 2);
}


.sticky_panel {
  @include sticky-panel-variant()
}

.sticky_panel-sm {
  @media (min-width: $screen-sm-min) {
    @include sticky-panel-variant()
  }
}

.sticky_panel-md {
  @media (min-width: $screen-md-min) {
    @include sticky-panel-variant()
  }
}

.sticky_panel-lg {
  @media (min-width: $screen-lg-min) {
    @include sticky-panel-variant()
  }
}


.panel-borderless {
  border: none;
  box-shadow: 0px 1px 1px rgba(20, 49, 68, 0.1);
  margin-bottom: $line-padding;

  > .panel-heading {
    padding-top: $line-padding;
    padding-bottom: math.div($line-padding, 2);

    > .panel-title {
      font-weight: 400;
    }

    &.panel-heading-lg {
      padding-bottom: $line-padding;
    }
  }

  .panel-body {
    padding-top: math.div($line-padding, 2);
  }

  &.panel > table > tbody:first-child > tr:first-child {
    td, th {
      border-top: 1px solid $border-color;
    }
  }
}

.panel {
  margin-bottom: $line-padding;
}

@media (max-width: $screen-xs-max) {
  .panel {
    margin-bottom: math.div($line-padding, 2);
  }
}

.panel {
  > .panel-body {
    .panel-row {
      margin-left: -$line-padding;
      margin-right: -$line-padding;
      padding: 4 * $grid-size $line-padding;
    }

   .panel-row-md {
      padding-top: $line-padding;
      padding-bottom: $line-padding;
    }

    .panel-row, .panel-row-md {
      ~ .panel-row, ~ .panel-row-md {
        border-top: 1px solid $border-color;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

}

.panel-corner-left {
  margin-left: 60px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid white;
  }
}

.panel-edited {
  box-shadow: 0px 0px 8px rgba(82, 168, 236, 0.6);
}

.panel .panel {
  margin-bottom: 16px !important;
}

.panel.child-panels-mb-0 .panel, .panel .panel:last-of-type {
  margin-bottom: 0px !important;
}

.panel {
  .list-group.list-group__separated {
    .list-group-item:first-child {
      border-top: 1px solid $border-color;
    }
  }
}

.panel.panel-transparent {
  background-color: rgba(255, 255, 255, 0.9);
}

.panel-field + .panel-field {
  margin-top: $grid-size * 6;
}
