.diary2 {
    position: relative;
}

.attendance__come_at__input {
    min-width: 60px;
    text-align: center;
    font-size: $font-md;
}

.row-delimiter td, .row-delimiter th {
    border-top-width: 2px;
}

.large-row-delimiter.row-delimiter td, .large-row-delimiter.row-delimiter th {
    border-top-width: 4px;
}


td.cell-delimiter, th.cell-delimiter {
    border-left-width: 2px;
}

/*Журнал*/
td.mark_cell {
    padding-bottom: 3px;
    white-space: nowrap;
}

.table > tbody > tr > td.has_mark,
.table > tbody > tr > td.has_exam_mark,
.table > tbody > tr > td.has_subject_mark,
.table > tbody > tr > td.has_part_mark {
    padding: 0;
    white-space: nowrap;
}

.add_mark_space  > * {
    padding: 4px;
}

tr.pupil-header > td > .mark_cell, tr.pupil-header > td > .exam_mark_cell, tr.pupil-header > td > .part_mark_cell  {
    padding: 4px 4px 3px 4px;
}

.diary-table {
  th:first-child, td:first-child {
    position: sticky;
    left: 0px;
    z-index: 2;
  }

  tr:nth-child(2) th:first-child {
    position: unset;
  }
}


.mark_cell .part_mark_cell, .subject_mark_cell, .total_pupils_cell, .exam_mark_cell {
    cursor: text;
    white-space: nowrap;
    height: 29px;
}

.table-condensed > tbody > tr > td.exam_td_cell {
    padding: 0;
}

.mark_cell .form-control,
.part_mark_cell .form-control,
.subject_mark_cell .form-control,
.total_pupils_cell .form-control,
.exam_mark_cell .form-control{
    display: inline-block;
}


td.mark_cell .popover {
    white-space: normal;
}

div.mark_cell, div.exam_mark_cell, div.part_mark_cell {
    min-height: 20px;
    min-width: 20px;
}

.diary-list div.mark_cell {
    min-height: 20px;
}

.mark {
    background-color: transparent;
    padding: 0;
}

.mark {
    cursor: pointer;
}

.available_classes-mode .has_mark, .available_classes-mode .has_mark > div {
    cursor: pointer;
}

.available_classes-mode table tbody tr:nth-child(n) td.available-classes, .available_classes-mode .available-classes {
    background-image: radial-gradient(circle closest-side, #EDC0C0 5px, rgba(255,255, 255, 0) 5px);
}

.available_classes-mode table tbody tr:nth-child(n) td.available-auto, .available_classes-mode .available-auto {
    /*background-image: radial-gradient(circle closest-side, #d9edf7 5px, rgba(255,255, 255, 0) 5px);*/
    background-image: radial-gradient(circle closest-side, #BCE2F5 5px, rgba(255,255, 255, 0) 5px);
}


.available_classes-mode table tbody tr:nth-child(n) td.mark_cell:hover {
    background-color: #CCDCFF;
    cursor: pointer;
}

.diary-label ~ .diary-label:before {
    content: '/';
}
.diary2 td, .diary2 th {
    min-width: 24px;
}

input.text-in-place {
    width: 18px;
    min-height: 15px;
    margin-bottom: 0;
    padding: 0 0 0 2px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    margin-top: 0;
    height: 19px;
}

input.text-in-place[maxlength='3'], input.text-in-place[maxlength='4'], input.text-in-place[maxlength='5'] {
    width: 25px;
}

span.has_comment {
    border-bottom: 2px dotted;
}

span.has_comment.published_comment {
    border-bottom: 2px solid;
}

.top-left-triangle {
    background: url('../img/blue_corner.png') no-repeat top left;
}

.bottom-left-triangle {
    background: url('../img/red_corner.png') no-repeat bottom left;
}

.top-right-triangle {
    background: url('../img/green_corner.png') no-repeat top right;
}

.top-right-triangle-warn {
    background: url('../img/yellow_corner.png') no-repeat top right;
}

.top-left-triangle.bottom-left-triangle {
    background: url('../img/blue_corner.png') no-repeat top left, url('../img/red_corner.png') no-repeat bottom left;
}

.top-right-triangle.top-left-triangle {
    background: url('../img/green_corner.png') no-repeat top right, url('../img/blue_corner.png') no-repeat top left;
}

.top-right-triangle.bottom-left-triangle {
    background: url('../img/green_corner.png') no-repeat top right, url('../img/red_corner.png') no-repeat bottom left;
}

.top-right-triangle-warn.top-left-triangle {
    background: url('../img/yellow_corner.png') no-repeat top right, url('../img/blue_corner.png') no-repeat top left;
}

.top-right-triangle-warn.bottom-left-triangle {
    background: url('../img/yellow_corner.png') no-repeat top right, url('../img/red_corner.png') no-repeat bottom left;
}

.top-right-triangle.bottom-left-triangle.top-left-triangle {
    background: url('../img/green_corner.png') no-repeat top right, url('../img/red_corner.png') no-repeat bottom left, url('../img/blue_corner.png') no-repeat top left;
}

.top-right-triangle-warn.bottom-left-triangle.top-left-triangle {
    background: url('../img/yellow_corner.png') no-repeat top right, url('../img/red_corner.png') no-repeat bottom left, url('../img/blue_corner.png') no-repeat top left;
}

td.mark_cell {
    z-index: 10;
    position: relative;
}

td.no-delimiters, th.no-delimiters {
    border-left-width: 0;
}

.translated-column {
    margin-bottom: 2px;
}

.unit-column {
    padding: 2px 4px;
    margin-bottom: 2px;
}

.sub-hover,
.table-hover tbody tr:hover th.sub-hover,
.table-hover tbody tr:hover td.sub-hover{
    background-color: #D9EDF7;
}

.large_margin_title {
    margin-top: 33px;
    margin-bottom: 22px;
}

tr.without_part input.hours {
    display: none;
}

.not-empty{
    min-height: 15px;
    min-width: 7px;
    display: inline-block;
}

.height-icon {
    line-height: 18px;
}

.marks-slider .tooltip.top {
    top: -34px;
}

.ui-state-disabled.marks-slider {
    opacity: .80;
}
